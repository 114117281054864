import React, { Fragment, useEffect } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { DropdownFilter } from "../_partials/dropdowns";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import SVG from "react-inlinesvg";
import { DynamicModal, apiCall } from "../../app/helpers/globals";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
} from "../_partials/controls";
export function removeCSSClass(ele, cls) {
  const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
  ele.className = ele.className.replace(reg, " ");
}

export function addCSSClass(ele, cls) {
  ele.classList.add(cls);
}

export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

export function TimelineHorizontal(props) {
  const [dataClicked, setDataClicked] = React.useState([]);
  const [modalClick, setModalDetailsClicked] = React.useState(false);
  useEffect(() => {
    if (!!props?.item?.id === true && dataClicked.length === 0 && props?.isDetails) {
      apiCall({
        method: "GET",
        link: `/api/Campaigns/LinksClicked/${props?.item?.id}`,
      }).then((dataResult) => {
        try {
          if (dataResult[0] === 200) {
            setDataClicked(dataResult[1]?.clicked || []);
          } else {
            setDataClicked([]);
          }
        } catch (err) {
          console.log(err);
        }
      });
    }
  }, [props]);
  // eslint-disable-next-line no-unused-expressions
  return (
    <>
      <div className="row">
        <div className="col">
          <div
            className="timeline-steps aos-init aos-animate"
            data-aos="fade-up"
          >
            <div
              className={`${props?.item?.sent ? "active" : ""} timeline-step`}
            >
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-top" className="mb-3">
                    <span className="font-weight-bold">Sent</span>
                  </Tooltip>
                }
              >
                <div
                  className={`timeline-content`}
                  data-toggle="popover"
                  data-trigger="hover"
                  data-placement="top"
                  title=""
                  data-content=""
                  data-original-title={2003}
                >
                  <div
                    className={`${
                      props?.item?.sent ? "active" : ""
                    } inner-circle`}
                  >
                    <i className="fa fa-envelope icon-sm text-white"></i>
                  </div>
                </div>
              </OverlayTrigger>
            </div>
            {props?.item?.bounce === true ? (
              <div className="active timeline-step">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top" className="mb-3">
                      <span className="font-weight-bold">Bounced</span>
                    </Tooltip>
                  }
                >
                  <div
                    className={`timeline-content`}
                    data-toggle="popover"
                    data-trigger="hover"
                    data-placement="top"
                    title=""
                    data-content=""
                    data-original-title={2004}
                  >
                    <div className="danger inner-circle text-danger cursor-pointer">
                      <i className="far fa-window-close text-danger"></i>
                    </div>
                  </div>
                </OverlayTrigger>
              </div>
            ) : (
              <>
                <div
                  className={`${
                    props?.item?.isOpen ? "active" : ""
                  } timeline-step`}
                >
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top" className="mb-3">
                        <span className="font-weight-bold">Opened</span>
                      </Tooltip>
                    }
                  >
                    <div
                      className={`timeline-content`}
                      data-toggle="popover"
                      data-trigger="hover"
                      data-placement="top"
                      title=""
                      data-content=""
                      data-original-title={2004}
                    >
                      <div
                        className={`${
                          props?.item?.isOpen ? "active" : ""
                        } inner-circle`}
                      >
                        <i className="fa fa-eye icon-sm text-white"></i>
                        {props?.item?.isOpen && (
                          <div className="chiffer-top">
                            {props?.item?.openCount}
                          </div>
                        )}
                      </div>
                    </div>
                  </OverlayTrigger>
                </div>
                {!!props?.isModal === true ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top" className="mb-3">
                        <span
                          className="text-primary font-weight-bold"
                          onClick={(e) => {
                            e.preventDefault();
                            setModalDetailsClicked(true);
                          }}
                        >
                          Show details
                        </span>
                      </Tooltip>
                    }
                  >
                    <div
                      className={`${
                        props?.item?.isClicked ? "active" : ""
                      } timeline-step`}
                      onClick={(e) => {
                        e.preventDefault();
                        if (
                          dataClicked.length > 0 &&
                          !!props?.isModal === true
                        ) {
                          setModalDetailsClicked(true);
                        }
                      }}
                      style={{
                        cursor: dataClicked.length > 0 ? "pointer" : "inherit",
                      }}
                    >
                      <div
                        className={`timeline-content`}
                        data-toggle="popover"
                        data-trigger="hover"
                        data-placement="top"
                        title=""
                        data-content=""
                        data-original-title={2005}
                      >
                        <div
                          className={`${
                            props?.item?.isClicked ? "active" : ""
                          } inner-circle`}
                        >
                          <i className="fa fa-hand-pointer icon-sm text-white"></i>
                          {props?.item?.isClicked && (
                            <div className="chiffer-top">
                              {props?.item?.clickCount ?? 0}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </OverlayTrigger>
                ) : (
                  <div
                    className={`${
                      props?.item?.isClicked ? "active" : ""
                    } timeline-step`}
                    onClick={(e) => {
                      e.preventDefault();
                      if (dataClicked.length > 0 && !!props?.isModal === true) {
                        setModalDetailsClicked(true);
                      }
                    }}
                    style={{
                      cursor: dataClicked.length > 0 ? "pointer" : "inherit",
                    }}
                  >
                    <div
                      className={`timeline-content`}
                      data-toggle="popover"
                      data-trigger="hover"
                      data-placement="top"
                      title=""
                      data-content=""
                      data-original-title={2005}
                    >
                      <div
                        className={`${
                          props?.item?.isClicked ? "active" : ""
                        } inner-circle`}
                      >
                        <i className="fa fa-hand-pointer icon-sm text-white"></i>
                        {props?.item?.isClicked && (
                          <div className="chiffer-top">
                            {props?.item?.clickCount ?? 0}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {props.isNewsletters ? (
                  ""
                ) : (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top" className="mb-3">
                        <span className="font-weight-bold">Replied</span>
                      </Tooltip>
                    }
                  >
                    <div
                      className={`${
                        props?.item?.isReply ? "active" : ""
                      } timeline-step mb-0`}
                    >
                      <div
                        className={`timeline-content`}
                        data-toggle="popover"
                        data-trigger="hover"
                        data-placement="top"
                        title=""
                        data-content=""
                        data-original-title={2020}
                      >
                        <div
                          className={`${
                            props?.item?.isReply ? "active" : ""
                          } inner-circle`}
                        >
                          <i className="fa fa-reply icon-sm text-white"></i>
                          {props?.item?.isReply && (
                            <div className="chiffer-top">
                              {props?.item?.replyCount}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </OverlayTrigger>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <DynamicModal
        size="lg"
        show={modalClick}
        setShow={setModalDetailsClicked}
        child={
          <Card>
            <CardBody className="px-0">
              <Fragment>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Link</th>
                      <th scope="col">Click Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(dataClicked ?? [])?.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <a href={item?.link} target="_blank" rel="noreferrer">
                            {item?.link}
                          </a>
                        </td>
                        <td>
                          <span className="badge badge-primary">
                            {item?.clickCount}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Fragment>
            </CardBody>
            <CardFooter className="py-4 text-right">
              <Button
                variant="secondary"
                size="sm"
                onClick={(e) => {
                  e.preventDefault();
                  setModalDetailsClicked(false);
                }}
              >
                Close
              </Button>
            </CardFooter>
          </Card>
        }
      />
    </>
  );
}

export function DropdownDate({ Filter, setFilter }) {
  return (
    <>
      {/*  <div className="row w-100">
        
        <div className="col-5">
          <input
            type={`Date`}
            className="form-control form-control-sm mr-2"
            placeholder={"From"}
            name="From"
            onChange={(e) => {}}
          />
        </div>
        <div className="col-5">
          <input
            type={`Date`}
            className="form-control form-control-sm mr-2"
            placeholder={"From"}
            name="From"
            onChange={(e) => {}}
          />
        </div>
        <div className="col-2"> */}
      <Dropdown className="dropdown-inline" alignRight>
        <Dropdown.Toggle id="dropdown-toggle-top" as={DropdownFilter}>
          <a
            href={() => false()}
            className="btn btn-light btn-sm font-weight-bold"
          >
            <span>
              {" "}
              <i className="fas fa-sort-amount-down-alt mr-2" />
            </span>

            <span
              className="text-primary font-weight-bold"
              id="kt_dashboard_daterangepicker_date"
            >
              {!!Filter.Type ? Filter.Type : "Custom Date"}
            </span>
          </a>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
          <div className="row w-100 mt-2 mb-2 pb-5 ml-0 border-bottom">
            <div className="col-12 mb-2">
              From
              <input
                type={`Date`}
                className="form-control form-control-sm mr-2 w-100"
                name="From"
                value={Filter?.StartDate ?? ""}
                onChange={(e) => {
                  var aa = e.target?.value;
                  setFilter((x) => {
                    x.StartDate = aa;
                    x.Type = null;
                    return { ...x };
                  });
                }}
              />
            </div>
            <div className="col-12">
              To
              <input
                type={`Date`}
                className="form-control form-control-sm mr-2 w-100"
                name="To"
                value={Filter?.EndDate ?? ""}
                onChange={(e) => {
                  var aa = e.target?.value;
                  setFilter((x) => {
                    x.EndDate = aa;
                    x.Type = null;
                    return { ...x };
                  });
                }}
              />
            </div>
          </div>

          {/* <b>Or</b> */}

          <Dropdown.Item
            onClick={() => {
              const startOfMonth = moment()
                .startOf("week")
                .format("YYYY-MM-DD");
              const endOfMonth = moment()
                .endOf("week")
                .format("YYYY-MM-DD");
              setFilter({
                StartDate: startOfMonth,
                EndDate: endOfMonth,
                Type: "week",
              });
            }}
          >
            This Week
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              const startOfMonth = moment()
                .startOf("month")
                .format("YYYY-MM-DD");
              const endOfMonth = moment()
                .endOf("month")
                .format("YYYY-MM-DD");
              setFilter({
                StartDate: startOfMonth,
                EndDate: endOfMonth,
                Type: "month",
              });
            }}
          >
            This Month
          </Dropdown.Item>
          {/* <Dropdown.Item
            onClick={() =>
              setFilter({ StartDate: null, EndDate: null, Type: "week" })
            }
          >
            This Quarter
          </Dropdown.Item> */}
          <Dropdown.Item
            onClick={() => {
              const startOfMonth = moment()
                .startOf("year")
                .format("YYYY-MM-DD");
              const endOfMonth = moment()
                .endOf("year")
                .format("YYYY-MM-DD");
              setFilter({
                StartDate: startOfMonth,
                EndDate: endOfMonth,
                Type: "year",
              });
            }}
          >
            This Year
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {/*   </div>
      </div> */}
    </>
  );
}
