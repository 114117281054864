/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_helpers";
import SVG from "react-inlinesvg";

export function StickyToolbar() {
  return (
    <>
      <ul
        className="sticky-toolbar nav flex-column pl-2 pr-1 pt-3 pb-1 mt-4"
        id="ChatId"
        style={{ display: "none" }}
      >
        <OverlayTrigger
          placement="left"
          overlay={<Tooltip id="documentations-tooltip">Chat</Tooltip>}
        >
          <li className="nav-item mb-2" data-placement="left">
            <a
              className="btn btn-sm btn-icon btn-light btn-hover-success"
              rel="noopener noreferrer"
              onClick={() => {
                try {
                  document
                    .querySelector("#customerly-container .giyoEQ")
                    .click();
                } catch (error) { }
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-success">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Chat2.svg")} />
              </span>
            </a>
          </li>
        </OverlayTrigger>
      </ul>
    </>
  );
}
