
import React from "react";
import { Button, Container } from "react-bootstrap";
import { ga } from "react-ga";
import { toAbsoluteUrl } from "../_metronic/_helpers";
export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errors: "" };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    //log the error to an error reporting service
    ga("send", "exception", {
      exDescription: `UserEmail:${
        localStorage.getItem("user") !== null
          ? JSON.parse(localStorage.getItem("user") ?? "").email
          : ""
      } has an error: ${String(error)} at ${String(errorInfo?.componentStack)}`,
      exFatal: false,
    });
    this.setState({
      errors: `${String(error)} at ${String(errorInfo?.componentStack)}`,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="d-flex flex-column flex-root">
          <div className="d-flex flex-row flex-column-fluid page">
            <div className="d-flex flex-column flex-row-fluid wrapper p-0">
              <div className="flex-column-fluid d-flex flex-column justify-content-center">
                <div className="bg">
                  <a href="/dashboard">
                    <Button
                      variant="primary"
                      size="md"
                      className="position-absolute"
                      style={{ left: "50%" }}
                    >
                      Go Home
                    </Button>

                    <img
                      className="img-responsive w-100 h-600px"
                      style={{ objectFit: "scale-down" }}
                      alt="img"
                      src={toAbsoluteUrl("/media/error/400.png")}
                    />

                    {/* Errors */}
                    {/* <span className="text-danger">{this.state.errors}</span> */}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
