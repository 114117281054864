import CKEditor from "ckeditor4-react";
import { useFormik } from "formik";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import ReactSelect from "react-select";
import Swal from "sweetalert2";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  apiCall,
  ConvertToTimeZoneSendingPost,
  ModelStateMessage,
  replaceValues,
  RequiredHtml,
  setValueField,
  ToggleTrackingOnOff,
  token,
} from "../../../helpers/globals";

function ReplyForm(props) {
  const emailObj = {
    templateId: [0],
    fromId: 0,
    toId: [0],
    ccId: [0],
    bccId: [0],
    subject: "",
    body: "",
  };
  // const Schema = Yup.object().shape({
  //   firstName: Yup.string().required("* First name is required"),
  //   lastName: Yup.string().required("* Last name is required"),
  // });
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const SchemaEmail = Yup.object().shape({});
  const [dateSent, setDateSent] = useState(new Date());
  const [loaderBtn, setLoaderBtn] = useState(false);
  const [body, setBody] = useState("");
  const [provider, setprovider] = useState({});
  const [datahtml, setdatahtml] = useState({ subject: "", body: "" });
  const [isCC, setisCC] = useState(false);
  const [CCvalue, setCCvalue] = useState([]);
  const [ShowPreview, setShowPreview] = useState(false);
  const [BCCvalue, setBCCvalue] = useState([]);
  const [trackValues, setTrackValues] = useState({
    trackOpen: true,
    trackClick: true,
  });

  const formikEmail = useFormik({
    initialValues: emailObj,
    enableReinitialize: true,
    validationSchema: SchemaEmail,
    onSubmit: (values) => {},
  });

  const bodyTemplate = useMemo(() => {
    return replaceValues(body, props?.variables ?? []);
  }, [body, props]);
  return (
    <div className="divReply">
      <Row>
        <Col className="py-4">
          <div>
            <Form.Row>
              <Col xl="12" lg="12" md="12">
                <Form.Group controlId="formGroupAccount" className="mb-3">
                  <Form.Label className="mb-1 font-size-sm">
                    Template
                  </Form.Label>
                  <ReactSelect
                    options={(props?.viewModelCompanies?.templates ?? []).map(
                      (x) => {
                        return {
                          value: x?.id,
                          label: x?.name,
                        };
                      }
                    )}
                    classNamePrefix="react-select"
                    size="sm"
                    name="templateId"
                    value={(props?.viewModelCompanies?.templates ?? []).find(
                      (obj) => obj.value === formikEmail?.values?.templateId[0]
                    )}
                    onChange={(e) => {
                      formikEmail.setFieldValue("templateId", [e.value]);
                      apiCall({
                        method: "GET",
                        link: `/api/Templates/${e.value}`,
                      }).then((data) => {
                        if (data[0] === 200) {
                          var obj = { ...datahtml };
                          obj.subject = data[1].subject;
                          setBody(data[1].body);
                          setdatahtml(obj);

                          formikEmail.setFieldValue("subject", data[1].subject);
                          formikEmail.setFieldValue("body", data[1].body);
                        }
                      });
                    }}
                  />
                </Form.Group>
              </Col>

              <Col xl="12" lg="12" md="12">
                <Form.Group controlId="formGroupAccount" className="mb-3">
                  <Form.Label className="mb-1 font-size-sm">From</Form.Label>
                  <ReactSelect
                    options={(props?.viewModelCompanies?.providers ?? []).map(
                      (x) => {
                        return {
                          value: x?.id,
                          label: x?.name,
                        };
                      }
                    )}
                    classNamePrefix="react-select"
                    size="sm"
                    name="fromId"
                    value={
                      !!props?.item?.provider
                        ? {
                            value: (
                              props?.viewModelCompanies?.providers ?? []
                            ).find((obj) => obj.name === props?.item?.provider)
                              ?.id,
                            label: props?.item?.provider,
                          }
                        : (props?.viewModelCompanies?.providers ?? []).find(
                            (obj) => obj.value === formikEmail?.values?.fromId
                          )
                    }
                    onChange={(e) => {
                      formikEmail.setFieldValue("fromId", e.value);
                      apiCall({
                        method: "GET",
                        link: `/api/Providers/${e.value}`,
                      }).then((data) => {
                        if (data[0] === 200) {
                          setprovider(data[1].provider);
                        }
                      });
                    }}
                  />
                </Form.Group>
              </Col>

              <Col xl="12" lg="12" md="12">
                <Form.Group controlId="formGroupAccount" className="mb-3">
                  <Form.Label className="mb-1 font-size-sm">To</Form.Label>
                  <Form.Control
                    disabled={true}
                    size="sm"
                    name="to"
                    value={`${props?.item?.firstName} ${props?.item?.lastName}`}
                  />
                </Form.Group>
              </Col>
              <Row className="px-2">
                <div>
                  <Form.Group controlId="formGroupAccount" className="mb-3">
                    <Form.Label className="mb-1 font-size-sm row px-4">
                      Sent Date
                    </Form.Label>
                    <DatePicker
                      selected={dateSent}
                      onChange={(date) => setDateSent(date)}
                      showTimeSelect
                      className="form-control"
                      timeFormat="h:mm aa"
                      dateFormat="MM/dd/yyyy h:mm aa"
                    />
                  </Form.Group>
                </div>
              </Row>
              {/* <Col>
                <div className="d-flex justify-content-end">
                  <Button
                    variant="outline-primary"
                    className=""
                    size="sm"
                    onClick={() => {
                      setisCC((x) => !x);
                    }}
                  >
                    <i className={`fas fa-eye${isCC ? "-slash" : ""}`}></i> CC
                    && BCC
                  </Button>
                </div>
              </Col> */}

              {isCC && (
                <>
                  <Col xl="12" lg="12" md="12" className="mt-2">
                    <Form.Group controlId="formGroupAccount" className="mb-3">
                      <Form.Label className="mb-1 font-size-sm">CC</Form.Label>
                      <ReactSelect
                        isMulti
                        options={
                          props?.contactList?.length > 0
                            ? props?.contactList?.map((x) => {
                                return {
                                  value: x?.contactId,
                                  label: `${x?.firstName} ${x?.lastName}`,
                                };
                              })
                            : []
                        }
                        value={CCvalue}
                        classNamePrefix="react-select"
                        size="sm"
                        name="ccId"
                        onChange={(e) => {
                          setCCvalue(e);
                          let result = e.map((a) => a.value);
                          formikEmail.setFieldValue("ccId", result);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl="12" lg="12" md="12">
                    <Form.Group controlId="formGroupAccount" className="mb-3">
                      <Form.Label className="mb-1 font-size-sm">BCC</Form.Label>
                      <ReactSelect
                        isMulti
                        options={
                          props?.contactList?.length > 0
                            ? props?.contactList?.map((x) => {
                                return {
                                  value: x?.contactId,
                                  label: `${x?.firstName} ${x?.lastName}`,
                                };
                              })
                            : []
                        }
                        value={BCCvalue}
                        classNamePrefix="react-select"
                        size="sm"
                        name="bccId"
                        onChange={(e) => {
                          setBCCvalue(e);
                          let result = e.map((a) => a.value);
                          formikEmail.setFieldValue("bccId", result);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </>
              )}

              <Col xl="12" lg="12" md="12" className="mt-5">
                <h5 className="mb-1">
                  <b>Re: </b> {props?.item?.subject}
                </h5>
              </Col>
            </Form.Row>

            {/* Second row */}
            <Row>
              <Col>
                <label className="font-size-sm mt-5">
                  Body
                  <RequiredHtml />
                </label>
                {!!props?.variables && props?.variables?.length > 0 && (
                  <CKEditor
                    data={body}
                    onChange={(event) => {
                      setBody(event?.editor?.getData());
                      formikEmail.setFieldValue(
                        "body",
                        event?.editor?.getData()
                      );
                    }}
                    config={{
                      versionCheck: false,
                      extraPlugins: "uploadimage,autolink",
                      filebrowserUploadUrl: `${BASE_URL}/api/Generic/uploadCK?isckEditor=true`,
                      fileTools_requestHeaders: {
                        "X-Requested-With": "XMLHttpRequest",
                        Authorization: `Bearer ${token}`,
                      },
                    }}
                    onPluginsLoaded={(args) => {
                      const editor = args.editor;
                      editor.ui.addRichCombo("my-combo", {
                        label: "Add variables",
                        title: "Add variables",
                        toolbar: "basicstyles,0",
                        className: "CkeATClass",
                        panel: {
                          // css: [ CKEDITOR.skin.getPath( 'editor' ) ].concat( config.contentsCss ),
                          css: [
                            "https://cdn.ckeditor.com/4.16.2/standard-all/skins/moono-lisa/editor.css?t=L7C8",
                            "https://cdn.ckeditor.com/4.4.0/standard/contents.css?t=E3OD",
                            "./styles.css",
                          ],
                          multiSelect: false,
                          attributes: {
                            "aria-label": "Merge Codes",
                          },
                        },
                        init: function() {
                          (props.variables ?? []).forEach((x) => {
                            this.add(x?.name, x?.name);
                          });
                        },
                        onClick: function(value) {
                          editor.focus();
                          editor.fire("saveSnapshot");
                          editor.insertHtml(value);
                          editor.fire("saveSnapshot");
                        },
                      });
                    }}
                  />
                )}
              </Col>

              <Col xl="12" lg="12" md="12" className="mt-2">
                {ShowPreview && (
                  <Row>
                    <Col>
                      <div className="divReply">
                        <h5 className="text-dark font-weight-bolder mt-2 mb-2 font-size-sm">
                          Subject:
                        </h5>
                        <span className="text-dark-700 font-weight-normal font-size-sm">
                          {replaceValues(
                            props?.item?.subject,
                            props?.variables
                          )}
                        </span>

                        <h5 className="text-dark font-weight-bolder mt-2 mb-2 font-size-sm mt-5">
                          Body
                        </h5>
                        <div
                          className="text-dark-700 font-weight-normal font-size-sm"
                          dangerouslySetInnerHTML={{
                            __html: replaceValues(
                              bodyTemplate,
                              props?.variables
                            ),
                          }}
                        />

                        {!!provider?.signature && (
                          <>
                            <div
                              className="text-dark-700 font-weight-normal font-size-sm mt-5"
                              dangerouslySetInnerHTML={{
                                __html: replaceValues(
                                  provider?.signature,
                                  props?.variables
                                ),
                              }}
                            />
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <ToggleTrackingOnOff
                      value={trackValues?.trackOpen}
                      setValue={(e) =>
                        setValueField(
                          trackValues,
                          setTrackValues,
                          e,
                          "trackOpen"
                        )
                      }
                      label="Track open"
                      name="trackOpen"
                    />
                  </Col>
                  <Col>
                    <ToggleTrackingOnOff
                      value={trackValues?.trackClick}
                      setValue={(e) =>
                        setValueField(
                          trackValues,
                          setTrackValues,
                          e,
                          "trackClick"
                        )
                      }
                      label="Track click"
                      name="trackClick"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <div className="d-flex justify-content-end" id="bottomContent">
        <Button
          variant="outline-primary"
          className="mr-2"
          size="sm"
          onClick={() => {
            setShowPreview((x) => !x);
          }}
        >
          <i className={`fas fa-eye${ShowPreview ? "-slash" : ""}`}></i> Preview
        </Button>
        <button
          className="btn btn-primary btn-sm mr-2"
          disabled={loaderBtn}
          onClick={(e) => {
            e.preventDefault();
            setLoaderBtn(true);
            apiCall({
              method: "POST",
              link: `/api/Sending/Reply`,
              data: {
                reportId: props?.item?.id,
                body: body,
                trackOpen: trackValues.trackOpen ?? true,
                trackClick: trackValues.trackClick ?? true,
                date: ConvertToTimeZoneSendingPost(dateSent),
              },
            }).then((data) => {
              setLoaderBtn(false);
              if (data[0] === 200) {
                props.GetData();
                Swal.fire({
                  icon: "success",
                  title: "Success",
                  text: "Email has been sent successfully",
                });
                props.getDataItem();
                props.setReplyForm(false);
                // setshowEmails(false);
              } else {
                ModelStateMessage(data);
              }
            });
          }}
        >
          Reply now
          {loaderBtn && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
        </button>
      </div>
    </div>
  );
}

export default ReplyForm;
