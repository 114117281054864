import React, { useEffect, useRef, useState } from "react";
import { Steps, Hints } from "intro.js-react";
import { useHistory } from "react-router-dom";

import "intro.js/introjs.css";

export default function IntroJs({ steps }) {
  const history = useHistory();
  const [showStep, setShowStep] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [showIntro, setShowIntro] = useState(false);
  const [listEmails, setlistEmails] = useState([]);
  const [getPath, setPath] = useState(null);
  const [stepss, setstepss] = useState([]);
  const intro = useRef(null);
  useEffect(() => {
    if (typeof localStorage.getItem("hasSeenIntro") == "undefined") {
      console.log("");
    } else {
      setlistEmails(String(localStorage.getItem("hasSeenIntro")).split("/"));
    }
    setPath(String(window?.location?.pathname).split("/"));
    setstepss(steps);
    const timeoutId = setTimeout(() => {
      setShowIntro(true);
    }, 1500); // Set timeout to 2 seconds

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
  useEffect(() => {
 console.log(currentStep)
  }, [currentStep]);

  useEffect(() => {
    setTimeout(() => {
      const skipButton = document.querySelector('.introjs-skipbutton');
      if (skipButton) {
        skipButton.addEventListener('click', handleSkipIntro);
      }
      return () => {
        if (skipButton) {
          skipButton.removeEventListener('click', handleSkipIntro);
        }
      }
    }, 1000);
   
  }, [intro, handleSkipIntro]);
  const handleBeforeChange = (nextStep,targetElement) => {
    setCurrentStep(nextStep)
    const buttons = document.querySelectorAll(".introjs-skipbutton");
    buttons.forEach((button) => {
      button.addEventListener("click", () => {
        // Call your function here
        handleSkipIntro();
      });
    });

    if (nextStep === 3 && String(window?.location?.pathname) === "/dashboard") {
      history.push("/user-profile/personal-information");
      intro.current.resume();
    } else if (
      nextStep === 1 &&
      String(window?.location?.pathname) ===
        "/user-profile/personal-information"
    ) {
      history.push("/user-profile/billing");
      setCurrentStep(1);
    } else if (
      nextStep === 3 &&
      String(window?.location?.pathname) === "/user-profile/billing"
    ) {
      history.push("/settings");
      intro.current.resume();
    } else if (
      nextStep === 1 &&
      String(window?.location?.pathname) === "/settings"
    ) {
      history.push("/search");
      intro.current.resume();
    } else if (
      nextStep === 7 &&
      String(window?.location?.pathname) === "/search"
    ) {
      history.push("/Leadlist");
      intro.current.resume();
    } else if (
      nextStep === 4 &&
      String(window?.location?.pathname) === "/Leadlist"
    ) {
      history.push("/Sequences/management");
      intro.current.resume();
    } else if (
      nextStep === 2 &&
      String(window?.location?.pathname) === "/Sequences/management"
    ) {
      history.push("/Newsletters/Management");
      intro.current.resume();
    } else if (
      nextStep === 2 &&
      String(window?.location?.pathname) === "/Newsletters/Management"
    ) {
      history.push("/Companies-AccountBased");
      intro.current.resume();
    } else if (nextStep === 3 && getPath[3] === "Details") {
      history.push("/Companies");
      intro.current.resume();
    } else {
      setCurrentStep(nextStep);
    }
  };

  function handleSkipIntro() {
    setShowIntro(false);
    var chacke = String(localStorage?.getItem("hasSeenIntro"));
    localStorage.removeItem("hasSeenIntro");
    localStorage.setItem(
      "hasSeenIntro",
      chacke + "/" + String(localStorage?.getItem("UserEmail"))
    );
    // Your code to handle skipping the intro
  }
  //   useEffect(() => {
  //     const hasSeenIntro = localStorage.getItem('hasSeenIntro');
  //     if (!hasSeenIntro) {
  //       setShowIntro(true);
  //       localStorage.setItem('hasSeenIntro', true);
  //     }
  //   }, []);
  function onComplete() {
    var chacke =String(localStorage?.getItem("hasSeenIntro"));
    localStorage.removeItem("hasSeenIntro");
    localStorage.setItem('hasSeenIntro', chacke+"/"+String(localStorage?.getItem("UserEmail")));
      }

  return (
    <>
      {showIntro && (
        <>
       {listEmails?.includes(String(localStorage?.getItem("UserEmail")))?"":  

          <Steps
          enabled={!showStep}
          showSkipButton={true}
          steps={stepss}
          initialStep={currentStep}
          onBeforeChange={handleBeforeChange}
          options={{
            exitOnOverlayClick: false,
            showBullets: false,
            showButtons: true,
            skipLabel: `Skip`,
          }}
          onComplete={onComplete}
          onExit={(targetElement) => {
            if (targetElement.classList.contains('introjs-skipbutton')) {
              handleSkipIntro();
            }
            setShowIntro(true);
          }}
          ref={intro}
        />
        
        }  

        </>
      )}
    </>
  );
}
