import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import withTracker from "./withTracker";
import Unibox from "./pages/Unibox/Unibox";

//lazy imports
const WebFormThankYouPage = lazy(() =>
  import("./pages/WebForm/WebFormThankYouPage")
);
const WebFormSlugPage = lazy(() =>
  import("./pages/WebForm/WebFormSlug")
);
const UnsbscribePage = lazy(() => import("./pages/Unsbscribe/Unsbscribe"));
const GlobalSearch = lazy(() => import("./pages/GlobalSearch"));
const GlobalNotification = lazy(() =>
  import("./pages/GlobalUserNotifications")
);
const DashboardExamplePage = lazy(() =>
  import("./pages/Dashboard/DashboardExample")
);
const GlobalDashboard = lazy(() =>
  import("./pages/Dashboard/GlobalDashboard")
);
const FormBuilderPage = lazy(() =>
  import("./pages/FormBuilder/FormBuilderPage")
);
const FormDtailPage = lazy(() =>
  import("./pages/FormBuilder/FormDetailPage")
);
const AddCampaigns = lazy(() => import("./pages/Campaigns/AddCampaigns"));
const PeoplePage = lazy(() => import("./pages/People/Main"));
const BusinessesPage = lazy(() => import("./pages/Businesses/BusinessesList"));
const AgenciesPage = lazy(() => import("./pages/Agencies/AgenciesList"));
const DashboardPage = lazy(() => import("./pages/Dashboard"));
const PeopleDetailPage = lazy(() => import("./pages/People/people-detail"));
const QueryQueryPageV2 = lazy(() =>
  import("./pages/QueryBuilderV2/QueryQueryPage")
);
const NylasCallBack = lazy(() =>
  import("./pages/CallBackUrl.js/NylasCallBack")
);
const EmailCampaignPage = lazy(() => import("./pages/EmailCampaigns"));
const CompanyDetailPage = lazy(() =>
  import("./pages/companies/company-detail")
);
const TargetList = lazy(() => import("./pages/Target-list/Index"));
const IndexTasks = lazy(() => import("./pages/Tasks/IndexTasks"));
const IndexStaff = lazy(() => import("./pages/Staff/IndexStaff"));
const Opportunities = lazy(() =>
  import("./pages/Opportunities/OpportunitiesIndex")
);
const EditCampaign = lazy(() => import("./pages/Campaigns/EditCampaigns"));
const DetailsTargetList = lazy(() =>
  import("./pages/Target-list/Target-List-Details")
);
const PersonaDetail = lazy(() => import("./pages/Target-list/PersonaDetail"));
const AddNewsletter = lazy(() => import("./pages/Newsletter/AddNewsletter"));
const NewsLetterPage = lazy(() => import("./pages/Newsletter/NewsLetterPage"));
const FormEdit = lazy(() => import("./pages/Templates/formEdit"));
const AddForm = lazy(() => import("./pages/Templates/addForm"));
const TemplatePage = lazy(() => import("./pages/Templates"));
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);
const SettingsMainPage = lazy(() => import("./pages/Settings/Index"));
const EditNewsLetter = lazy(() => import("./pages/Newsletter/EditNewsLetter"));
const CampaignsPage = lazy(() => import("./pages/Campaigns/Index"));
const CompaniesPage = lazy(() => import("./pages/companies/Index"));
const Chats = lazy(() => import("./pages/Chats/Index"));
const CompaniesEditPage = lazy(() =>
  import("./pages/companies/EditCompany/Index")
);
const GlobalImportpage = lazy(() => import("./pages/Imports/GlobalImport"));
const Example = lazy(() => import("./pages/EmailTemplate"));

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute
          path='/unibox'
          component={withTracker(Unibox)}
        />
        <ContentRoute
          path="/global-search"
          component={withTracker(GlobalSearch)}
        />
        <ContentRoute
          path="/global-Notifications"
          component={withTracker(GlobalNotification)}
        />
        <ContentRoute
          path="/dashboard"
          component={withTracker(DashboardExamplePage)}
        />
        <ContentRoute
          path="/global-dashboard"
          component={withTracker(GlobalDashboard)}
        />
        <ContentRoute
          path="/Sequences/management"
          component={withTracker(CampaignsPage)}
        />
        <ContentRoute
          path="/Unsubscribe/Report"
          component={UnsbscribePage}
        />
        <ContentRoute
          path="/Sequences/Edit/:id"
          component={withTracker(EditCampaign)}
        />
        <ContentRoute
          path="/Newsletters/Edit/:id"
          component={withTracker(EditNewsLetter)}
        />
        <ContentRoute
          path="/Newsletters/management"
          component={withTracker(NewsLetterPage)}
        />
        <ContentRoute
          path="/Sequences/AddCampaign"
          component={withTracker(AddCampaigns)}
        />
        <ContentRoute
          path="/Newsletters/AddNewsletter"
          component={withTracker(AddNewsletter)}
        />
        <ContentRoute
          path="/Contacts/:id/Details"
          component={withTracker(PeopleDetailPage)}
        />
        <ContentRoute
          path="/Companies/:id/Details"
          component={withTracker(CompanyDetailPage)}
        />
        <ContentRoute
          path="/Leadlist/:id/Details"
          component={withTracker(DetailsTargetList)}
        />
        <ContentRoute
          path="/settings/Persona/:id/Details"
          component={withTracker(PersonaDetail)}
        />
        <ContentRoute
          path="/Companies"
          component={withTracker(CompaniesPage)}
        />
        <ContentRoute
          path="/Companies-AccountBased"
          component={withTracker(CompaniesPage)}
        />
        <Route
          path="/Companies/Edit/:id"
          component={withTracker(CompaniesEditPage)}
        />
        <ContentRoute path="/Contacts" component={withTracker(PeoplePage)} />
        <ContentRoute path="/Businesses" component={withTracker(BusinessesPage)} />
        <ContentRoute path="/Agencies" component={withTracker(AgenciesPage)} />
        <ContentRoute
          path="/templatesList"
          component={withTracker(TemplatePage)}
        />
        {/* <Route path="/Message-Center/:id" component={formEdit} /> */}
        <ContentRoute path="/templates/add" component={withTracker(AddForm)} />
        <ContentRoute
          path="/templates/:id/edit"
          component={withTracker(FormEdit)}
        />
        <ContentRoute path="/Forms/Management" component={withTracker(FormBuilderPage)} />
        <Route path="/Form/details/:id" component={withTracker(FormDtailPage)} />
        {/* <ContentRoute path="/Message-Center" component={MessageCenterPage} /> */}
        <ContentRoute path="/Email" component={withTracker(Example)} />
        <Route path="/user-profile" component={withTracker(UserProfilepage)} />
        <Route path="/Opportunities" component={withTracker(Opportunities)} />
        <Route path="/GlobalImport" component={withTracker(GlobalImportpage)} />
        <Route path="/settings" component={withTracker(SettingsMainPage)} />
        <Route path="/Chats" component={withTracker(Chats)} />
        <Route path="/search" component={withTracker(QueryQueryPageV2)} />
        <Route path="/callback" component={withTracker(NylasCallBack)} />
        <Route path="/Staff" component={withTracker(IndexStaff)} />
        <Route path="/Tasks" component={withTracker(IndexTasks)} />
        <Route path="/Leadlist" component={withTracker(TargetList)} />
        <Route path="/global-search" component={withTracker(GlobalSearch)} />
        <Route path="/Form/:slug" component={withTracker(WebFormSlugPage)} />
        <Route path="/ThankYouPage/:id" component={withTracker(WebFormThankYouPage)} />
        <Route
          path="/Sequences/emails"
          component={withTracker(EmailCampaignPage)}
        />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
