/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { useState,useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Routes } from "../app/Routes";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import ReactGA from "react-ga4";
import { ErrorBoundary } from "./ErrorBoundary";
import { useTranslation } from "react-i18next";
import 'react-rangeslider/lib/index.css'
const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || "i18nConfig";

export default function App({ store, persistor, basename }) {
  const TRACKING_ID = process.env.REACT_APP_TRACKING_ID; // YOUR_OWN_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
 const [lang,setLang]=useState(JSON.parse(localStorage.getItem("i18nConfig")))

  const [t,i18n ]=useTranslation();
  useEffect(() => {
    
    if(i18n.changeLanguage(lang?.selectedLang)===undefined)
    {localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: "en" }));
      }else{ i18n.changeLanguage(lang?.selectedLang)
  }
  }, []);
  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <MaterialThemeProvider>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <ErrorBoundary>
                <I18nProvider>
                  {/* Render routes with provided `Layout`. */}
                  <Routes />
                </I18nProvider>
              </ErrorBoundary>
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}
