/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { DynamicModal } from "../../helpers/globals";

export function Preview(props) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function formaterFunction(parameters) {
    try {
      var tt = parameters;
      if (!!parameters) {
        (!!props.variables.variables
          ? props.variables.variables
          : props.variables
        ).forEach((element) => {
          tt = tt.replace(element.name, element.value);
        });
      }
      return tt;
    } catch (err) {}
  }

  let currentContent = useMemo(() => {
    return (
      <Card>
        <CardHeader title={"Preview template"} className="font-weight-boldest">
          {" "}
          <CardHeaderToolbar className="d-flex justify-centent-between">
            <div>
              <a
                style={{ cursor: "pointer" }}
                href={() => false}
                onClick={() => props.setShow(false)}
                className="float-right"
              >
                <i className="flaticon2-delete"></i>
              </a>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Row className="form-group">
            <Col>
              {/*begin::Page Title*/}
              <div
                className="alert alert-primary font-weight-boldest p-2"
                role="alert"
              >
                Subject
              </div>
              <span className="text-dark-700 font-weight-normal ml-2 font-size-sm p-2">
                {formaterFunction(props?.currentRow?.subject)}
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <div
                className="alert alert-primary font-weight-boldest p-2"
                role="alert"
              >
                Body
              </div>
              <div className="p-2">
                {props?.currentRow?.type === "LinkedIn" ? (
                  <pre className="text-dark-700 font-weight-normal font-size-sm">
                    {formaterFunction(props?.currentRow?.body)}
                  </pre>
                ) : (
                  <div
                    className="text-dark-700 font-weight-normal font-size-sm"
                    dangerouslySetInnerHTML={{
                      __html: formaterFunction(props?.currentRow?.body),
                    }}
                  />
                )}
                {props?.currentRow?.signature?.length > 0 && (
                  <div
                    className="text-dark-700 font-weight-normal font-size-sm"
                    dangerouslySetInnerHTML={{
                      __html: formaterFunction(props?.currentRow?.signature),
                    }}
                  />
                )}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }, [props]);
  return (
    <>
      <DynamicModal
        size="lg"
        show={props?.show}
        setShow={props.setShow}
        child={currentContent}
      />
    </>
  );
}
