/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import React from "react";
import { Row, Form, Col, Modal, Dropdown, Spinner } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
// import { Form } from "formik";
import Swal from "sweetalert2";
import { TimelineHorizontal, toAbsoluteUrl } from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import Papa from "papaparse";
import Select from "react-select";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import ReplyForm from "../pages/EmailCampaigns/Box/replyForm";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { ABMPage } from "../pages/companies/ABMPage";
import { v4 as uuidv4 } from 'uuid';

export const timeOptions = [
  { value: 24, label: '12:00 AM' },
  { value: 1, label: '1:00 AM' },
  { value: 2, label: '2:00 AM' },
  { value: 3, label: '3:00 AM' },
  { value: 4, label: '4:00 AM' },
  { value: 5, label: '5:00 AM' },
  { value: 6, label: '6:00 AM' },
  { value: 7, label: '7:00 AM' },
  { value: 8, label: '8:00 AM' },
  { value: 9, label: '9:00 AM' },
  { value: 10, label: '10:00 AM' },
  { value: 11, label: '11:00 AM' },
  { value: 12, label: '12:00 PM' },
  { value: 13, label: '1:00 PM' },
  { value: 14, label: '2:00 PM' },
  { value: 15, label: '3:00 PM' },
  { value: 16, label: '4:00 PM' },
  { value: 17, label: '5:00 PM' },
  { value: 18, label: '6:00 PM' },
  { value: 19, label: '7:00 PM' },
  { value: 20, label: '8:00 PM' },
  { value: 21, label: '9:00 PM' },
  { value: 22, label: '10:00 PM' },
  { value: 23, label: '11:00 PM' },
];

export function addIdToListElements(list) {
  let data = [...list];
  for (let i = 0; i < data.length; i++) {
    data[i].id = `element-${uuidv4()}-${i + 1}`;
  }
  return data;
}
export function ScheduleComponent(props) {
  var dataSchedule = props?.dataSchedule;
  var setDataSchedule = props?.setDataSchedule;
  const [selectedFromTime, setSelectedFromTime] = useState({}); // Store selected "From time" for each day
  useEffect(() => {
    if(!!dataSchedule === true){
      dataSchedule.forEach((d) => {
        console.log("🚀 ~ file: globals.js:76 ~ dataSchedule.forEach ~ d:", d)
        var e =  {
          value:
            d?.fromTime,
          label:
          d?.textFrom,
        };
        setSelectedFromTime((prevSelected) => ({
          ...prevSelected,
          [d?.dayNumber]: e,
        }));
      })
    }
  },[dataSchedule]);
  function GetFromToTime({ day }) {
  // Filter "To time" options based on the selected "From time"
  const toTimeOptions = timeOptions.filter((option) => {
    if (selectedFromTime[day]) {
      if (selectedFromTime[day].value === 24) {
        return option.value < 24; // Allow all options less than 24
      }
      return option.value === 24 ? option.value < selectedFromTime[day].value : option.value > selectedFromTime[day].value;
    }else{
      if(!!dataSchedule[dataSchedule.findIndex((d) => d?.dayNumber === day)]
      ?.fromTime === true){
        return (
          option.value >
          dataSchedule[dataSchedule.findIndex((d) => d?.dayNumber === day)]
            ?.fromTime
        );
      }
    }
    return true;
  });
    return !!props?.dataSchedule === false ? (
      "..."
    ) : (
      <Fragment>
        <div className="mb-4">
          <Select
            classNamePrefix="react-select"
            size="sm"
            isClearable
            placeholder={"From time"}
            value={
              !!dataSchedule[
                dataSchedule.findIndex((d) => d?.dayNumber === day)
              ]?.fromTime === true &&
              !!dataSchedule[
                dataSchedule.findIndex((d) => d?.dayNumber === day)
              ].textFrom === true
                ? {
                    value:
                      dataSchedule[
                        dataSchedule.findIndex((d) => d?.dayNumber === day)
                      ].fromTime,
                    label:
                      dataSchedule[
                        dataSchedule.findIndex((d) => d?.dayNumber === day)
                      ].textFrom,
                  }
                : null
            }
            onChange={(e) => {
              const value = e?.value;
              var data = [...dataSchedule];
              if (value) {
                setSelectedFromTime((prevSelected) => ({
                  ...prevSelected,
                  [day]: e,
                }));
                if (data.some((d) => d?.dayNumber === day)) {
                  data[
                    data.findIndex((d) => d?.dayNumber === day)
                  ].fromTime = value;
                  data[data.findIndex((d) => d?.dayNumber === day)].textFrom =
                    e?.label;
                } else {
                  data.push({
                    dayNumber: day,
                    fromTime: value,
                    textFrom: e?.label,
                  });
                }
              } else {
                setSelectedFromTime((prevSelected) => ({
                  ...prevSelected,
                  [day]: null,
                }));
                data = data?.filter((s) => s?.dayNumber !== day);
              }
              setDataSchedule(data);
            }}
            options={timeOptions}
            id={`selectFrom-${day}`}
          />
        </div>
        <div>
          <Select
            classNamePrefix="react-select"
            size="sm"
            isClearable
            isDisabled={
              !!dataSchedule[
                dataSchedule.findIndex((d) => d?.dayNumber === day)
              ]?.fromTime === false
            }
            placeholder={"To time"}
            options={toTimeOptions}
            value={
              !!dataSchedule[
                dataSchedule.findIndex((d) => d?.dayNumber === day)
              ]?.toTime === true &&
              !!dataSchedule[
                dataSchedule.findIndex((d) => d?.dayNumber === day)
              ].textTo === true
                ? {
                    value:
                      dataSchedule[
                        dataSchedule.findIndex((d) => d?.dayNumber === day)
                      ].toTime,
                    label:
                      dataSchedule[
                        dataSchedule.findIndex((d) => d?.dayNumber === day)
                      ].textTo,
                  }
                : null
            }
            onChange={(e) => {
              const value = e?.value;
              var data = [...dataSchedule];
              if (value) {
                if (data.some((d) => d?.dayNumber === day)) {
                  data[
                    data.findIndex((d) => d?.dayNumber === day)
                  ].toTime = value;
                  data[data.findIndex((d) => d?.dayNumber === day)].textTo =
                    e?.label;
                } else {
                  data.push({
                    dayNumber: day,
                    toTime: value,
                    textTo: e?.label,
                  });
                }
              } else {
                data = data?.filter((s) => s?.dayNumber !== day);
              }
              setDataSchedule(data);
            }}
            id={`selectTo-${day}`}
          />
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Row>
        <Col>
          <div className="d-inline">
            <span className="label label-lg font-weight-bold label-inline label-primary w-100 mb-4">
              Monday
            </span>
            <GetFromToTime day={1} />
          </div>
        </Col>
        <Col>
          <div className="d-inline">
            <span className="label label-lg font-weight-bold label-inline label-primary w-100 mb-4">
              Tuesday
            </span>
            <GetFromToTime day={2} />
          </div>
        </Col>
        <Col>
          <div className="d-inline">
            <span className="label label-lg font-weight-bold label-inline label-primary w-100 mb-4">
              Wednesday
            </span>
            <GetFromToTime day={3} />
          </div>
        </Col>
        <Col>
          <div className="d-inline">
            <span className="label label-lg font-weight-bold label-inline label-primary w-100 mb-4">
              Thursday
            </span>
            <GetFromToTime day={4} />
          </div>
        </Col>
        <Col>
          <div className="d-inline">
            <span className="label label-lg font-weight-bold label-inline label-primary w-100 mb-4">
              Friday
            </span>
            <GetFromToTime day={5} />
          </div>
        </Col>
        <Col>
          <div className="d-inline">
            <span className="label label-lg font-weight-bold label-inline label-primary w-100 mb-4">
              Saturday
            </span>
            <GetFromToTime day={6} />
          </div>
        </Col>
        <Col>
          <div className="d-inline">
            <span className="label label-lg font-weight-bold label-inline label-primary w-100 mb-4">
              Sunday
            </span>
            <GetFromToTime day={0} />
          </div>
        </Col>
      </Row>
    </Fragment>
  );
}

export function ToggleTrackingOnOff(props) {
  return (
    <Fragment>
      <form class="form">
        <Row>
          <Col>
            <label class="col-form-label">{props?.label}</label>
            <div class="form-group row">
              <div class="col-9 col-form-label">
                <div class="radio-inline">
                  <label class="radio radio-primary">
                    <input
                      type="radio"
                      name={props?.name}
                      checked={props?.value}
                      onChange={(e) => {
                        props.setValue(true);
                      }}
                    />
                    <span></span>
                    On
                  </label>
                  <label class="radio radio-primary">
                    <input
                      type="radio"
                      name={props?.name}
                      checked={!props?.value}
                      onChange={(e) => {
                        props.setValue(false);
                      }}
                    />
                    <span></span>
                    Off
                  </label>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </form>
    </Fragment>
  );
}

export function validateEmail(email) {
  if (email === "") return true;
  // Regular expression pattern for email validation
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Test the email against the pattern
  return emailPattern.test(email);
}

export function ABMSendingEmail(props) {
  return (
    <ABMPage
      id={props?.id}
      isAbmAuthorized={props?.isAbmAuthorized}
      initialValues={props?.initialValues}
      idsTable={props?.idsTable}
      rows={props?.rows}
      isSendEmail={props?.isSendEmail}
      setIdsTable={props?.setIdsTable}
      setLoaderTable={props?.setLoaderTable}
      ContactSelectedSendEmail={props?.ContactSelectedSendEmail}
      setContactSelectedSendEmailState={props?.setContactSelectedSendEmailState}
      {...props}
    />
  );
}

export function formatDateV2(dateValue) {
  var date = new Date(dateValue);
  var year = date.getFullYear();
  var month = (date.getMonth() + 1).toString().padStart(2, "0");
  var day = date
    .getDate()
    .toString()
    .padStart(2, "0");

  return year + "-" + month + "-" + day;
}

export function removeDuplicates(arr, prop) {
  const uniqueItems = new Map();
  return arr.filter((item) => {
    if (!uniqueItems.has(item[prop])) {
      uniqueItems.set(item[prop], true);
      return true;
    }
    return false;
  });
}

export function truncateString(str, maxLength) {
  try {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    }
    return str;
  } catch (err) {
    console.log("err :", err);
  }
  return "";
}

function escapeRegExp(str) {
  return str.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&");
}

function removeSpecialCharacters(str) {
  return str.replace(/[.*+\-?^${}()|[\]\\]/g, "");
}

export function checkMappedField(str, phrase) {
  const escapedPhrase = escapeRegExp(phrase);
  const strWithoutSpecialChars = removeSpecialCharacters(str);
  if (
    strWithoutSpecialChars.match(
      new RegExp(escapedPhrase.split(/\W/).join("[\\W]*"), "i")
    )
  ) {
    return true;
  }
  return false;
}

export function PoweredByStripe(props) {
  return (
    <div style={{ textAlign: props?.isAligned ? "end" : "" }}>
      <SVG
        style={{
          height: "76%",
          opacity: "0.7",
          width: props?.isAligned ? "45%" : "",
        }}
        className="align-self-center"
        src={toAbsoluteUrl("/media/svg/icons/General/Stripe.svg")}
      />
    </div>
  );
}

export function capitalizeFirstLastName(string) {
  return string
    .toLowerCase()
    .split(" ")
    .map((s, i) =>
      i === 0
        ? s.charAt(0).toLowerCase() + s.substring(1)
        : s.charAt(0).toUpperCase() + s.substring(1)
    )
    .join("");
}

export function EmailModalReply(props) {
  const [loaderDetail, setLoaderDetail] = useState(true);
  const [replyForm, setReplyForm] = useState(false);
  const [loaderRefresh, setLoaderRefresh] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [variables, setVariables] = useState([]);
  async function getDataItem(id) {
    await apiCall({
      method: "GET",
      link: `/api/Campaigns/EmailDetails/${id}`,
    }).then((dataResult) => {
      try {
        if (dataResult[0] === 200) {
          setCurrentRow(dataResult[1] ?? null);
          setLoaderDetail(false);
        }
      } catch (err) {
        setLoaderDetail(false);
      }
    });
  }
  const [viewModelCompanies, setViewModelCompanies] = useState([]);
  const bodyTemplate = useMemo(() => {
    return (
      <div class="divReply">
        <div>
          <label class="font-weight-bolder pr-1">Sent at:</label>
          <span>
            {!!currentRow?.sentDate
              ? moment(
                  ConvertToDateCurrentTimeZoneV2(currentRow?.sentDate)
                ).format(getFromatdate() + " hh:mm A")
              : ""}
          </span>
        </div>
        {!currentRow?.isReply ? (
          <div>
            <label class="font-weight-bolder pr-1">From:</label>
            <span>{currentRow?.provider}</span>
          </div>
        ) : (
          <div>
            <label class="font-weight-bolder pr-1">To:</label>
            <Link
              class="text-primary"
              to={`/Contacts/${currentRow?.contactId}/Details`}
            >
              {`${currentRow?.firstName ?? "-"}  ${currentRow?.lastName ??
                "-"}`}{" "}
            </Link>
            {currentRow?.toEmail && (
              <Fragment>
                {"<"}
                <a
                  href={`mailto:${currentRow?.toEmail}`}
                  class="text-primary"
                >{`${currentRow?.toEmail}`}</a>
                {">"}
              </Fragment>
            )}
          </div>
        )}

        {currentRow?.isReply ? (
          <div>
            <label class="font-weight-bolder pr-1">From:</label>
            <span>{currentRow?.provider}</span>
          </div>
        ) : (
          <div>
            <label class="font-weight-bolder pr-1">To:</label>
            <Link
              class="text-primary"
              to={`/Contacts/${currentRow?.contactId}/Details`}
            >
              {`${currentRow?.firstName ?? "-"}  ${currentRow?.lastName ??
                "-"}`}{" "}
            </Link>
            {currentRow?.toEmail && (
              <Fragment>
                {"<"}
                <a
                  href={`mailto:${currentRow?.toEmail}`}
                  class="text-primary"
                >{`${currentRow?.toEmail}`}</a>
                {">"}
              </Fragment>
            )}
          </div>
        )}

        {currentRow?.ccs.length === 0 && currentRow?.bccs.length === 0 ? (
          ""
        ) : (
          <>
            {/* Your layout content goes here */}
            {currentRow?.ccs.length === 0 ? (
              ""
            ) : (
              <>
                {currentRow?.ccs?.map((item) => {
                  return (
                    <div className="d-flex mb-2">
                      <label class="font-weight-bolder pr-1">cc :</label>
                      <Link
                        class="text-primary"
                        to={`/Contacts/${item?.contactId}/Details`}
                        onClick={() => {
                          props?.setShow(false);
                        }}
                      >
                        {item?.firstName} {item?.lastName}{" "}
                      </Link>
                      <div className="ml-1">
                        <Fragment>
                          {" "}
                          {" <"}
                          <a
                            href={`mailto:${item?.email}`}
                            class="text-primary "
                          >{`${item?.email}`}</a>
                          {">"}
                        </Fragment>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
            {currentRow?.bccs.length === 0 ? (
              ""
            ) : (
              <>
                {currentRow?.bccs?.map((item) => {
                  return (
                    <div className="d-flex mb-2">
                      <label class="font-weight-bolder pr-1">Bcc :</label>
                      <Link
                        class="text-primary"
                        to={`/Contacts/${item?.contactId}/Details`}
                      >
                        {item?.firstName} {item?.lastName}{" "}
                      </Link>
                      <div className="ml-1">
                        <Fragment>
                          {"<"}
                          <a
                            href={`mailto:${item?.email}`}
                            class="text-primary"
                          >{`${item?.email}`}</a>
                          {">"}
                        </Fragment>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </>
        )}

        <div class="mb-4">
          <label class="font-weight-bolder pr-1">Subject:</label>
          <span>{replaceValues(currentRow?.subject ?? "-", variables)}</span>
        </div>
        <div class="body-email">
          <div
            dangerouslySetInnerHTML={createMarkup(
              replaceValues(currentRow?.body ?? "-", variables)
            )}
          />
        </div>
      </div>
    );
  }, [currentRow, variables]);

  const bodyTemplateReplied = useMemo(() => {
    return !!currentRow?.reply && currentRow?.reply?.length > 0
      ? currentRow?.reply
          ?.map(
            (x, i) =>
              `<div class="divReply bg-white px-10 py-5 mb-5 rounded" key={${i}}>
              ${
                !!x?.replaceBody === false
                  ? `<span class="text-muted font-weight-bolder">
                    Message has been queued and will be sent shortly...
                  </span>`
                  : ""
              }
        ${
          x?.isReply
            ? `<div class="${!!x?.replaceBody === false ? "pt-3" : ""}">
          <label style="padding-right : 0.5rem;font-weight: 700">${
            currentRow?.bounce ? "Bounced at:" : "Replied at:"
          }</label>
          <span>${moment(ConvertToDateCurrentTimeZoneV2(x?.replyDate)).format(
            getFromatdate() + " hh:mm A"
          )}</span>
        </div>`
            : ""
        }
       ${
         currentRow?.bounce
           ? ""
           : `<div class="pt-3 pl-4 ">
              <label class="font-weight-bolder pr-1 ">From:</label>
              ${
                x.isReply === true
                  ? `<span>${x?.contactFirstName}  ${x?.contactLastName}</span>`
                  : `<span>${x?.provider}</span>`
              }
            </div> `
       }
      <div class="${!!x?.replaceBody === false ? "pl-4" : "pl-4"}">
        <label class="font-weight-bolder pr-1">To:</label>
        ${
          !x.isReply === true
            ? `<span>${x?.contactFirstName}  ${x?.contactLastName}</span>`
            : `<span>${x?.provider}</span>`
        }
      </div>
      <div class="row pl-md-8">
        ${replaceValues(x?.replyBody, variables)}
      </div>
      ${
        !!x?.isReply === false
          ? `      <div class="d-flex justify-content-end">
      <div class="row">
        <div class="col">
          <div
            class="timeline-steps aos-init aos-animate"
            data-aos="fade-up"
          >
          <div
            class='${x.sent ? "active" : ""} timeline-step'
          >
            <div
              class='timeline-content'
              data-toggle="popover"
              data-trigger="hover"
              data-placement="top"
              title=""
              data-content=""
              data-original-title={2003}
            >
              <div
                class='${x.sent ? "active" : ""} inner-circle'
              >
                <i class="fa fa-envelope icon-sm text-white"></i>
              </div>
            </div>
          </div>
          <div
            class='${x.isOpen ? "active" : ""} timeline-step'
          >
            <div
              class="timeline-content"
              data-toggle="popover"
              data-trigger="hover"
              data-placement="top"
              title=""
              data-content=""
              data-original-title={2004}
            >
              <div
                class='${x.isOpen ? "active inner-circle" : "inner-circle"}'
              >
                <i class="fa fa-eye icon-sm text-white"></i>
                ${
                  x.isOpen
                    ? `<div class="chiffer-top">${x.openCount}</div>`
                    : ""
                }
              </div>
            </div>
          </div>
          <div
            class='${x.isClicked ? "active" : ""} timeline-step'
          >
            <div
              class="timeline-content"
              data-toggle="popover"
              data-trigger="hover"
              data-placement="top"
              title=""
              data-content=""
              data-original-title={2005}
            >
              <div
                class='${x.isClicked ? "active" : ""} inner-circle'
              >
                <i class="fa fa-hand-pointer icon-sm text-white"></i>
                ${
                  x.isClicked
                    ? `<div class="chiffer-top">${x.clickCount ?? 0}</div>`
                    : ""
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>`
          : ""
      }

      </div>
    </div>`
          )
          .join("")
      : "";
  }, [currentRow, variables]);
  const viewModel = [];
  const [contacts, setContacts] = useState([]);

  //useEffect
  useEffect(() => {
    if (!!props?.email && !!currentRow === false) {
      apiCall({
        method: "GET",
        link: `/api/Campaigns/EmailDetails/${props?.email?.id}`,
      }).then((dataResult) => {
        try {
          if (dataResult[0] === 200) {
            setCurrentRow(dataResult[1] ?? null);
            setLoaderDetail(false);
          }
        } catch (err) {
          setLoaderDetail(false);
        }
      });

      if (!!props?.id) {
        apiCall({
          method: "GET",
          link: `/api/Campaigns/GetContactIdsByCampain?campaignId=${props?.id}`,
        }).then((dataResult) => {
          try {
            if (dataResult[0] === 200) {
              setContacts(dataResult[1] || []);
            }
          } catch (err) {}
        });
      }
      apiCall({
        method: "GET",
        link: `/api/Companies/GetViewModel`,
      }).then((data) => {
        if (data?.length > 0 && data[0] === 200) {
          setViewModelCompanies(data[1]);
        }
      });
    }
  }, [props]);
  useEffect(() => {
    if (!!currentRow) {
      apiCall({
        method: "GET",
        link: `/api/Templates/GetVariables?contactId=${currentRow?.contactId}`,
      }).then((dataResult) => {
        try {
          if (dataResult[0] === 200) {
            setVariables(dataResult[1]?.variables || []);
          }
        } catch (err) {}
      });
    }
  }, [currentRow]);
  return (
    <DynamicModal
      size="xl"
      show={props?.show}
      setShow={props?.setShow}
      child={
        <Card>
          <CardHeader title="Sent email" className="font-weight-boldest">
            <CardHeaderToolbar className="d-flex justify-centent-between">
              <div>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => props?.setShow(false)}
                  className="float-right"
                >
                  <i className="flaticon2-delete"></i>
                </span>
              </div>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody className="px-0">
            {loaderDetail || !!currentRow === false ? (
              <div style={{ height: "auto" }}>
                <div className="d-flex justify-content-center mt-md-10">
                  <h3 className="text-muted">Loading </h3>
                  <Spinner
                    as="span"
                    animation="border"
                    className="mb-1 mx-2 mt-2 text-muted"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </div>
              </div>
            ) : (
              <Fragment>
                <div className="col-lg-12 px-10 mb-7">{bodyTemplate}</div>
                <div className="d-flex justify-content-start align-items-center align-items-start mb-3 pl-10">
                  {replyForm ? (
                    <button
                      className="btn btn-danger btn-sm mr-4"
                      onClick={(e) => {
                        e.preventDefault();
                        setReplyForm(false);
                      }}
                    >
                      <i className="fas fa-times icon-sm pr-2"></i>
                      Close
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary btn-sm mr-4"
                      onClick={(e) => {
                        e.preventDefault();
                        setReplyForm(true);
                        setTimeout(() => {
                          try {
                            document
                              .getElementById("bottomContent")
                              .scrollIntoView({ behavior: "smooth" });
                          } catch (err) {}
                        }, 300);
                      }}
                    >
                      <i className="fas fa-reply icon-sm pr-2"></i>
                      Reply
                    </button>
                  )}
                  <button
                    className="btn btn-primary btn-sm mr-4"
                    disabled={loaderRefresh}
                    onClick={async (e) => {
                      e.preventDefault();
                      setLoaderRefresh(true);
                      await getDataItem(currentRow?.id);
                      setLoaderRefresh(false);
                    }}
                  >
                    {!loaderRefresh && (
                      <i className="flaticon2-refresh-button icon-sm pr-2"></i>
                    )}
                    Refresh
                    {loaderRefresh && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        className="ml-2"
                        aria-hidden="true"
                      />
                    )}
                  </button>
                  <div className="pt-2">
                    <TimelineHorizontal item={currentRow} isModal={true}/>
                  </div>
                </div>
                {replyForm && (
                  <Row>
                    <Col className="px-14">
                      <ReplyForm
                        GetData={() => ""}
                        key={currentRow?.id}
                        item={currentRow}
                        viewModelCompanies={viewModelCompanies}
                        templates={props?.templates}
                        to={`${currentRow?.firstName} ${currentRow?.lastName}`}
                        subject={replaceValues(currentRow?.subject, variables)}
                        getDataItem={() => getDataItem(currentRow?.id)}
                        body={
                          <div
                            dangerouslySetInnerHTML={createMarkup(
                              replaceValues(currentRow?.body, variables)
                            )}
                          />
                        }
                        setReplyForm={setReplyForm}
                        variables={variables}
                        contactList={contacts}
                        viewModel={viewModel}
                      />
                    </Col>
                  </Row>
                )}
                {!!currentRow?.reply && currentRow?.reply?.length > 0 && (
                  <Fragment>
                    <Row>
                      <Col className="px-12 mb-3">
                        <div className="d-flex justify-content-start align-items-center">
                          <h4 className="text-primary">Replies</h4>
                          <div
                            className="timeline-steps aos-init aos-animate"
                            data-aos="fade-up"
                          >
                            <div className={` timeline-step active mb-0`}>
                              <div
                                className={`timeline-content`}
                                data-toggle="popover"
                                data-trigger="hover"
                                data-placement="top"
                                title=""
                                data-content=""
                                data-original-title={2020}
                              >
                                <div className={` inner-circle active`}>
                                  <i className="fa fa-reply icon-sm text-white"></i>

                                  <div className="chiffer-top">
                                    {" "}
                                    {currentRow?.reply?.length}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="col-lg-12 bg-primary-o-30 px-12 py-5">
                      <div
                        className={
                          currentRow?.reply?.length <= 3
                            ? ""
                            : "scrollDivReplies"
                        }
                      >
                        <div
                          dangerouslySetInnerHTML={createMarkup(
                            bodyTemplateReplied
                          )}
                        />
                      </div>
                    </div>
                  </Fragment>
                )}
              </Fragment>
            )}
          </CardBody>
        </Card>
      }
    />
  );
}

export function LoaderGlobal(props) {
  return props?.isCard ? (
    <Card>
      <CardBody>
        <div className="text-center">
          <span className="text-muted mr-3" style={{ fontSize: "1.5rem" }}>
            Loading ...
          </span>
        </div>
      </CardBody>
    </Card>
  ) : (
    <div className="text-center">
      <span className="text-muted mr-3" style={{ fontSize: "1.5rem" }}>
        Loading ...
      </span>
    </div>
  );
}
export function ExportGlobal(props) {
  function Export(Type) {
    props.setExporting(true);
    apiCall({
      method: "GET",
      link: props?.link,
    })
      .then(async (result) => {
        if (!!result && result.length > 0 && result[0] === 200) {
          var data = result[1];
          let dataResult = [...data[props?.fieldName]];
          if (props?.deletedFields?.length > 0) {
            props.deletedFields.forEach((x) => {
              dataResult
                .map((s) => {
                  delete s[x];
                  return s;
                })
                .join("");
            });
          }
          ExportAs(dataResult, Type, props?.fieldName);
          props.setExporting(false);
        }
      })
      .catch((error) => {
        props.setExporting(false);
        console.log("error", error);
      });
  }
  return (
    <Dropdown>
      <Dropdown.Toggle
        className="mr-2"
        variant={!!props?.variant ? props?.variant : "primary"}
        id="dropdown-basic"
        size="sm"
      >
        Export as
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => {
            Export("excel");
          }}
          href={"#"}
        >
          <i className="fas fa-file-excel mr-3"></i> Excel
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            Export("csv");
          }}
          href="#"
        >
          <i className="fas fa-file-csv mr-3"></i> Csv
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            Export("json");
          }}
          href="#"
        >
          <i className="fas fa-file mr-3"></i> Json
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export function getBase64(file, setState) {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function() {
    setState(reader.result);
    console.log(reader.result);
  };
  reader.onerror = function(error) {
    console.log("Error: ", error);
  };
}

export function groupByKey(array, key) {
  return array.reduce((hash, obj) => {
    if (obj[key] === undefined) return hash;
    return Object.assign(hash, {
      [obj[key]]: (hash[obj[key]] || []).concat(obj),
    });
  }, {});
}

export function useIsMounted() {
  const isMountedRef = useRef(true);
  const isMounted = useCallback(() => isMountedRef.current, []);

  useEffect(() => {
    return () => void (isMountedRef.current = false);
  }, []);

  return isMounted;
}
export function getFirstParagraph(html) {
  if (!!html) {
    const first200 = html.substring(0, 240);
    const split = first200.split("<p>");
    return split[1] ? split[1] + "..." : "...";
  } else {
    return "...";
  }
}
export function RemoveDollar(str = "") {
  try {
    return parseInt(str.replaceAll("$", ""));
  } catch (e) {
    return 0;
  }
}

export function timeSince(dt) {
  var date = new Date(moment(Number(dt)).format("lll"));
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " year";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " month";
  }
  interval = seconds / 86400;
  if (interval >= 1 && interval < 2) {
    return Math.floor(interval) + " day";
  } else if (interval > 2) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval >= 1 && interval < 2) {
    return Math.floor(interval) + " hr";
  } else if (interval > 2) {
    return Math.floor(interval) + " hrs";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " min";
  }
  return "just now";
}

export function formatDateCustom(date) {
  if (date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    const result = [year, month, day].join("-");
    if (result !== "NaN/NaN/NaN") {
      return result;
    } else {
      return "";
    }
  } else {
    return "";
  }
}
export function arrayInsertAt(destArray, pos, arrayToInsert) {
  var args = [];
  args.push(pos); // where to insert
  args.push(0); // nothing to remove
  args = args.concat(arrayToInsert); // add on array to insert
  destArray.splice.apply(destArray, args); // splice it in
}
export function replaceValues(parameters, array) {
  try {
    var tt = parameters;
    if (!!parameters) {
      array.forEach((element) => {
        tt = tt.replaceAll(element.name, element.value);
      });
    }
    return tt;
  } catch (err) {
    console.log("err", err);
  }
}

export const containsHTML = (str) => /<[a-z][\s\S]*>/i.test(str);

export function stripHtml(html) {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

function returncsv(values) {
  var json = values;
  var fields = Object.keys(json[0]);
  var replacer = function(key, value) {
    return value === null ? "" : value;
  };
  var csv = json.map(function(row) {
    return fields
      .map(function(fieldName) {
        return JSON.stringify(row[fieldName], replacer);
      })
      .join(",");
  });
  csv.unshift(fields.join(",")); // add header columnmoment
  csv = csv.join("\r\n");
  return csv;
}

export function capitalizeCustom(s) {
  return s?.length > 0 ? s[0].toUpperCase() + s.slice(1) : "";
}
export function DynamicModal(props) {
  return (
    <Modal
      size={props?.size}
      show={props?.show}
      backdrop={props?.backdrop}
      enforceFocus={false}
      className={props?.className ?? ""}
      onHide={() => {
        props.setShow(false);
      }}
    >
      {props?.child}
    </Modal>
  );
}

export function createMarkup(dataString) {
  return { __html: dataString };
}
export function InputPassword(props) {
  const [isShowPassword, setShowPassword] = useState(false);

  return (
    <div className={!!props?.isFormGroup === false ? "form-group" : ""}>
      <div className="input-icon input-icon-right">
        <input
          type={isShowPassword ? "text" : "password"}
          className="form-control form-control-sm"
          placeholder={props?.placeholder}
          name={props?.name}
          aria-describedby="basic-addon2"
          onChange={props.onChange}
          {...(!!props?.formikParams ? props.formikParams : null)}
        />
        <span onClick={(e) => setShowPassword(!isShowPassword)}>
          {!isShowPassword ? (
            <SVG src={toAbsoluteUrl("/media/svg/input/eye-solid.svg")} />
          ) : (
            <SVG src={toAbsoluteUrl("/media/svg/input/eye-slash-solid.svg")} />
          )}
        </span>
      </div>
    </div>
  );
}

export function getFromatdate() {
  try {
    if (
      localStorage.getItem("dateFormat") === null ||
      localStorage.getItem("dateFormat") === "null" ||
      localStorage.getItem("dateFormat") === undefined ||
      localStorage.getItem("dateFormat") === "" ||
      localStorage.getItem("dateFormat") === "string"
    ) {
      return "MM/DD/YYYY";
    } else {
      return localStorage.getItem("dateFormat");
    }
  } catch (err) {
    console.log("err", err);
  }
}

export const RequiredHtml = () => {
  return (
    <i
      className="fa fa-star-of-life text-danger icon-l ml-2"
      style={{ fontSize: "6px", position: "absolute" }}
    ></i>
  );
};
export const getInputClasses = (fieldname, formik) => {
  try {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  } catch (err) {
    console.log("err", err);
  }
};
export function InputItem(props) {
  return (
    <Fragment>
      <Form.Group controlId={props?.name} className={`${props?.className} mb-3`}>
        <Form.Label className="mb-1 font-size-sm">{props?.label}</Form.Label>
        {props?.isRequired && <RequiredHtml />}
        <Form.Control
          key={props?.name}
          type={props?.type}
          name={props?.name}
          placeholder={props?.placeholder}
          onChange={(e) => {
            if (!!props?.onChange) props.onChange(e);
          }}
          value={props?.value ?? ""}
          size="sm"
          // {...props?.formik?.getFieldProps(props?.name)}
        />
        {props?.formik?.touched[props?.name] &&
        props?.formik?.errors[props?.name] ? (
          <div className="invalid-feedback">
            {props?.formik?.errors[props?.name]}
          </div>
        ) : null}
      </Form.Group>
      {/* <div className="row">
          <div className="col-lg-12 col-xl-12">
            <h5 className="font-weight-bold mt-10 mb-2 font-size-sm">
              {props?.label}
            </h5>
          </div>
        </div>
        <Row>
          {props?.typeInput === "phone" ? (
            <div className="col-lg-12 col-xl-12">
              <div className="input-group input-group-sm">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fa fa-phone"></i>
                  </span>
                </div>
                <input
                  type="text"
                  placeholder="+1(123)112-11-11"
                  className={`form-control form-control-sm`}
                  name={props?.name}
                  {...props?.formik?.getFieldProps(props?.name)}
                />
                {props?.formik.touched[props?.name] &&
                props?.formik.errors[props?.name] ? (
                  <div className="invalid-feedback">
                    {props?.formik.errors[props?.name]}
                  </div>
                ) : null}
              </div>
            </div>
          ) : props?.typeInput === "email" ? (
            <div className="col-lg-12 col-xl-12">
              <div className="input-group input-group-sm">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fa fa-at"></i>
                  </span>
                </div>
                <input
                  type="email"
                  placeholder={props?.placeholder}
                  className={`form-control form-control-sm`}
                  name={props?.name}
                  {...props?.formik?.getFieldProps(props?.name)}
                />
                {props?.formik.touched[props?.name] &&
                props?.formik.errors[props?.name] ? (
                  <div className="invalid-feedback">
                    {props?.formik.errors[props?.name]}
                  </div>
                ) : null}
              </div>
            </div>
          ) : (
            <div className="col-lg-12 col-xl-12">
              <input
                type={props?.type}
                placeholder={props?.placeholder}
                className={`form-control form-control-sm`}
                name={props.name}
                {...props?.formik?.getFieldProps(props?.name)}
              />
              {props?.formik.touched[props?.name] &&
              props?.formik.errors[props?.name] ? (
                <div className="invalid-feedback">
                  {props?.formik.errors[props?.name]}
                </div>
              ) : null}
            </div>
          )}
        </Row> */}
    </Fragment>
  );
}
export function ItemLabel({ title, value }) {
  return (
    <Row className="mb-3">
      <Col>
        <span className="titleLabel1">{title}: </span>
        <span className="valueLabel1 text-muted">{value}</span>
      </Col>
    </Row>
  );
}
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const token =
  localStorage?.getItem("user") !== null
    ? JSON.parse(localStorage.getItem("user") ?? "").token
    : "";

export async function apiCall(props) {
  const currentlang = localStorage.getItem("i18nConfig");
  const token =
    (typeof localStorage.getItem("user")?.token ?? "") === "string"
      ? localStorage.getItem("user")?.token ?? ""
      : JSON.parse(localStorage.getItem("user"))?.token ?? "";
  return await fetch(`${BASE_URL}${props?.link}`, {
    method: props?.method,
    headers: props?.isFormData
      ? {
          Authorization: "Bearer " + token,
          "Accept-Language": currentlang || "en",
        }
      : {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
          "Accept-Language": currentlang || "en",
        },
    signal: props?.signal,
    body: props?.isFormData ? props?.data : JSON.stringify(props?.data),
  })
    .then(async (response) => {
      const statusCode = response.status;
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.json().then((data) => [statusCode, data]);
      } else {
        return response.text().then((data) => [statusCode, data]);
      }
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export function ItemNumberStep(props) {
  return (
    <div className="d-flex flex-nowrap align-items-center">
      <div className="mr-3">
        <div
          className={`${
            props?.currentIndex === props?.number ? "activeCardNumber" : ""
          } itemCardNumber mt-1 bgCardNumber`}
        >
          {props?.number}
        </div>
      </div>
      <div className={`pt-1 ${props?.isUpgrade ? "" : ""}`}>
        <span
          className={`titleItem ${props?.isUpgrade ? "smSize14" : "smSize11"}`}
        >
          {props?.title}
        </span>
      </div>
    </div>
  );
}

export const handleParse = (file, setData) => {
  if (!file) return false;
  const reader = new FileReader();
  reader.onload = async ({ target }) => {
    const csv = Papa.parse(target.result, { header: true });
    const parsedData = csv?.data;
    const columns = Object.keys(parsedData[0]);
    var a = columns.filter((x) => x !== "");
    setData(a);
  };
  reader.readAsText(file);
};

export const handleParseCsv = (file, setData) => {
  if (!file) return false;
  if (!file.type.match("csv.*")) {
    Swal.fire("Oops?", "Accept only file CSV", "error");
    return false;
  }

  const reader = new FileReader();
  reader.onload = async ({ target }) => {
    const csv = Papa.parse(target.result, {});
    var list = [];
    (csv?.data ?? []).forEach((element) => {
      list = list.concat(element);
    });
    var a = list.filter((x) => x !== "");
    setData(a);
  };
  reader.readAsText(file);
};

export function creditCardTypeFromNumber(number) {
  try {
    // visa
    var re = new RegExp("^4");
    if (number.match(re) != null) return "Visa";

    // Mastercard
    // Updated for Mastercard 2017 BINs expansion
    if (
      /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
        number
      )
    )
      return "MasterCard";

    // AMEX
    re = new RegExp("^3[47]");
    if (number.match(re) != null) return "Amex";

    // Discover
    re = new RegExp(
      "^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)"
    );
    if (number.match(re) != null) return "Discover";

    // Diners
    re = new RegExp("^36");
    if (number.match(re) != null) return "Diners";

    // Diners - Carte Blanche
    re = new RegExp("^30[0-5]");
    if (number.match(re) != null) return "Diners - Carte Blanche";

    // JCB
    re = new RegExp("^35(2[89]|[3-8][0-9])");
    if (number.match(re) != null) return "JCB";

    // Visa Electron
    re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
    if (number.match(re) != null) return "Visa Electron";

    // Union Pay
    if (number.match(/^(62[0-9]{14,17})$/)) return "Union Pay";
    return "";
  } catch (err) {
    console.log("~err", err);
  }
}

export function setValueField(data, setData, value, column) {
  try {
    let dataSet = Array.isArray(data) ? [...data] : { ...data };
    dataSet[column] = value;
    setData(dataSet);
  } catch (err) {}
}
export function setValueFieldArray(data, setData, value, column, index) {
  try {
    let dataSet = [...data];
    dataSet[index][column] = value;
    setData(dataSet);
  } catch (err) {}
}
export function ModelStateMessage(result) {
  try {
    if (
      result[1].modelState !== null &&
      result[1].modelState !== undefined &&
      result[1].modelState !== ""
    ) {
      let modelState = result[1].modelState;
      if (modelState)
        Object.keys(modelState).forEach(function(k) {
          modelState[k].forEach((element) => {
            Swal.fire("Oops?", element, "error");
          });
        });
    } else if (result[0] === 402) {
      Swal.fire("Oops?", "You have to subscribe first", "error");
    } else if (
      result[0] === 401 &&
      result[1].message !== null &&
      result[1].message !== undefined &&
      result[1].message !== ""
    ) {
      Swal.fire("Oops?", result[1].message, "error");
      setTimeout(() => {
        localStorage.clear();
        window.location.href = "/auth/login";
      }, 1000);
    } else if (
      result[1].message !== null &&
      result[1].message !== undefined &&
      result[1].message !== ""
    ) {
      Swal.fire(
        "Oops?",
        typeof result[1].message !== "string"
          ? result[1].message?.value
          : result[1]?.message,
        "error"
      );
    } else if (
      result[1].error_description !== null &&
      result[1].error_description !== undefined &&
      result[1].error_description !== ""
    ) {
      Swal.fire("Oops?", result[1].error_description, "error");
    } else if (typeof result[1] === "string") {
      Swal.fire("Oops?", result[1], "error");
    } else if (result[0] === 400) {
      ModelStateMessage400(result);
    } else {
      Swal.fire("Oops?", "Bad request", "error");
    }
  } catch (err) {}
}

export function ImageCardNumber({ value }) {
  return (
    <img
      src={toAbsoluteUrl(
        creditCardTypeFromNumber(value) === "Visa"
          ? "/media/svg/card-logos/visa.svg"
          : creditCardTypeFromNumber(value) === "MasterCard"
          ? "/media/svg/card-logos/mastercard.svg"
          : creditCardTypeFromNumber(value) === "Amex"
          ? "/media/svg/card-logos/american-express.svg"
          : creditCardTypeFromNumber(value) === "Discover"
          ? "/media/svg/card-logos/discover.png"
          : creditCardTypeFromNumber(value) === "UnionPay"
          ? "/media/svg/card-logos/union-pay.png"
          : creditCardTypeFromNumber(value) === "JCB"
          ? "/media/svg/card-logos/jcb.png"
          : creditCardTypeFromNumber(value) === "Diners"
          ? "/media/svg/card-logos/diners-club.png"
          : creditCardTypeFromNumber(value) === "Union Pay"
          ? "/media/svg/card-logos/unionpay.png"
          : ""
      )}
      alt=""
      className="h-25px"
    />
  );
}
export function getImageSvgByName(value) {
  switch (value) {
    case "Visa":
      return "/media/svg/card-logos/visa.svg";
    case "MasterCard":
      return "/media/svg/card-logos/mastercard.svg";
    case "Amex":
    case "American Express":
      return "/media/svg/card-logos/american-express.svg";
    case "Discover":
      return "/media/svg/card-logos/discover.png";
    case "UnionPay":
    case "Union Pay":
      return "/media/svg/card-logos/union-pay.png";
    case "JCB":
      return "/media/svg/card-logos/jcb.png";
    case "Diners":
      return "/media/svg/card-logos/diners-club.png";
    case "Diners Club":
      return "/media/svg/card-logos/diners-club.png";
    default:
      return "";
  }
}
export function ImageCardNumberByName({ value }) {
  return (
    <img
      src={toAbsoluteUrl(
        value === "Visa"
          ? "/media/svg/card-logos/visa.svg"
          : value === "MasterCard"
          ? "/media/svg/card-logos/mastercard.svg"
          : value === "Amex" || value === "American Express"
          ? "/media/svg/card-logos/american-express.svg"
          : value === "Discover"
          ? "/media/svg/card-logos/discover.png"
          : value === "UnionPay"
          ? "/media/svg/card-logos/union-pay.png"
          : value === "JCB"
          ? "/media/svg/card-logos/jcb.png"
          : value === "Diners"
          ? "/media/svg/card-logos/diners-club.png"
          : value === "Union Pay"
          ? "/media/svg/card-logos/unionpay.png"
          : ""
      )}
      alt=""
      className="h-25px"
    />
  );
}
export function CheckboxLargeWB({
  field,
  label,
  required,
  text,
  textSmall = "",
  color = "success",
  disabled,
  Size,
  isTms,
  ...props
}) {
  const RequiredHtml = () => {
    return (
      <i
        className="fa fa-star-of-life text-danger icon-l ml-2"
        style={{ fontSize: "8px", position: "absolute" }}
      ></i>
    );
  };
  const inputEl = useRef(null);
  const [colorStat, setColorStat] = useState("success");
  useEffect(() => {
    setColorStat(disabled ? "secondary" : color);
  }, [disabled]);
  return (
    <>
      {label && (
        <label>
          {" "}
          {label} {required && <RequiredHtml />}
        </label>
      )}

      <div className="d-flex align-items-center mr-2">
        <label
          className={`checkbox checkbox-${
            props?.isSmall ? "md" : "lg"
          } checkbox-light-${
            isTms === true ? (disabled ? "secondary" : "info") : colorStat
          } checkbox-single flex-shrink-0 m-0`}
        >
          <input type="checkbox" {...field} {...props} ref={inputEl} />
          <span></span>
        </label>
        <div className="d-flex flex-column flex-grow-1">
          <a
            href={() => false}
            className="text-dark-75 text-hover-primary font-weight-bold font-size-md mb-0 ml-2"
            style={{ fontSize: Size }}
            onClick={() => inputEl.current.click()}
          >
            {text} <small>{textSmall}</small>
          </a>
        </div>
      </div>
    </>
  );
}
export function CheckboxLargeOppotunities({
  field,
  label,
  required,
  text,
  color = "success",
  disabled,
  Size,
  isTms,
  ...props
}) {
  const RequiredHtml = () => {
    return (
      <i
        className="fa fa-star-of-life text-danger icon-l ml-2"
        style={{ fontSize: "8px", position: "absolute" }}
      ></i>
    );
  };
  const inputEl = useRef(null);
  const [colorStat, setColorStat] = useState("success");
  useEffect(() => {
    setColorStat(disabled ? "secondary" : color);
  }, [disabled]);
  return (
    <>
      {label && (
        <label>
          {" "}
          {label} {required && <RequiredHtml />}
        </label>
      )}

      <div className="d-flex align-items-center mr-2">
        <label
          className={`checkbox checkbox-lg checkbox-light-${
            isTms === true ? (disabled ? "secondary" : "info") : colorStat
          } checkbox-single flex-shrink-0 m-0`}
        >
          <input type="checkbox" {...field} {...props} ref={inputEl} />
          <span></span>
        </label>
        <div className="d-flex flex-column flex-grow-1">
          <a
            href={() => false}
            className="text-dark-75 text-hover-primary font-weight-bold font-size-md mb-1"
            style={{ fontSize: Size }}
            onClick={() => inputEl.current.click()}
          >
            {text}
          </a>
        </div>
      </div>
    </>
  );
}
export function CountryStateGlobal({ values, setFieldValue, className = "" }) {
  const [countires, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  useEffect(() => {
    handleGetCoutries();
  }, []);

  const handleGetCoutries = () => {
    apiCall({
      method: "GET",
      link: "/api/Generic/Countries",
    })
      .then((data) => {
        if (data[0] === 200) {
          const countriesList = data[1]?.countries?.map((country) => {
            return { value: country?.name, label: country?.name };
          });
          setCountries(countriesList);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGetStates = (value) => {
    apiCall({
      method: "GET",
      link: `/api/Generic/States?name=${value}`,
    })
      .then((data) => {
        if (data[0] === 200) {
          const stateList = data[1]?.states?.map((state) => {
            return { value: state?.name, label: state?.name };
          });
          setStates(stateList);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (
      values.country !== "" &&
      values.country !== null &&
      values.country !== undefined
    ) {
      handleGetStates(values.country);
    }
  }, [values.country]);

  return (
    <>
      <Row className={className}>
        <Col xl="6" lg="6" md="6">
          <Form.Group controlId="formGroupJob" className="mb-3">
            <Form.Label className="mb-1 font-size-sm">Country</Form.Label>
            <Select
              options={countires}
              classNamePrefix="react-select"
              size="sm"
              name="country"
              value={countires.find((obj) => obj.value === values.country)}
              onChange={(e) => {
                setFieldValue("country", e.value);
                setTimeout(() => {
                  setFieldValue("state", "");
                }, 500);
              }}
            />
          </Form.Group>
        </Col>
        <Col xl="6" lg="6" md="6">
          <Form.Group controlId="formGroupJob" className="mb-3">
            <Form.Label className="mb-1 font-size-sm">State</Form.Label>
            <CreatableSelect
              options={states}
              classNamePrefix="react-select"
              size="sm"
              name="state"
              value={
                !!values.state
                  ? states.find((obj) => obj.value === values.state) || {
                      value: values.state,
                      label: values.state,
                    }
                  : null
              }
              onChange={(e) => {
                setFieldValue("state", e.value);
              }}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}

export function ExportAs(List, Type, Entity) {
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  List.forEach((o) => {
    Object.keys(o).forEach((k) => {
      o[capitalizeFirstLetter(k)] = o[k];
      delete o[k];
    });
  });
  try {
    var dataExportList = JSON.parse(JSON.stringify(List));
    // make dataExportList all the names of the columns in the table capitalized
    dataExportList.forEach((o) => {
      Object.keys(o).forEach((k) => {
        if (containsHTML(o[k])) {
          o[k] = stripHtml(o[k]);
        }
        if (
          moment(
            o[k] !== null ? String(o[k]).slice(0, 10) : "",
            "YYYY-MM-DD",
            true
          ).isValid()
        ) {
          o[k] = new Date(o[k]);
        }
      });
    });
    var date = new Date(Date.now());
    var dateformat =
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "" +
      date.getDate() +
      "" +
      date.getFullYear() +
      "" +
      date.getHours() +
      "" +
      date.getMinutes() +
      "" +
      date.getSeconds();
    if (Type === "excel") {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(dataExportList, {
        cellDates: true,
        dateNF: "mm/dd/yyyy",
      });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, Entity + "_" + dateformat + fileExtension);
    } else if (Type === "csv") {
      const data2 = returncsv(dataExportList);
      const fileType = "text/csv;charset=utf-8";
      const fileExtension = ".csv";
      const data = new Blob([data2], { type: fileType });
      FileSaver.saveAs(data, Entity + "_" + dateformat + fileExtension);
    } else if (Type === "json") {
      const fileType = "application/json";
      const fileExtension = ".json";
      const data = new Blob([JSON.stringify(dataExportList)], {
        type: fileType,
      });
      FileSaver.saveAs(data, Entity + "_" + dateformat + fileExtension);
    }
  } catch (err) {
    console.log(err);
  }
}

export function ExportFormBuilderAs(List, Type, Entity) {
  try {
    var dataExportList = JSON.parse(JSON.stringify(List));
    // make dataExportList all the names of the columns in the table capitalized
    dataExportList.forEach((o) => {
      Object.keys(o).forEach((k) => {
        if (containsHTML(o[k])) {
          o[k] = stripHtml(o[k]);
        }
        if (
          moment(
            o[k] !== null ? String(o[k]).slice(0, 10) : "",
            "YYYY-MM-DD",
            true
          ).isValid()
        ) {
          o[k] = new Date(o[k]);
        }
      });
    });
    var date = new Date(Date.now());
    var dateformat =
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "" +
      date.getDate() +
      "" +
      date.getFullYear() +
      "" +
      date.getHours() +
      "" +
      date.getMinutes() +
      "" +
      date.getSeconds();
    if (Type === "excel") {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(dataExportList, {
        cellDates: true,
        dateNF: "mm/dd/yyyy",
      });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, Entity + "_" + dateformat + fileExtension);
    } else if (Type === "csv") {
      const data2 = returncsv(dataExportList);
      const fileType = "text/csv;charset=utf-8";
      const fileExtension = ".csv";
      const data = new Blob([data2], { type: fileType });
      FileSaver.saveAs(data, Entity + "_" + dateformat + fileExtension);
    } else if (Type === "json") {
      const fileType = "application/json";
      const fileExtension = ".json";
      const data = new Blob([JSON.stringify(dataExportList)], {
        type: fileType,
      });
      FileSaver.saveAs(data, Entity + "_" + dateformat + fileExtension);
    }
  } catch (err) {
    console.log(err);
  }
}
export function ConvertToDateCurrentTimeZone(params) {
  if (params === null || params === "" || params === undefined) {
    return "";
  } else {
    //params = params.replace("GMT", "");

    let newDate = moment(params).format(getFromatdate() + " hh:mm A [GMT]");
    let dateConverted = new Date(newDate).toLocaleString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    return dateConverted;
  }
}
export function ConvertToDateCurrentTimeZoneForNotInput(params) {
  if (params === null || params === "" || params === undefined) {
    return "";
  } else {
    //params = params.replace("GMT", "");

    let newDate = moment(params).format(getFromatdate() + " hh:mm A [GMT]");
    let dateConverted = new Date(newDate).toLocaleString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    return moment(dateConverted).format(`${getFromatdate()}, h:mm:ss A`);
  }
}

export function CheckisRequredApi(
  a,
  cell,
  indexRow,
  errorList,
  setInfoJsonResult
) {
  var isTrue = false;
  try {
    errorList.forEach((x) => {
      var y = (x?.key ?? "").split("[")[1];
      var b = y.split("].");
      if (
        parseInt(b[0]) === indexRow &&
        RemoveSpace(a) === RemoveSpace(b[1]) &&
        !cell
      ) {
        if (!isTrue) isTrue = true;
      }
    });

    /* if (a.includes("*") && !cell) {
      setInfoJsonResult((x) => {
        x.forEach((y) => {
          if (!y[a]) y[a] = "";
        });
        return x;
      });
    } */
    return isTrue || (a.includes("*") && !cell);
  } catch (error) {
    return isTrue;
  }
}

export function RemoveSpace(str) {
  str = str.replace(/\s/g, "");
  str = str.replace(/\*/g, "");
  str = str.toLowerCase();
  return str;
}

export function ModelStateMessage400(result, setListFun) {
  try {
    /* if (!!result[1]) {
      Swal.fire("Oops?", result[1], "error");
      return false;
    } */
    if (!!result[1]?.message) {
      Swal.fire(
        "Oops?",
        typeof result[1].message !== "string"
          ? result[1].message?.value
          : result[1]?.message,
        "error"
      );
      return false;
    }
    if (!result[1]?.errors) result[1] = JSON.parse(result[1]);
    if (!!result[1]?.errors) {
      var p = result[1]?.errors;
      var list = [];
      var list2 = [];
      for (var key in p) {
        if (p.hasOwnProperty(key)) {
          list2.push({ key: key, value: p[key] });

          var a = list.findIndex(
            // eslint-disable-next-line no-loop-func
            (x) => x === `<div class="col-12">${p[key]}</div>`
          );
          if (a === -1) list.push(`<div class="col-12">${p[key]}</div>`);
        }
      }
      if (!!setListFun) setListFun(list2);

      Swal.fire({
        title:
          '<i class="fas fa-exclamation-circle text-danger"></i> <strong class="text-danger h4">Required</strong>',
        icon: "error",
        html: `<div class="row p-0 m-0">${list.join(" ")}</div>`,
        showCloseButton: true,
        confirmButtonText: "OK!",
      });
    } else if (typeof result[1] === "string") {
      Swal.fire("Oops?", result[1], "error");
    } else {
      Swal.fire("Oops?", "Bad_request", "error");
    }
  } catch (err) {
    Swal.fire("Oops?", "Bad_request", "error");
  }
}

function numberFormat(no) {
  var str = no + "";
  var ar = [];
  var i = str.length - 1;
  while (i >= 0) {
    ar.push((str[i - 2] || "") + (str[i - 1] || "") + (str[i] || ""));
    i = i - 3;
  }
  return ar.reverse().join(",");
}
export function NumberFormat(no) {
  if (no === null) {
    no = 0;
  }
  var ar = (+no).toFixed(2).split(".");
  return [numberFormat(ar[0] | 0)].join("");
}

export function PercentCalcule(n1, n2) {
  if (n2 === null || n2 === undefined || n2 === "" || n2 === 0) {
    return 0;
  }

  return (n1 / n2) * 100;
}

export function handleTimeZone(date) {
  var moment = require("moment-timezone");
  const currentUser = localStorage.getItem("user");
  const timeZone = currentUser && JSON.parse(currentUser).timeZoneValue;
  if (timeZone) {
    var tt = moment(date)
      .tz(timeZone)
      .format("YYYY-MM-DD[T]HH:mm:ss");
    return tt;
  }
  return date;
}

export function handleTimeZoneToUTC(date) {
  var moment = require("moment-timezone");
  const currentUser = localStorage.getItem("user");
  const timeZone = currentUser && JSON.parse(currentUser).timeZoneValue;
  const ofsset = currentUser && JSON.parse(currentUser).timeZone;
  if (timeZone) {
    var tt = moment(date)
      .utcOffset(ofsset)
      .format("YYYY-MM-DD[T]HH:mm:ss");
    return tt;
  }
  return date;
}

export function ConvertIntFormat(number) {
  var a = parseFloat(number);
  try {
    if (a < 1000) {
      if (isFloat(a)) {
        a = a.toFixed(2);
      }
      return a;
    }
    return Intl.NumberFormat("en-US", {
      notation: "compact",
    }).format(a);
  } catch (error) {
    return 0;
  }
}

function isFloat(n) {
  return n === +n && n !== (n | 0);
}

export function ConvertToDateCurrentTimeZoneV2(params) {
  if (params === null || params === "" || params === undefined) {
    return "";
  } else {
    const currentUser = localStorage.getItem("user");
    const timeZone = currentUser && JSON.parse(currentUser).timeZone;
    if (timeZone !== null && timeZone !== "" && timeZone !== undefined) {
      var dateConverted = moment(params)
        .add(timeZone, "hours")
        .format("MM/DD/YYYY hh:mm A");
      return dateConverted;
    } else {
      let newDate = moment(params).format("YYYY/MM/DD HH:mm:ss [GMT]");
      let dateConverted = new Date(newDate).toLocaleString("en-US", {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
      return moment(dateConverted).format("MM/DD/YYYY hh:mm A");
    }
  }
}

export function ConvertToTimeZoneSendingPost(params) {
  try {
    if (params === null || params === "" || params === undefined) {
      return "";
    } else {
      // const currentUser = localStorage.getItem("user");
      const timeZone = localStorage.getItem("sendingTimeZone");

      if (timeZone !== null && timeZone !== "" && timeZone !== undefined) {
        var dateConverted = moment(params)
          .subtract(timeZone, "hours")
          .format("YYYY-MM-DD[T]HH:mm");
        return dateConverted;
      } else {
        let newDate = moment(params).format("YYYY/MM/DD HH:mm:ss [GMT]");
        let dateConverted = new Date(newDate).toLocaleString("en-US", {
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
        return moment(dateConverted).format("YYYY-MM-DD[T]HH:mm");
      }
    }
  } catch (err) {
    console.log(
      "🚀 ~ file: globals.js:2057 ~ ConvertToDateCurrentTimeZoneSending ~ err:",
      err
    );
  }
}

export function ConvertToDateGMTV2(params) {
  if (params === null || params === "" || params === undefined) {
    return "";
  } else {
    const currentUser = localStorage.getItem("user");
    const timeZone = currentUser && JSON.parse(currentUser).timeZone;
    if (timeZone !== null && timeZone !== "" && timeZone !== undefined) {
      var dateConverted = moment(params)
        .add(-timeZone, "hours")
        .format("YYYY-MM-DD[T]HH:mm:ss");
      return dateConverted;
    } else {
      let UTCDate = new Date(params).toUTCString();
      let DateFormater = moment.utc(UTCDate).format("YYYY-MM-DD[T]HH:mm:ss");
      return DateFormater;
    }
  }
}
export const numbers = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
];
