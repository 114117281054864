import React from 'react'
import TinySlider from "tiny-slider-react";
import 'tiny-slider/dist/tiny-slider.css';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';

const settings = {
    controls:false,
    lazyload: true,
    nav: false,
    mouseDrag: false,
    autoplay: true,
    autoplayTimeout: 3500,
    autoplayButton:false
};

export function Slide() {
    return (
        <>
            <TinySlider settings={settings}>
                <div className="col-12 text-center">
                    <div className="symbol-list d-flex flex-wrap justify-content-center mb-7">
                        <div className="symbol symbol-50 symbol-lg-100 symbol-circle mr-3">
                            <img
                                src={toAbsoluteUrl("/media/users/150-18.jpg")}
                                alt=""
                                className="h-50 align-self-center"
                            />
                        </div>
                    </div>
                    <h5 className='font-size-h1 mb-5 text-white'>
                        “We saved 9 hrs/week for each salesperson, 70% of deals closed are a result of SalesProphet campaign.“
                    </h5>
                    <div className="flex-grow-1">
                        <a href={() => false} className="text-white font-weight-bold h6">Shawn Elledge</a>
                        <span className="text-muted d-block font-weight-bold">CEO - SLA</span>
                    </div>
                </div>
                <div className="col-12 text-center">
                    <div className="symbol-list d-flex flex-wrap justify-content-center mb-7">
                        <div className="symbol symbol-50 symbol-lg-100 symbol-circle mr-3">
                            <img
                                src={toAbsoluteUrl("/media/users/150-3.jpg")}
                                alt=""
                                className="h-50 align-self-center"
                            />
                        </div>
                    </div>
                    <h5 className='font-size-h1 mb-5 text-white'>
                        “9 meetings set just TODAY with prospective clients. Brands like MGM Resorts…“
                    </h5>
                    <div className="flex-grow-1">
                        <a href={() => false} className="text-white font-weight-bold text-hover-primary h6">Wes Struebing</a>
                        <span className="text-muted d-block font-weight-bold">CEO - Logistics Titans</span>
                    </div>
                </div>
                <div className="col-12 text-center">
                    <div className="symbol-list d-flex flex-wrap justify-content-center mb-7">
                        <div className="symbol symbol-50 symbol-lg-100 symbol-circle mr-3">
                            <img
                                src={toAbsoluteUrl("/media/users/100_7.jpg")}
                                alt=""
                                className="h-50 align-self-center"
                            />
                        </div>
                    </div>
                    <h5 className='font-size-h1 mb-5 text-white'>
                        “SalesProphet Automation has really helped me generate new interest and potential new business opportunities.“
                    </h5>
                    <div className="flex-grow-1">
                        <a href={() => false} className="text-white font-weight-bold text-hover-primary h6">Brad Burrow</a>
                        <span className="text-muted d-block font-weight-bold">CEO - Real Media</span>
                    </div>
                </div>
            </TinySlider>
        </>
    )
}
