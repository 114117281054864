/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import Activationemail from "./Activationemail";
import ResetPassword from "./ResetPassword";
import TermsAndCondition from "./Terms";
import UnsbscribePage from "../../../pages/Unsbscribe/Unsbscribe";

import HistoryPage from "../../../pages/History/History";
import WebFormSlugPage from "../../../pages/WebForm/WebFormSlug";
import WebFormThankYouPage from "../../../pages/WebForm/WebFormThankYouPage";
import RegistrationAppSumo from "./RegistrationAppSumo";

export function AuthPage() {

  return (
    <>
      <Switch>
        <ContentRoute path="/auth/login" component={Login} />
        <ContentRoute
          path="/auth/registration"
          component={Registration}
        />
        <ContentRoute
          path="/auth/AppSumo"
          component={RegistrationAppSumo}
        />
        <ContentRoute path="/ResetPassword" component={ResetPassword} />
        <ContentRoute
          path="/auth/forgot-password"
          component={ForgotPassword}
        />
        <ContentRoute
          path="/activateaccount"
          component={Activationemail}
        />
        <ContentRoute path="/Terms" component={TermsAndCondition} />
        <ContentRoute
          path="/Unsubscribe/Report"
          component={UnsbscribePage}
        />
        <ContentRoute
          path="/History/Report"
          component={HistoryPage}
        />
        <Redirect from="/auth" exact={true} to="/auth/login" />
        <Redirect from="/dashboard" exact={false} to="/auth/login" />
        <Redirect from="/search" exact={false} to="/auth/login" />
        <Redirect from="/Sequences" exact={false} to="/auth/login" />
        <Redirect from="/Newsletters" exact={false} to="/auth/login" />
        <Redirect from="/Forms" exact={false} to="/auth/login" />
        <Redirect from="/Leadlist" exact={false} to="/auth/login" />
        <Redirect from="/Contacts" exact={false} to="/auth/login" />
        <Redirect from="/Companies" exact={false} to="/auth/login" />
        <Redirect from="/Companies-AccountBased" exact={false} to="/auth/login" />
        <Redirect from="/Opportunities" exact={false} to="/auth/login" />
        <Redirect from="/Tasks" exact={false} to="/auth/login" />
        <Redirect from="/settings" exact={false} to="/auth/login" />
        <Redirect from="/user-profile" exact={false} to="/auth/login" />
        <Redirect from="/logout" exact={true} to="/auth/login" />
        <ContentRoute path="/ThankYouPage/:id" component={WebFormThankYouPage} />

        <ContentRoute path="/Form/:slug" component={WebFormSlugPage} />


        <Redirect to="/auth/login" />
      </Switch>

    </>
  );
}
