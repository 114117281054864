import React, { useEffect, useState } from "react";
import { Col, Spinner } from "react-bootstrap";
import { ReactFormGenerator } from "react-form-builder2";
import "react-form-builder2/dist/app.css";
import {
  BASE_URL,
  ModelStateMessage,
  useIsMounted,
} from "../../helpers/globals";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { element } from "prop-types";

var code = document.location.pathname?.replace("/Form/","");
export default function WebFormSlugPage() {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const isMounted = useIsMounted();
  var element1 = document.getElementById("kt_aside");
  if(!!element1) element1.className = "d-none";
  var element2 = document.getElementById("kt_subheader");
  if(!!element2) element2.className = "d-none";  
  var element3 = document.getElementById("kt_footer");
  if(!!element3) element3.className = "d-none";
  var bodyElement = document.querySelector("body");
  if(!!bodyElement) bodyElement.style.backgroundColor = "white";

  useEffect(() => {
    if (isMounted()) {
      getFormInfo(code);
    }
  }, [code]);
  const [FormBuilderStyle, setFormBuilderStyle] = useState({
    slug: "",
    name: "",
    pageColor: "#ffffff",
    formColor: "light",
    textColor: "dark",
    buttonColor: "primary",
    thanksPageTitle: "",
    thanksPageDescription: "",
    link: "",
    isLink: false,
  });
  const [showloadingpost, setshowloadingpost] = useState(false);
  function postAnswer(params) {
    var objPost = {
      id: FormBuilderStyle?.id,
      answers: params.map((a) => {
        return {
          questionId: FormBuilderStyle?.JsonQestion.find(
            (x) => x.name === a.name
          )?.id,
          name: a.name,
          value: String(a.value),
        };
      }),
    };
    setshowloadingpost(true);

    fetch(`${BASE_URL}/api/Forms/Answer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(objPost), // body data type must match "Content-Type" header
    })
      .then(async (response) => {
        const statusCode = await response.status;
        if (statusCode === 200) {
          return Promise.all([statusCode, ""]);
        } else {
          const data = await response.json();
          return Promise.all([statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          if (!FormBuilderStyle.isLink) {
            // history.push(FormBuilderStyle.link)
            window.location.href = FormBuilderStyle.link;
          } else {
            history.push(`/ThankYouPage/${FormBuilderStyle?.id}`);
          }
        } else if (data[0] === 400 && data[1].message) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: data[1].message,
          });
        } else if (data[0] === 400) {
          let tt = JSON.parse(data[1]);
          for (const [key, value] of Object.entries(tt.errors)) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: value,
            });
          }
        } else {
          ModelStateMessage(data);
        }
        setshowloadingpost(false);
      });
  }
  const [JsonQestion, setJsonQestion] = useState([]);
  function getFormInfo() {
    setLoader(true);
    fetch(`${BASE_URL}/api/Forms/slug`, {
      method: "POST",
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        slug: code,
      }),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((data) => {
        try {
          if (!!data && data?.length > 0 && data[0] === 200) {
            setFormBuilderStyle({
              id: data[1].id,
              slug: data[1].slug,
              name: data[1].name,
              pageColor: data[1].pageColor,
              formColor: data[1].formColor,
              textColor: data[1].textColor,
              buttonColor: data[1].buttonColor,
              thanksPageTitle: data[1].thanksPageTitle,
              thanksPageDescription: data[1].thanksPageDescription,
              link: data[1].link,
              isLink: data[1].isLink,
              JsonQestion: data[1].questions,
            });
            setJsonQestion(
              data[1].questions.map((a) => JSON.parse(a.fieldJson))
            );
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "URL Not Found",
            });
          }
          setLoader(false);
        } catch (eee) {
          console.log("eee", eee);
        }
      });
  }
  return (
    <div
      className="error error-6 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
      style={{ background: FormBuilderStyle.pageColor }}
    >
      {/*begin::Content*/}
      <div className="d-flex flex-column flex-row-fluid text-center justify-content-center">
        <Col lg="5" xl="5" md="10" className="mx-auto text-left my-10">
          <div className="card">
            <div className={`${loader ? "bg-secondary" : ""} card-body p-0`}>
              {!loader ? (
                <div
                  className={`CustomColorFormBuilder ${FormBuilderStyle.formColor}`}
                >
                  <div
                    className={`CustomColorTextFormBuilder ${FormBuilderStyle.textColor}`}
                  >
                    <ReactFormGenerator
                      form_action="/path/to/form/submit"
                      form_method="POST"
                      onSubmit={(e) => {
                        console.log("eventSubmit", e);
                        postAnswer(e);
                      }}
                      // read_only={true}
                      task_id={12} // Used to submit a hidden variable with the id to the form from the database.
                      //answer_data={JsonAnswer} // Answer data, only used if loading a pre-existing form with values.
                      authenticity_token={"AUTH_TOKEN"} // If using Rails and need an auth token to submit form.
                      data={JsonQestion} // Question data
                      inputProps={{
                        className: "form-control form-control-sm",
                      }}
                      submitButton={
                        <button
                          type="submit"
                          disabled={showloadingpost}
                          className={`btn btn-${FormBuilderStyle.buttonColor} ml-auto`}
                        >
                          Submit
                          {showloadingpost && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                        </button>
                      }
                      backButton={
                        <a
                          href="/"
                          className="btn btn-default btn-cancel btn-big"
                        >
                          Back
                        </a>
                      }
                    />
                  </div>
                </div>
              ) : (
                <div className="rgt-container-overlay position-relative top-0 bottom-0 mt-5 pt-5 d-flex justify-content-center mb-10">
                  <div className="mr-2 text-primary">Loading...</div>
                  <div className="spinner spinner-primary mr-10"></div>
                </div>
              )}
            </div>
          </div>
        </Col>
      </div>
    </div>
  );
}
