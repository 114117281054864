/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useRef, useEffect, Fragment } from "react";
import { Nav, Tab, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownItemToggler } from "../../../../_partials/dropdowns";
import { apiCall, BASE_URL } from "../../../../../app/helpers/globals";
import Message from "../../../../../app/pages/Notifications/Message";
import { HubConnectionBuilder } from '@microsoft/signalr';
import { Link } from "react-router-dom";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function UserNotificationsDropdown() {
  const [key, setKey] = useState("Alerts");
  const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.notifications.layout") ===
        "offcanvas",
    };
  }, [uiService]);
  const [connection, setConnection] = useState(null);
  const [chat, setChat] = useState([]);
  const [chatFromApi, setChatFromApi] = useState([]);
  const latestChat = useRef(null);

  latestChat.current = chat;

  useEffect(() => {
    getNotificqtions()

    const newConnection = new HubConnectionBuilder()
      .withUrl(`${BASE_URL}/Hubs/chat`)
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
  }, []);
  async function getNotificqtions() {
    await apiCall({
      method: "GET",
      link: `/api/Notifications?isRead=&pageNumber=${1}&pageSize=${5}&search=&sortField=&sortOrder=desc`,
    }).then((dataResult) => {
      if (!!dataResult && dataResult?.length > 0 && dataResult[0] === 200) {
        var array = dataResult[1]?.notifications || [];
        setChatFromApi([...array]);
        setChat([...array]);
      }
    });
  }
  useEffect(() => {
    if (connection) {
      connection.start()
        .then(result => {
          var ConnexionId = connection?.connection?.connectionId;
          if (ConnexionId) {
            apiCall({
              method: "PUT",
              link: `/api/Notifications/SetConnectionId`,
              data: {
                "id": ConnexionId
              },
            });
          }

          connection.on('ReceiveMessage', message => {
            const updatedChat = [...latestChat.current];
            // updatedChat.push(message);

            setChat([message, ...updatedChat]);
          });
        })
        .catch(e => console.log('Connection failed: ', e));
      connection.onreconnected((connectionId) => {
        if (connectionId) {
          apiCall({
            method: "PUT",
            link: `/api/Notifications/SetConnectionId`,
            data: {
              "id": connectionId
            },
          });
        }
      });
    }
  }, [connection]);


  return (
    <>
      <button className="d-none" id="refreshNotificationButton" onClick={() => {
        getNotificqtions();
      }} ></button>
      {!layoutProps.offcanvas && (
        <div className="topbar-item">
          <div
            className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
            id="kt_quick_notifications_toggle"
          >
            <span className="svg-icon svg-icon-xl svg-icon-warning">
              <span className="label label-danger mr-2">5</span>
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")} />
            </span>
            <span className="pulse-ring"></span>
          </div>
        </div>
      )}
      {layoutProps.offcanvas && (
        <Dropdown drop="down" alignRight>
          <Dropdown.Toggle
            as={DropdownItemToggler}
            id="kt_quick_notifications_toggle"
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-notification-tooltip">
                  User Notifications
                </Tooltip>
              }
            >

              <div
                className="btn btn-icon btn-hover-transparent-primary btn-dropdown btn-lg pulse pulse-primary"
                id="kt_quick_notifications_toggle" style={{ width: "50px" }}
              >

                <span className="svg-icon svg-icon-xl" style={{ position: "relative" }}>
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/Notifications1.svg")}
                  />

                </span>

                {chat.filter(a => a.isRead !== true).length > 0 ? (<span className="label label-danger mb-5 mr-2" style={{ height: "auto", position: "absolute", right: "-5px", top: "2px", width: "auto", paddingLeft: "5px", paddingRight: "5px" }}>{chat.filter(a => a.isRead !== true)?.length}</span>) : ""}
                {chat.filter(a => a.isRead !== true).length > 0 ? (<span className="pulse-ring" />) : ""}

              </div>
            </OverlayTrigger>

          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
            <form>
              {/** Head */}
              <div
                className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
                style={{ backgroundImage: `url(${bgImage})` }}
              >
                <h4 className="d-flex flex-center rounded-top">
                  <span className="text-white">User Notifications</span>
                  <span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">
                    {chat.filter(a => a.isRead !== true).length || 0} new
                  </span>
                </h4>

                <Tab.Container defaultActiveKey={key}>
                  <Nav
                    as="ul"
                    className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
                    onSelect={(_key) => setKey(_key)}
                  >
                    <Nav.Item className="nav-item" as="li">
                      <Nav.Link
                        eventKey="Alerts"
                        className={`nav-link show ${key === "Alerts" ? "active" : ""
                          }`}
                      >
                        Events
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content className="tab-content">
                    <Tab.Pane eventKey="Alerts" className="p-8">
                      <PerfectScrollbar
                        options={perfectScrollbarOptions}
                        className="scroll pr-7 mr-n7"
                        style={{ maxHeight: chat.length === 1 ? "100px" : "300px", position: "relative" }}
                      >
                        {chat.length > 0 ? (
                          <Fragment>
                            {chat
                              .map((m, i) => (
                                <Message
                                  key={Date.now() * Math.random()}
                                  id={m.id}
                                  index={i}
                                  title={m.title}
                                  date={m.date}
                                  icon={m.icon}
                                  isRead={m.isRead}
                                  link={m.link}
                                  getNotificqtions={getNotificqtions}
                                  message={m.text} />

                              ))}
                          </Fragment>
                        ) : <h4>No results found.</h4>}
                      </PerfectScrollbar>
                      <div className="d-flex flex-center pt-7">
                        <Link to="/global-Notifications" className="btn btn-light-primary font-weight-bold text-center">See All</Link>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}
