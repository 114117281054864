import React from "react";
import { Button } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../../../_metronic/_partials/controls";
import preval from "preval.macro";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Slide } from "../../modules/Slide/Slide";
export default function HistoryPage() {
  const today = new Date().getFullYear();
  const [t, i18n] = useTranslation();
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Content*/}
          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
        
            {/*begin::Content header*/}
            {/* <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
              <span className="font-weight-bold text-dark-50">
                Don't have an account yet?
              </span>
              <Link
                to="/auth/registration"
                className="font-weight-bold ml-2"
                id="kt_login_signup"
              >
                Sign Up!
              </Link>
            </div> */}
            {/*end::Content header*/}

            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <div className="container">
                <div className="col-12">
                  <div className="d-flex justify-content-between align-items-center mb-10">
                    <div className="">
                      <img
                        src={toAbsoluteUrl("/media/logos/SalesProphet.png")}
                        alt=""
                        className="h-100px"
                      />
                    </div>
                    <div className="">
                      <Button variant="primary" >
                        Subscribe to Update
                      </Button>
                    </div>
                  </div>
                  <Card className="border-primary bg-primary border rounded-0 mb-10">
                    <CardHeader>
                      <div className="card-title">
                        <h3 className="card-label text-white">SalesProphet Scheduler booking e-mails aren't being sent</h3>
                      </div>
                      <CardHeaderToolbar>
                        <Button variant="primary" >
                          Subscribe to Update
                        </Button>
                      </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody className="bg-white">
                      <p>
                        Identified - We have received reports of booking e-mails not being sent for appointments booked through the SalesProphet Scheduler. We have correlated this issue with a problem with the 3rd-party provider we are using for this i.e. Mailgun. You can subscribe to updates on their status page: URL. Thank you for your patience.
                        Feb 22, 2023 - 11:39 PST
                      </p>
                    </CardBody>
                  </Card>

                  <div className="d-flex flex-column flex-grow-1 font-weight-bold mb-10">
                    <span className="text-dark mb-1 h1">
                      About This Site
                    </span>
                    <span className="text-muted">
                      Seeing issues that aren't reflected on this page? Contact us at <a href="mailto:support@slaesprophet.com">
                        support@slaesprophet.com
                      </a>
                    </span>
                  </div>
                  <div className="mb-10">
                    <div className="d-flex justify-content-between align-items-center border p-4">
                      <div className="">
                        API
                      </div>
                      <div className="">
                        <p className="text-success mb-0">Operational
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center border p-4">
                      <div className="">
                        Dashboard
                      </div>
                      <div className="">
                        <p className="text-success mb-0">Operational
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center border p-4">
                      <div className="">
                        Developer Console
                      </div>
                      <div className="">
                        <p className="text-success mb-0">Operational
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center border p-4">
                      <div className="">
                        Sync Status
                      </div>
                      <div className="">
                        <p className="text-success mb-0">Operational
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center border p-4">
                      <div className="">
                        Webhooks
                      </div>
                      <div className="">
                        <p className="text-success mb-0">Operational
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center border p-4">
                      <div className="">
                        AWS route53
                      </div>
                      <div className="">
                        <p className="text-success mb-0">Operational
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center border p-4">
                      <div className="">
                        Scheduler
                      </div>
                      <div className="">
                        <p className="text-success mb-0">Operational
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center border p-4">
                      <div className="">
                        Streams
                      </div>
                      <div className="">
                        <p className="text-success mb-0">Operational
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column flex-grow-1 font-weight-bold mb-7">
                    <span className="text-dark mb-1 h1">
                      Past Incidents
                    </span>
                  </div>
                  <div className="d-flex flex-column flex-grow-1 font-weight-bold mb-7">
                    <span className="text-dark mb-1 h2 border-bottom">
                      Feb 24, 2023
                    </span>
                    <span className="text-muted">
                      No incidents reported today.
                    </span>
                  </div>
                  <div className="d-flex flex-column flex-grow-1 font-weight-bold mb-7">
                    <span className="text-dark mb-1 h2 border-bottom">
                      Feb 23, 2023
                    </span>
                    <span className="text-muted">
                      No incidents reported.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy; {today} SalesProphet
                <span className="text-muted ml-3">
                  {t("Build Version")}{" "}
                  <a
                    href={() => false}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-weight-bolder text-muted"
                  >
                    {process.env.REACT_APP_VERSION}
                  </a>{" "}
                  / {t("Build Date:")}{" "}
                  <strong>{preval`module.exports = new Date().toLocaleDateString("en-US");`}</strong>
                </span>
              </div>
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
          {/*begin::Aside*/}
          <div
            className={`login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10 ${String(window.location.href).includes("Unsubscribe") || String(window.location.href).includes("History") ? "d-none" : ""}`}
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`,

            }}
          >
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-between">
              {/* start:: Aside header */}
              <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                <img
                  alt="Logo"
                  className="max-h-150px"
                  src={toAbsoluteUrl("/media/logos/logo-letter-1.png")}
                />
              </Link>
              {/* end:: Aside header */}

              {/* start:: Aside content */}
              <div className="flex-column-fluid d-flex flex-column justify-content-center">
                <Slide />
              </div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 font-weight-bold	text-white">
                  &copy; {today} SalesProphet
                  <span className="text-muted ml-3">
                    {t("Build Version")}{" "}
                    <a
                      href={() => false}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-weight-bolder text-muted"
                    >
                      {process.env.REACT_APP_VERSION}
                    </a>{" "}
                    / {t("Build Date:")}{" "}
                    <strong>{preval`module.exports = new Date().toLocaleDateString("en-US");`}</strong>
                  </span>
                </div>
              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
