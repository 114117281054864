import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import preval from "preval.macro";
import { LanguageSelectorDropdown } from "../../../../_metronic/layout/components/extras/dropdowns/LanguageSelectorDropdown";
import { Slide } from "../../Slide/Slide";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialValues = {
  email: "",
};

function ForgotPassword(props) {
  const today = new Date().getFullYear();
  const [t, i18n] = useTranslation();
  const [isRequested, setIsRequested] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),
  });

  const handleForgotPassword = (values) => {
    setIsLoader(true);
    //setLoading(true);
    fetch(`${BASE_URL}/api/Account/ForgetPassword`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      Allow: "POST",
      body: JSON.stringify(values),
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          Swal.fire({
            icon: "success",
            title: "success",
            text:
              "We have sent you an email with a link to reset your password",
          });
          // setLoading(false);
          setIsLoader(true);
          setIsRequested(true);
        } else if (data[0] === 400 && data[1]?.message) {
          // setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: data[1]?.message,
          });
        } else if (data[0] === 500) {
          // setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!. Please try again later",
          });
        }
      })
      .catch((error) => {
        //setLoading(false);
        setIsLoader(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error,
        });
      });
    setIsLoader(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values) => {
      handleForgotPassword(values);
    },
  });

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="d-flex flex-column flex-root">
          {/*begin::Login*/}
          <div
            className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
            id="kt_login"
          >
            {/*begin::Content*/}
            <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
              <div className="col-2">
                <LanguageSelectorDropdown />
              </div>
              {/*begin::Content header*/}
              {/* <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
              <span className="font-weight-bold text-dark-50">
                Don't have an account yet?
              </span>
              <Link
                to="/auth/registration"
                className="font-weight-bold ml-2"
                id="kt_login_signup"
              >
                Sign Up!
              </Link>
            </div> */}
              {/*end::Content header*/}

              {/* begin::Content body */}
              <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
                <div className="login-form login-forgot" style={{ display: "block" }}>
                  <div className="text-center mb-10 mb-lg-10">
                    <h3 className="font-size-h1">{t("Forgotten Password ?")}</h3>
                    <div className="text-muted font-weight-bold">
                      {t("Enter your email to reset your password")}
                    </div>
                  </div>
                  <form
                    onSubmit={formik.handleSubmit}
                    className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                  >
                    <div className="form-group fv-plugins-icon-container">
                      <input
                        type="email"
                        className={`form-control h-auto py-3 px-6`}
                        name="email"
                        placeholder="Email"
                        {...formik.getFieldProps("email")}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className="fv-plugins-message-container">
                          <div className="text-danger">{formik.errors.email}</div>
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group d-flex flex-wrap flex-center">
                      <button
                        id="kt_login_forgot_submit"
                        type="submit"
                        className="btn btn-primary font-weight-bold px-9 py-3 my-3 mx-4"
                        disabled={isLoader}
                      >
                        {t("Submit")}
                      </button>
                      {props?.isFunction ? (
                        <button
                          type="button"
                          id="kt_login_forgot_cancel"
                          onClick={props.onHide}
                          className="btn btn-light-primary font-weight-bold px-9 py-3 my-3 mx-4"
                        >
                          {t("Cancel")}
                        </button>
                      ) : (
                        <Link to="/auth">
                          <button
                            type="button"
                            id="kt_login_forgot_cancel"
                            className="btn btn-light-primary font-weight-bold px-9 py-3 my-3 mx-4"
                          >
                            {t("Cancel")}
                          </button>
                        </Link>
                      )}
                    </div>
                  </form>
                </div>
              </div>
              {/*end::Content body*/}

              {/* begin::Mobile footer */}
              <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
                <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                  &copy; {today} SalesProphet
                  <span className="text-muted ml-3">
                    {t("Build Version")}{" "}
                    <a
                      href={() => false}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-weight-bolder text-muted"
                    >
                      {process.env.REACT_APP_VERSION}
                    </a>{" "}
                    / {t("Build Date:")}{" "}
                    <strong>{preval`module.exports = new Date().toLocaleDateString("en-US");`}</strong>
                  </span>
                </div>
              </div>
              {/* end::Mobile footer */}
            </div>
            {/*end::Content*/}
            {/*begin::Aside*/}
            <div
              className={`login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10 ${String(window.location.href).includes("Unsubscribe") || String(window.location.href).includes("History") ? "d-none" : ""}`}
              style={{
                backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`,

              }}
            >
              {/*begin: Aside Container*/}
              <div className="d-flex flex-row-fluid flex-column justify-content-between">
                {/* start:: Aside header */}
                <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                  <img
                    alt="Logo"
                    className="max-h-150px"
                    src={toAbsoluteUrl("/media/logos/logo-letter-1.png")}
                  />
                </Link>
                {/* end:: Aside header */}

                {/* start:: Aside content */}
                <div className="flex-column-fluid d-flex flex-column justify-content-center">
                  <Slide />
                </div>
                {/* end:: Aside content */}

                {/* start:: Aside footer for desktop */}
                <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                  <div className="opacity-70 font-weight-bold	text-white">
                    &copy; {today} SalesProphet
                    <span className="text-muted ml-3">
                      {t("Build Version")}{" "}
                      <a
                        href={() => false}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="font-weight-bolder text-muted"
                      >
                        {process.env.REACT_APP_VERSION}
                      </a>{" "}
                      / {t("Build Date:")}{" "}
                      <strong>{preval`module.exports = new Date().toLocaleDateString("en-US");`}</strong>
                    </span>
                  </div>
                </div>
                {/* end:: Aside footer for desktop */}
              </div>
              {/*end: Aside Container*/}
            </div>
            {/*begin::Aside*/}
          </div>
          {/*end::Login*/}
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
