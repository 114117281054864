import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import 'react-form-builder2/dist/app.css';
import { useParams } from "react-router-dom";
import { apiCall } from "../../helpers/globals";

export default function WebFormThankYouPage() {
    const params = useParams();
    const { id } = params;
    var element1 = document.getElementById("kt_aside");
    if(!!element1) element1.className = "d-none";
    var element2 = document.getElementById("kt_subheader");
    if(!!element2) element2.className = "d-none";  
    var element3 = document.getElementById("kt_footer");
    if(!!element3) element3.className = "d-none";
    var bodyElement = document.querySelector("body");
    if(!!bodyElement) bodyElement.style.backgroundColor = "white";
    const [ThankyoupageData, setThankyoupageData] = useState(null);
    useEffect(() => {
        getThankyoupageData(id);
    }, [id]);
    function getThankyoupageData(idForm) {
        apiCall({
            method: "GET",
            link: `/api/Forms/ThanksPage/${idForm}`,
        }).then((data) => {
            if (data[0] === 200) {
                setThankyoupageData(data[1])
            }
        });
    }
    return (

        <div className="error error-6 d-flex flex-row-fluid bgi-size-cover bgi-position-center">
            {/*begin::Content*/}
            <div className="d-flex flex-column flex-row-fluid text-center justify-content-center">
                <Col lg="10" className="mx-auto text-center">
                    <div class="page-wrap">
                        <div class="main">
                            <h1>{ThankyoupageData?.pageTitle}</h1>
                            <h2  dangerouslySetInnerHTML={{
                      __html:( ThankyoupageData?.pageDescription),
                    }}></h2>
                        </div>
                    </div>
                </Col>
            </div>
        </div>
    );
  
}
