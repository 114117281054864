
import moment from 'moment';
import React from 'react';
import SVG from "react-inlinesvg";
import { useHistory } from 'react-router-dom';
import ReactTimeAgo from 'react-time-ago';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import { apiCall, ConvertToDateCurrentTimeZoneV2, getFromatdate } from '../../helpers/globals';

const Message = (props) => {
    const history = useHistory();

    return (
        <>
            <div className={`d-flex align-items-top mb-6 ${props.index === 0 ? "pt-1" : ""}`} onClick={() => {
                apiCall({
                    method: "PUT",
                    link: `/api/Notifications/MarkAsRead?id=${props.id}`,
                }).then((dataResult) => {
                    props.getNotificqtions()
                });
                if (props.link !== null && props.link !== "" && props.link !== undefined) {
                    history.push(props.link);
                }
            }}>
                <div className="symbol symbol-30 symbol-light-primary mr-5">
                    <span className="symbol-label">
                        <SVG
                            src={toAbsoluteUrl(props.icon)}
                            className="svg-icon-md svg-icon-primary"
                        ></SVG>
                    </span>
                    {props.isRead !== true ? <i className="symbol-badge bg-danger"></i> : ""}

                </div>
                <div className="d-flex flex-column font-weight-bold" style={{ width: "100%" }}>
                    <a
                        href={() => false}
                        className="text-dark text-hover-primary mb-1 font-size-lg"
                    >
                        {props.title}
                    </a>
                    <span className="text-muted" dangerouslySetInnerHTML={{
                        __html:
                            props.message
                        ,
                    }} >
                        {/* {props.message} */}
                    </span>
                    <span className={`${props.isRead !== true ? "text-success" : "text-muted"}`}>
                        {moment(ConvertToDateCurrentTimeZoneV2(props.date)).format(getFromatdate() + " hh:mm A")}
                    </span>

                </div>
            </div>
            <div className="separator separator-solid mb-6" />
        </>
    )
};

export default Message;