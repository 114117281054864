import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { useSubheader } from "../../../_metronic/layout";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import {
  DropdownCustomToggler,
  DropdownFilter,
} from "../../../_metronic/_partials/dropdowns";
import Select from "react-select";
import {
  ConvertToDateCurrentTimeZoneV2,
  DynamicModal,
  ModelStateMessage,
  apiCall,
  getFromatdate,
} from "../../helpers/globals";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import Swal from "sweetalert2";
import ReplyModal from "./ReplyModal";
import InboxDetails from "./InboxDetails";
import InputSpinner from "react-bootstrap-input-spinner";
export default function Unibox() {
  const suhbeader = useSubheader();
  useEffect(() => {
    suhbeader.setTitle("Unibox");
  }, [suhbeader]);

  const options = [
    { value: 1, label: "Sequences" },
    // { value: 2, label: "Newsletters" },
    { value: 3, label: "ABM" },
    { value: 4, label: "Manual Replies" },
  ];
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [unibox, setUniBox] = useState([]);
  const [toggleModal, setToggleModal] = useState(false);
  const [Removing, setIsRemoving] = useState(false);
  const [paginationData, setPaginationData] = useState([]);
  const [ids, setIds] = useState([]);
  const [providers, setProviders] = useState([]);
  const [modalType, setModalType] = useState(false);
  const [loaderBtn, setLoaderBtn] = useState(false);
  const [modalReply, setModalReply] = useState(false);
  const [itemId, setItemId] = useState("");
  const [compainId, setCompainId] = useState("");
  const [contacts, setContacts] = useState([]);
  const [classifyType, setClassifyType] = useState("");
  const [providerId, setProviderId] = useState("");
  const [providerId2, setProviderId2] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [SpinnerValue, setSpinnerValue] = useState(7);
  const [typeCampaign, setTypeCampaign] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [typeReply, setTypeReply] = useState("");
  const [search, setSearch] = useState("");
  const [viewModelCompanies, setViewModelCompanies] = useState([]);
  const [TemplateViewModel, setTemplateViewModel] = useState(null);
  const [item, setItem] = useState(null);
  const [loaderData, setLoaderData] = useState(false);
  const handleGetUbniBox = async () => {
    setIsLoading(true);
    apiCall({
      method: "GET",
      link: `/api/Unibox/GlobalReply?typeCampaign=${typeCampaign}&typeReply=${typeReply}&pageNumber=${currentPage}&pageSize=10&search=${search}&endDate=${endDate}&startDate=${startDate}&providerId=${providerId}`,
    }).then((data) => {
      if (data[0] === 200) {
        setIsLoading(false);
        console.log("unitBox", data[1]);
        setUniBox(data[1]);
        setPaginationData(data[1]?.pagination);
      } else {
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    if (!!item?.campaignId) {
      apiCall({
        method: "GET",
        link: `/api/Campaigns/GetContactIdsByCampain?campaignId=${item?.campaignId}`,
      }).then((dataResult) => {
        try {
          if (dataResult[0] === 200) {
            setContacts(dataResult[1] || []);
          }
        } catch (err) {}
      });
    }
  }, [item]);

  function MarkAsIterested() {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to mark this email as interested`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoaderData(true);
        apiCall({
          method: "POST",
          link: `/api/Campaigns/MarkInterested`,
          data: {
            ids: ids,
            mark: true,
          },
        }).then((data) => {
          if (data[0] === 200) {
            Swal.fire({
              title: "Success",
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
            setIds([]);
            handleGetUbniBox();
            setLoaderData(false);
          } else {
            ModelStateMessage(data);
            setLoaderData(false);
          }
        });
      }
    });
  }

  const handleResendStep = () => {
    setIsRemoving(true);
    apiCall({
      method: "PUT",
      link: `/api/Unibox/BulkRemoveReply`,
      data: {
        reportId: ids,
        continued: true,
        numberOfDays: SpinnerValue,
      },
    }).then((data) => {
      if (data[0] === 200) {
        setIsRemoving(false);
        handleClose();
        setIds([]);
        handleGetUbniBox();
        setSpinnerValue(7);
        Swal.fire({
          title: "Success",
          text: "Step Resended Successfully",
          icon: "success",
          confirmButtonText: "Ok",
        });
      } else if (data[0] === 400 && data[1].message) {
        setIsRemoving(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: data[1].message,
        });
      } else if (data[0] === 402) {
        setIsRemoving(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Payment Required ",
        });
      }
    });
  };

  function getViewModelFunction() {
    apiCall({
      method: "GET",
      link: `/api/Templates/GetViewModel`,
    }).then((dataResult) => {
      try {
        if (dataResult[0] === 200) {
          console.log("setTemplateViewModel", dataResult[1]);

          setTemplateViewModel(dataResult[1]);
        }
      } catch (err) {}
    });
    apiCall({
      method: "GET",
      link: `/api/Companies/GetViewModel`,
    }).then((data) => {
      if (data?.length > 0 && data[0] === 200) {
        console.log("setViewModelCompanies", data[1]);
        setViewModelCompanies(data[1]);
      }
    });
  }
  const handleGetViewModel = async () => {
    setIsLoading(true);
    apiCall({
      method: "GET",
      link: `/api/Unibox/GetViewModel`,
    }).then((data) => {
      if (data[0] === 200) {
        console.log("ViewModel", data[1]);
        const options = data[1]?.providers?.map((item, index) => {
          return { value: item?.id, label: item?.name };
        });
        setProviders(options);
      }
    });
  };
  useEffect(() => {
    handleGetUbniBox();
  }, [currentPage, typeCampaign, typeReply, startDate, endDate, providerId]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      handleGetUbniBox();
    }, 500);
    return () => clearTimeout(timeout);
  }, [search]);
  useEffect(() => {
    handleGetViewModel();
    getViewModelFunction();
  }, []);

  const handlePreviousPage = () => {
    if (paginationData.hasPreviousPage) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (paginationData.hasNextPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  function removeNewlinesAndCarriageReturns(text) {
    const withoutNewlinesAndCarriageReturns = text.replace(/\\[nr]/g, "");
    return withoutNewlinesAndCarriageReturns;
  }

  const handleIcon = (type) => {
    if (type === "Sequences") {
      return <SVG src={toAbsoluteUrl("/media/svg/icons/Map/Direction2.svg")} />;
    } else if (type === "ABM") {
      return <SVG src={toAbsoluteUrl("/media/svg/icons/General/Star.svg")} />;
    } else if (type === "Manual Replies") {
      return (
        <span className={`svg-icon svg-icon-primary ml-0`}>
          <i className="fa fa-reply text-primary icon-md"></i>
        </span>
      );
    } else if (type === "Newsletter") {
      return (
        <SVG
          src={toAbsoluteUrl("/media/svg/icons/Communication/Sending mail.svg")}
        />
      );
    }
  };
  const handleIconColor = (type) => {
    if (type === "Sequences") {
      return "info";
    } else if (type === "ABM") {
      return "warning";
    } else if (type === "Manual Replies") {
      return "primary";
    } else if (type === "Newsletter") {
      return "success";
    }
  };

  const handleLink = (type, campaignId) => {
    if (type === "Sequences") {
      return `/Sequences/edit/${campaignId}/basics`;
    } else if (type === "ABM") {
      return "";
    } else if (type === "Manual Replies") {
      return "";
    } else if (type === "Newsletter") {
      return `/Newsletters/edit/${campaignId}/basics`;
    }
  };

  return (
    <>
      <Container fluid="true">
        <div className="d-flex flex-row">
          {/*begin::List*/}
          <div className="flex-row-fluid  d-block" id="kt_inbox_list">
            {/*begin::Card*/}
            <div className="card card-custom card-stretch">
              {/*begin::Header*/}
              <div className="card-header row row-marginless align-items-center flex-wrap py-5 h-auto">
                {/*begin::Toolbar*/}

                <div className="col-12">
                  <div className="d-flex justify-content-between">
                    <div className="flex-grow-1">
                      <input
                        name="my-search"
                        type="text"
                        id="searchIconInput"
                        placeholder="Search for..."
                        className="form-control form-control mr-2 icon-input"
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                      />
                    </div>

                    <div className="d-flex flex-nowrap ml-2">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top" className="mb-3">
                            <span className="font-weight-bold">
                              Refresh data
                            </span>
                          </Tooltip>
                        }
                      >
                        <button
                          onClick={() => {
                            handleGetUbniBox();
                          }}
                          className="btn btn-sm btn-outline-primary mr-2 px-2"
                        >
                          <i className="fas fa-sync-alt px-1"></i>
                        </button>
                      </OverlayTrigger>
                      <div className="w-200px mr-2">
                        <Select
                          placeholder="Filter by type"
                          classNamePrefix="react-select "
                          size="xl"
                          options={options}
                          value={options.find(
                            (obj) => obj.value === typeCampaign
                          )}
                          onChange={(e) => {
                            if (e) {
                              setTypeCampaign(e.value);
                            } else {
                              setTypeCampaign("");
                            }
                          }}
                          isClearable={true}
                        />
                      </div>
                      <div className="w-300px mr-2">
                        <Select
                          placeholder="Filter by provider"
                          classNamePrefix="react-select "
                          size="xl"
                          isClearable={true}
                          options={providers}
                          value={providers.find(
                            (obj) => obj.value === providerId
                          )}
                          onChange={(e) => {
                            if (e) {
                              setProviderId(e.value);
                            } else {
                              setProviderId("");
                            }
                          }}
                        />
                      </div>
                      <Dropdown className="dropdown-inline" alignRight>
                        <Dropdown.Toggle
                          id="dropdown-toggle-top"
                          as={DropdownFilter}
                        >
                          <span className="btn btn-light font-weight-bold">
                            <span>
                              {" "}
                              <i className="fas fa-sort-amount-down-alt mr-2" />
                            </span>

                            <span
                              className="text-primary font-weight-bold"
                              id="kt_dashboard_daterangepicker_date"
                            >
                              Custom Date
                            </span>
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                          <div className="row w-100 mt-2 mb-2 pb-5 ml-0 border-bottom">
                            <div className="col-12 mb-2">
                              From
                              <input
                                type={`Date`}
                                className="form-control form-control-sm mr-2 w-100"
                                name="From"
                                value={startDate}
                                onChange={(e) => {
                                  setStartDate(e.target.value);
                                }}
                              />
                            </div>
                            <div className="col-12">
                              To
                              <input
                                type={`Date`}
                                className="form-control form-control-sm mr-2 w-100"
                                name="To"
                                value={endDate}
                                onChange={(e) => {
                                  setEndDate(e.target.value);
                                }}
                              />
                            </div>
                          </div>

                          {/* <b>Or</b> */}
                          {/* 
                          <Dropdown.Item>This Week</Dropdown.Item>
                          <Dropdown.Item>This Month</Dropdown.Item>
                          <Dropdown.Item>This Year</Dropdown.Item> */}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                {/*end::Search*/}

                <div className="col-12 order-4">
                  <div className="navi navi-hover navi-active navi-link-rounded navi-bold navi-icon-center navi-light-icon d-flex flex-wrap">
                    {/*begin::Item*/}
                    <div
                      className="navi-item my-2"
                      onClick={() => {
                        setTypeReply("");
                        setCurrentPage(1);
                      }}
                    >
                      <span
                        className={`navi-link ${
                          typeReply === "" ? "active" : ""
                        }`}
                      >
                        <span className="navi-text font-weight-bolder font-size-lg cursor-pointer">
                          All
                        </span>
                        <span className="navi-label">
                          <span className="label label-rounded label-light-primary font-weight-bolder px-5 cursor-pointer">
                            {unibox?.all}
                          </span>
                        </span>
                      </span>
                    </div>

                    {unibox?.replyTypes?.map((item, index) => {
                      return (
                        <div
                          className="navi-item my-2"
                          onClick={() => {
                            setTypeReply(item.name);
                            setCurrentPage(1);
                          }}
                          key={index}
                        >
                          <span
                            className={`navi-link ${
                              typeReply === item?.name ? "active" : ""
                            }`}
                          >
                            <span className="navi-text font-weight-bolder font-size-lg cursor-pointer">
                              {item.name}
                            </span>
                            <span className="navi-label">
                              <span className="label label-rounded px-5 label-light-primary font-weight-bolder cursor-pointer">
                                {item.count}
                              </span>
                            </span>
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              {!isLoading ? (
                <div className="card-body table-responsive px-0">
                  {/*begin::Items*/}
                  <div
                    className="list list-hover min-w-500px"
                    data-inbox="list"
                  >
                    {/*begin::Item*/}
                    <div className="d-flex justify-content-between align-items-center checkbox-primary mx-4 px-4">
                      {unibox?.inbox?.length > 0 && (
                        <div>
                          <input
                            className="mr-3"
                            type="checkbox"
                            id="checkbox"
                            name="checkbox"
                            onChange={(e) => {
                              if (e.target.checked) {
                                setIds(unibox?.inbox?.map((x) => x?.id));
                              } else {
                                setIds([]);
                              }
                            }}
                            checked={
                              unibox?.inbox?.length > 0 &&
                              ids.length === unibox?.inbox?.length
                            }
                          />
                          <label htmlFor="checkbox pt-1">All</label>
                        </div>
                      )}
                      {ids.length > 0 && (
                        <div>
                          <button
                            className="btn btn-sm btn-primary mr-2 px-2"
                            onClick={() => {
                              setModalType(true);
                            }}
                          >
                            Classify type
                          </button>
                          <button
                            className="btn btn-sm btn-primary mr-2 px-2"
                            onClick={() => {
                              handleShow();
                            }}
                          >
                            Resend Step
                          </button>
                          <button
                            disabled={loaderData}
                            className="btn btn-sm btn-primary mr-2 px-2"
                            onClick={() => {
                              MarkAsIterested();
                            }}
                          >
                            Mark as Interested
                          </button>
                        </div>
                      )}
                    </div>
                    {unibox?.inbox?.map((item, index) => {
                      return (
                        <div
                          className="d-flex align-items-start list-item card-spacer-x py-3"
                          data-inbox="message"
                          key={index}
                        >
                          <div className="d-flex align-items-center">
                            <div className="checkbox-inline checkbox-primary mr-3">
                              <input
                                type="checkbox"
                                id="checkbox"
                                name="checkbox"
                                value={item?.id}
                                checked={ids.includes(item?.id)}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setIds([...ids, item?.id]);
                                  } else {
                                    setIds(ids.filter((x) => x !== item?.id));
                                  }
                                }}
                              />
                              <label htmlFor="checkbox"></label>
                            </div>
                            {/*begin::Actions*/}
                            <div
                              className="d-flex align-items-center mr-3"
                              data-inbox="actions"
                            >
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="tooltip-top" className="mb-3">
                                    <span className="font-weight-bold">
                                      {item?.type}
                                    </span>
                                  </Tooltip>
                                }
                              >
                                <span
                                  className={`svg-icon svg-icon-${handleIconColor(
                                    item?.type
                                  )} ml-0`}
                                >
                                  {handleIcon(item?.type)}
                                </span>
                              </OverlayTrigger>
                            </div>
                            <div
                              className="d-flex align-items-center flex-wrap w-xxl-225px mr-3"
                              data-toggle="view"
                            >
                              <div className="symbol symbol-light-primary symbol-35 mr-3">
                                <span className="symbol-label font-weight-bolder">
                                  {item?.firstName
                                    ?.substring(0, 1)
                                    ?.toUpperCase()}
                                  {item?.lastName
                                    ?.substring(0, 1)
                                    ?.toUpperCase()}
                                </span>
                              </div>
                              <Link
                                to={`/contacts/${item?.contactId}/Details`}
                                className="font-weight-bold text-dark-75 text-hover-primary cursor-pointer"
                              >
                                {item?.firstName} {item?.lastName}
                              </Link>
                              {item?.isInterested && (
                                <div
                                  className="d-flex align-items-center ml-3"
                                  data-inbox="actions"
                                >
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip
                                        id="tooltip-top"
                                        className="mb-3"
                                      >
                                        <span className="font-weight-bold">
                                          Interested
                                        </span>
                                      </Tooltip>
                                    }
                                  >
                                    <span
                                      className={`svg-icon svg-icon-success ml-0`}
                                    >
                                      <SVG
                                        src={toAbsoluteUrl(
                                          "/media/svg/icons/Shopping/Dollar.svg"
                                        )}
                                      />
                                    </span>
                                  </OverlayTrigger>
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            className="flex-grow-1 mt-2 mr-2"
                            data-toggle="view"
                            onClick={() => {
                              setToggleModal(true);
                              setItemId(item?.id);
                              setCompainId(item?.campaignId);
                              setProviderId2(item?.providerId);
                            }}
                          >
                            <div className="d-flex">
                              <span className="font-weight-bolder font-size-lg mr-2">
                                {item?.subject || "No subject"} -{" "}
                              </span>
                              <span className="text-muted text-truncate max-w-700px">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: removeNewlinesAndCarriageReturns(
                                      item?.stippedBody
                                    ),
                                  }}
                                />
                              </span>
                              <span className="text-muted"> ...</span>
                            </div>
                            <div className="mt-2">
                              {item?.campaignName && (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-top" className="">
                                      <span className="font-weight-bold">
                                        {item?.campaignName}
                                      </span>
                                    </Tooltip>
                                  }
                                >
                                  <Link
                                    to={handleLink(
                                      item?.type,
                                      item?.campaignId
                                    )}
                                    className={`label label-light-${handleIconColor(
                                      item?.type
                                    )} font-weight-bold label-inline text-truncate d-inline-block w-140px mr-1`}
                                  >
                                    {item?.campaignName}
                                  </Link>
                                </OverlayTrigger>
                              )}
                              {item?.type !== "ABM" && (
                                <span
                                  className={`label label-light-${handleIconColor(
                                    item?.type
                                  )} font-weight-bold label-inline text-truncate d-inline-block  mr-1`}
                                >
                                  Step {item?.step}
                                </span>
                              )}
                              {item?.type === "ABM" && (
                                <span
                                  className={`label label-light-${handleIconColor(
                                    item?.type
                                  )} font-weight-bold label-inline text-truncate d-inline-block  mr-1`}
                                >
                                  ABM
                                </span>
                              )}
                              {item?.variant !== "A" &&
                                item?.variant !== null && (
                                  <span
                                    className={`label label-light-${handleIconColor(
                                      item?.type
                                    )} font-weight-bold label-inline text-truncate d-inline-block  mr-1`}
                                  >
                                    Variant {item?.variant}
                                  </span>
                                )}
                            </div>
                          </div>
                          <div
                            className="mt-2 mr-3 font-weight-normal w-150px text-right text-muted d-flex align-items-center"
                            data-toggle="view"
                          >
                            {moment(
                              ConvertToDateCurrentTimeZoneV2(item?.replyDate)
                            ).format(getFromatdate() + " hh:mm A")}

                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-top" className="mb-3">
                                  <span className="font-weight-bold">
                                    {item?.provider}
                                  </span>
                                </Tooltip>
                              }
                            >
                              <span className="label label-rounded label-outline-primary ml-3 font-size-label labelName px-3">
                                {item?.provider?.substring(0, 2)?.toUpperCase()}
                              </span>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-top" className="mb-3">
                                  <span className="font-weight-bold">
                                    Actions
                                  </span>
                                </Tooltip>
                              }
                            >
                              <Dropdown
                                className="dropdown-inline ml-2 position-unset"
                                alignRight
                              >
                                <Dropdown.Toggle
                                  id="dropdown-toggle-top"
                                  as={DropdownCustomToggler}
                                >
                                  <i className="ki ki-bold-more-ver icon-md" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                  <ul className="navi navi-hover">
                                    <li className="navi-header font-weight-bold py-3">
                                      <span className="font-size-lg">
                                        Actions
                                      </span>
                                    </li>
                                    <li className="navi-separator mb-3 opacity-70"></li>
                                    <li
                                      className="navi-item"
                                      onClick={() => {
                                        setModalReply(true);
                                        setItem(item);
                                      }}
                                    >
                                      <span className="navi-link">Reply</span>
                                    </li>
                                    <li
                                      className="navi-item"
                                      onClick={() => {
                                        setModalType(true);
                                        setIds([item?.id]);
                                      }}
                                    >
                                      <span className="navi-link">
                                        Classify type
                                      </span>
                                    </li>
                                  </ul>
                                </Dropdown.Menu>
                              </Dropdown>
                            </OverlayTrigger>
                          </div>
                        </div>
                      );
                    })}
                    {unibox?.inbox?.length === 0 && !isLoading && (
                      <div
                        style={{ height: "300px", marginTop: "200px" }}
                        className="d-flex justify-content-center align-tems-center"
                      >
                        <h1 className="text-center">No Records</h1>
                      </div>
                    )}
                  </div>
                  {/*end::Items*/}
                </div>
              ) : (
                <div
                  className="d-flex justify-content-center align-tems-center"
                  style={{ height: "500px" }}
                >
                  <div className="d-flex justify-content-center align-tems-center">
                    <div className="spinner spinner-primary spinner-lg mr-15"></div>
                  </div>
                </div>
              )}
              {/*end::Body*/}
              {isLoading ? (
                <div className="card-footer py-2">
                  <div className="col-12 col-sm-6 col-xxl-4 order-2 order-xxl-3 d-flex align-items-center justify-content-sm-end text-right my-2 ml-auto">
                    <span className="text-muted font-weight-bold mr-2">
                      Loading...
                    </span>
                  </div>
                </div>
              ) : (
                <div className="card-footer py-2">
                  <div className="col-12 col-sm-6 col-xxl-4 order-2 order-xxl-3 d-flex align-items-center justify-content-sm-end text-right my-2 ml-auto">
                    <span className="text-muted font-weight-bold mr-2">
                      {paginationData.count === 0
                        ? "No records"
                        : `${paginationData.pageNumber} - ${paginationData.count} of ${paginationData.totalItemCount}`}
                    </span>
                    <div className="dropdown-menu dropdown-menu-right p-0 m-0 dropdown-menu-sm">
                      {/* Render dropdown items based on your preference */}
                    </div>
                    <button
                      className="btn btn-default btn-icon btn-sm mr-2"
                      disabled={!paginationData.hasPreviousPage || isLoading}
                      onClick={() => {
                        handlePreviousPage();
                      }}
                    >
                      <i className="ki ki-bold-arrow-back icon-sm" />
                    </button>
                    <button
                      className="btn btn-default btn-icon btn-sm mr-2"
                      disabled={!paginationData.hasNextPage || isLoading}
                      onClick={() => {
                        handleNextPage();
                      }}
                    >
                      <i className="ki ki-bold-arrow-next icon-sm" />
                    </button>
                  </div>
                </div>
              )}
            </div>
            {/*end::Card*/}
          </div>
          {/*end::List*/}
        </div>
      </Container>

      {modalType && (
        <DynamicModal
          size="md"
          show={modalType}
          setShow={setModalType}
          child={
            <Fragment>
              <Card>
                <CardHeader
                  title={"Classify type"}
                  className="font-weight-boldest"
                >
                  {" "}
                  <CardHeaderToolbar className="d-flex justify-centent-between">
                    <div>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => setModalType(false)}
                        className="float-right"
                      >
                        <i className="flaticon2-delete"></i>
                      </span>
                    </div>
                  </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <label className="font-size-sm">Type</label>
                      <Select
                        isSearchable={true}
                        options={
                          !!unibox?.replyTypes
                            ? unibox?.replyTypes.map((x) => {
                                return {
                                  value: x?.id,
                                  label: x?.name,
                                };
                              })
                            : []
                        }
                        value={
                          !!classifyType
                            ? {
                                value: unibox?.replyTypes?.find(
                                  (x) => x.name === classifyType
                                )?.id,
                                label: classifyType,
                              }
                            : null
                        }
                        onChange={(e) => {
                          setClassifyType(e?.label);
                        }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={(e) => {
                    e.preventDefault();
                    setModalType(false);
                  }}
                >
                  Close
                </Button>
                <button
                  className="btn btn-primary btn-sm"
                  disabled={loaderBtn}
                  onClick={(e) => {
                    e.preventDefault();
                    setLoaderBtn(true);
                    apiCall({
                      method: "POST",
                      link: `/api/Unibox/BulkClassifyType`,
                      data: {
                        id: ids,
                        type: classifyType,
                      },
                    }).then((data) => {
                      setLoaderBtn(false);
                      if (data[0] === 200) {
                        setModalType(false);
                        setClassifyType("");
                        setIds([]);
                        handleGetUbniBox();
                        Swal.fire({
                          icon: "success",
                          title: "Success",
                        });
                        // setshowEmails(false);
                      } else {
                        ModelStateMessage(data);
                        setLoaderBtn(false);
                      }
                    });
                  }}
                >
                  Submit
                  {loaderBtn && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </button>
              </Modal.Footer>
            </Fragment>
          }
        />
      )}
      {item && (
        <ReplyModal
          modalReply={modalReply}
          setModalReply={setModalReply}
          item={item}
          viewModelCompanies={viewModelCompanies}
          TemplateViewModel={TemplateViewModel}
          contactList={contacts}
          handleGetUbniBox={handleGetUbniBox}
        />
      )}
      <InboxDetails
        toggleModal={toggleModal}
        setToggleModal={setToggleModal}
        id={itemId}
        compainId={compainId}
        dataTable={unibox}
        handleGetUbniBox={handleGetUbniBox}
        providerId={providerId2}
      />

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        {/*begin::Loading*/}

        {/*end::Loading*/}
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Resend Step
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <span className="text-muted">
            {`We will send an email after ${SpinnerValue} days`}
          </span>
          <div className="justify-content-center mt-3">
            <InputSpinner
              type={"real"}
              precision={2}
              max={30}
              min={0}
              step={1}
              value={SpinnerValue}
              onChange={(num) => setSpinnerValue(num)}
              variant={"primary"}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            onClick={() => {
              handleClose();
              setSpinnerValue(7);
            }}
            className="btn btn-light btn-elevate"
          >
            {"Cancel"}
          </button>
          <button
            type="button"
            onClick={handleResendStep}
            className="btn btn-primary"
            disabled={Removing}
          >
            {Removing ? "Resending..." : "Resend step"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
