import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  ConvertToDateCurrentTimeZoneV2,
  DynamicModal,
  ModelStateMessage,
  apiCall,
  createMarkup,
  getFromatdate,
  replaceValues,
} from "../../helpers/globals";
import { Link } from "react-router-dom";
import Creatable from "react-select/creatable";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { Button, Col, Dropdown, Form, Modal, Row, Spinner } from "react-bootstrap";
import moment from "moment";
import ReplyForm from "../EmailCampaigns/Box/replyForm";
import Swal from "sweetalert2";
import InputSpinner from "react-bootstrap-input-spinner";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const InboxDetails = (props) => {
  const [loaderDetail, setLoaderDetail] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [variables, setVariables] = useState([]);
  const [viewModelCompanies, setViewModelCompanies] = useState([]);
  const [currentRow, setCurrentRow] = useState(null);
  const [replyForm, setReplyForm] = useState(false);
  const [loaderRefresh, setLoaderRefresh] = useState(false);
  const [loaderRefreshMain, setLoaderRefreshMain] = useState(false);
  const [viewModel, setViewModel] = useState([]);
  const [LoadingForwardEmail, setLoadingForwardEmail] = useState(false);
  const [activeStatusReply, setActiveStatusReply] = useState("");
  const [forwardObjectData, setforwardObjectData] = useState({
    reportId: null,
    providerId: null,
    toEmails: [],
    date: moment(),
  });
  const [SpinnerValue, setSpinnerValue] = useState(null);
  const [show, setShow] = useState(false);

  const [list, setList] = useState([]);
  const [pushing, setPushing] = useState(false);
  const [showForwardEmail, setshowForwardEmail] = useState(false);
  const bodyTemplate = useMemo(() => {
    return (
      <Fragment>
        <div class="divReply">
          <div>
            <label style={{ paddingRight: "0.5rem", fontWeight: "700" }}>
              {currentRow?.bounce ? "Bounced at:" : "Replied at:"}
            </label>
            <span>
              {!!currentRow?.replyDate
                ? moment(
                    ConvertToDateCurrentTimeZoneV2(currentRow?.replyDate)
                  ).format(getFromatdate() + " hh:mm A")
                : ""}
            </span>
          </div>
          {currentRow?.bounce ? (
            ""
          ) : (
            <div>
              <label class="font-weight-bolder pr-2">From:</label>
              <Link to={`/Contacts/${currentRow?.contactId}/Details`}>
                {`${currentRow?.firstName}  ${currentRow?.lastName}`}{" "}
              </Link>
              {currentRow?.toEmail && (
                <Fragment>
                  {"<"}
                  <a
                    href={`mailto:${currentRow?.toEmail}`}
                    class="text-primary mr-2"
                  >{`${currentRow?.toEmail}`}</a>
                  {">"}
                </Fragment>
              )}
            </div>
          )}
          <div>
            <label class="font-weight-bolder pr-1">To:</label>
            <span>{currentRow?.provider ?? "-"}</span>
          </div>
          <div>
            <label class="font-weight-bolder pr-1">Subject:</label>
            <span>{replaceValues(currentRow?.subject ?? "-", variables)}</span>
          </div>
          <div class="body-email">
            <div
              dangerouslySetInnerHTML={createMarkup(
                replaceValues(currentRow?.body ?? "-", variables)
              )}
            />
          </div>
        </div>
        <div class="mt-5 d-flex">
          {currentRow?.attachments.map((x) => {
            return `<div class="my-5 mr-5">
                  <button
                    onclick="window.open('${x?.internalLink}', '_blank')"
                    type="button" class="btn btn-outline-secondary">
                    ${
                      x?.fileName?.endsWith(".png") ||
                      x?.fileName?.endsWith(".jpg") ||
                      x?.fileName?.endsWith(".jpeg")
                        ? `<i class="fas fa-image text-danger"></i>`
                        : x?.fileName?.endsWith(".pdf")
                        ? `<i class="fas fa-file-pdf text-danger"></i>`
                        : x?.fileName?.endsWith(".docx")
                        ? `<i class="fas fa-file-word text-primary"></i>`
                        : x?.fileName?.endsWith(".xlsx")
                        ? `<i class="fas fa-file-excel text-success"></i>`
                        : x?.fileName?.endsWith(".csv")
                        ? `<i class="fas fa-file-csv text-success"></i>`
                        : `<i class="fas fa-file"></i>`
                    }
                    ${
                      x?.fileName?.length > 10
                        ? x?.fileName?.substring(0, 10) + "..."
                        : x?.fileName
                    }
                  </button>
                </div>`;
          })}
        </div>
      </Fragment>
    );
  }, [currentRow, variables]);

  const bodyTemplateReplied = useMemo(() => {
    return !!currentRow?.reply && currentRow?.reply?.length > 0
      ? currentRow?.reply
          ?.map(
            (x, i) =>
              `<div class="divReply bg-white px-10 py-5 mb-5 rounded"key={${i}}>
              ${
                !!x?.replaceBody === false
                  ? `<span class="text-muted font-weight-bolder">
                    Message has been queued and will be sent shortly...
                  </span>`
                  : ""
              }
              ${
                x?.isReply
                  ? `<div class="row" style="margin-bottom : 0.5rem">
          <label style="padding-right : 0.5rem;font-weight: 700">Replied at:</label>
          <span>${moment(ConvertToDateCurrentTimeZoneV2(x?.replyDate)).format(
            getFromatdate() + " hh:mm A"
          )}</span>
        </div>`
                  : ""
              }
       <div class="${!!x?.replaceBody === false ? "pt-3 pl-4" : "pl-4"}">
        <label class="font-weight-bolder pr-1">From:</label>
        <span>${x?.provider}</span>
      </div>
      <div class="pl-4">
        <label class="font-weight-bolder pr-1">To:</label>
        <span>${x?.contactFirstName ?? "-"}  ${x?.contactLastName ?? "-"}</span>
      </div>
      <div class="row pl-md-8">
        ${replaceValues(x?.replyBody, variables)}
      </div>
    </div>`
          )
          .join("")
      : "";
  }, [currentRow, variables]);

  useEffect(() => {
    apiCall({
      method: "GET",
      link: `/api/Companies/GetViewModel`,
    }).then((data) => {
      if (data?.length > 0 && data[0] === 200) {
        setViewModelCompanies(data[1]);
      }
    });
  }, []);

  useEffect(() => {
    apiCall({
      method: "GET",
      link: `/api/Campaigns/GetViewModel`,
    }).then((data) => {
      if (data?.length > 0 && data[0] === 200) {
        setViewModel(data[1]);
      }
    });
  }, []);

  useEffect(() => {
    if (!!props?.compainId) {
      apiCall({
        method: "GET",
        link: props?.isCompany
          ? `/api/Contacts/ContactsDropDown?CompanyId=${props?.compainId}&pageNumber=1&pageSize=10000&sortOrder=desc`
          : `/api/Campaigns/GetContactIdsByCampain?campaignId=${props?.compainId}`,
      }).then((dataResult) => {
        try {
          if (dataResult[0] === 200) {
            setContacts(dataResult[1] || []);
          }
        } catch (err) {}
      });
    }
  }, [props.compainId]);

  async function getDataItem(id) {
    await apiCall({
      method: "GET",
      link: `/api/Campaigns/EmailDetails/${id}`,
    }).then((dataResult) => {
      try {
        if (dataResult[0] === 200) {
          setCurrentRow(dataResult[1] ?? null);
          setLoaderDetail(false);
          setActiveStatusReply(dataResult[1]?.replyType);
        }
      } catch (err) {
        setLoaderDetail(false);
      }
    });
  }

  function getClassNameActive(isValid) {
    return isValid ? "btn-primary" : "btn-light-primary";
  }
  const handlePushToHubspot = () => {
    setPushing(true);
    apiCall({
      method: "POST",
      link: `/api/Integrations/hubspot/note/${currentRow?.id}`,
      data: {
        note: "",
      },
    }).then((data) => {
      if (data[0] === 200 && data[1]?.length === 0) {
        setPushing(false);
        Swal.fire({
          title: "Success",
          text: "Reply added Successfully",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      } else if (data[0] === 200 && data[1]?.length > 0) {
        setPushing(false);
        Swal.fire({
          title: "Info",
          text: data[1],
          icon: "info",
          confirmButtonText: "Ok",
        });
      } else if (data[1].message) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: data[1].message,
        });
        setPushing(false);
      } else if (data[0] === 400 && data[1]) {
        Swal.fire({
          icon: "info",
          title: "Oops...",
          text: data[1],
        });
        setPushing(false);
      } else if (data[0] !== 200) {
        let tt = JSON.parse(data[1]);
        setPushing(false);
        for (const [key, value] of Object.entries(tt.errors)) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: value,
          });
        }
      }
    });
  };
  const handleGetList = async () => {
    apiCall({
      method: "GET",
      link: `/api/Integrations/list`,
    }).then((data) => {
      if (data[0] === 200) {
        setList(data[1]);
      }
    });
  };
  const handlePushToPipeDrive = () => {
    setPushing(true);
    const data = {
      ids: 784,
    };
    apiCall({
      method: "POST",
      link: `/api/Integrations/pipedrive/note/${currentRow?.id}`,
      data: data,
    }).then((data) => {
      if (data[0] === 200 && data[1]?.length === 0) {
        setPushing(false);
        Swal.fire({
          title: "Success",
          text: "reply added Successfully",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      } else if (data[0] === 200 && data[1]?.length > 0) {
        setPushing(false);
        Swal.fire({
          title: "Info",
          text: data[1],
          icon: "info",
          confirmButtonText: "Ok",
        });
      } else if (data[1].message) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: data[1].message,
        });
        setPushing(false);
      } else if (data[0] === 400 && data[1]) {
        Swal.fire({
          icon: "info",
          title: "Oops...",
          text: data[1],
        });
        setPushing(false);
      } else if (data[0] !== 200) {
        let tt = JSON.parse(data[1]);
        setPushing(false);
        for (const [key, value] of Object.entries(tt.errors)) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: value,
          });
        }
      }
    });
  };
  const handlePushToSalesForce = () => {
    setPushing(true);
    const data = {
      ids: 784,
    };
    apiCall({
      method: "POST",
      link: `/api/Integrations/salesforce/note/${currentRow?.id}`,
      data: data,
    }).then((data) => {
      if (data[0] === 200 && data[1]?.length === 0) {
        setPushing(false);
        Swal.fire({
          title: "Success",
          text: "reply added Successfully",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      } else if (data[0] === 200 && data[1]?.length > 0) {
        setPushing(false);
        Swal.fire({
          title: "Info",
          text: data[1],
          icon: "info",
          confirmButtonText: "Ok",
        });
      } else if (data[0] === 400 && data[1]) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: data[1],
        });
        setPushing(false);
      } else if (data[1].message) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: data[1].message,
        });
        setPushing(false);
      } else if (data[0] !== 200) {
        let tt = JSON.parse(data[1]);
        setPushing(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: tt[0].errorCode,
        });
      }
    });
  };

  useEffect(() => {
    handleGetList();
  }, []);

  useEffect(() => {
    if (!!props?.id) {
      getDataItem(!!currentIdDetails === true ? currentIdDetails : props?.id);
    }
  }, [props, currentIdDetails]);

  useEffect(() => {
    if (props?.toggleModal && !!currentRow?.contactId) {
      apiCall({
        method: "GET",
        link: `/api/Templates/GetVariables?contactId=${currentRow?.contactId}`,
      }).then((dataResult) => {
        try {
          if (dataResult[0] === 200) {
            setVariables(dataResult[1]?.variables || []);
          }
        } catch (err) {}
      });
    }
  }, [props?.toggleModal, currentRow]);
  const handlDeletReplay = () => {
    console.log("🚀 ~ handlDeletReplay ~ currentRow:", currentRow)
    apiCall({
      method: "PUT",
      link: `/api/Campaigns/RemoveReply`,
      data: {
        reportId: currentRow?.id,
        continued: true,
        numberOfDays: SpinnerValue,
      },
    }).then((data) => {
      if (data[0] === 200) {
        setShow(false);
        setSpinnerValue(7);
        Swal.fire({
          title: "Success",
          text: "Step resend successfully",
          icon: "success",
          confirmButtonText: "Ok",
        });
      } else if (data[0] === 400 && data[1].message) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: data[1].message,
        });
      } else if (data[0] === 402) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Payment Required ",
        });
      }
    });
  };
  const [currentIdDetails, setCurrentIdDetails] = useState(null);
  const handleKeyDown = async (event) => {
    // Check for the left arrow key
    if (event.key === 'ArrowLeft') {
      var myArray = props?.dataTable?.inbox;
      const currentIndex = myArray.findIndex(item => item.id === currentRow?.id);

      if (currentIndex !== -1) {
        // Get the next and previous objects based on the current index
        const previousIndex = (currentIndex - 1 + myArray.length) % myArray.length;

        const previousItem = myArray[previousIndex];
        setCurrentIdDetails(previousItem?.id);
        await getDataItem(previousItem?.id);
      }
    }

    // Check for the right arrow key
    if (event.key === 'ArrowRight') {
      var myArray2 = props?.dataTable?.inbox;
      const currentIndex = myArray2.findIndex(item => item.id === currentRow?.id);

      if (currentIndex !== -1) {
        // Get the next and previous objects based on the current index
        const nextIndex = (currentIndex + 1) % myArray2.length;

        const nextItem = myArray2[nextIndex];
        setCurrentIdDetails(nextItem?.id);

        await getDataItem(nextItem?.id);
      }
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    window.addEventListener('keydown', handleKeyDown);

    // Detach the event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [props, currentRow]);
  return (
    <>
      <DynamicModal
        size="xl"
        className="modalInbox"
        show={props?.toggleModal}
        setShow={props?.setToggleModal}
        child={
          <Card>
            <CardHeader title="Inbox" className="font-weight-boldest">
              <CardHeaderToolbar className="d-flex justify-centent-between">
                <div>
                  <a
                    style={{ cursor: "pointer" }}
                    href={() => false}
                    onClick={() => props?.setToggleModal(false)}
                    className="float-right"
                  >
                    <i className="flaticon2-delete"></i>
                  </a>
                </div>
              </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
              <CardBody className="px-0">
                <Row>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top" className="mb-3">
                        <span className="font-weight-bold">Previous</span>
                      </Tooltip>
                    }
                  >
                    <Col lg="1" className="mt-md-30 arrowItem">

                      <a href={() => false}
                        className=""
                        onClick={async (e) => {
                          e.preventDefault();
                          var myArray = props?.dataTable?.inbox;
                          const currentIndex = myArray.findIndex(item => item.id === currentRow?.id);

                          if (currentIndex !== -1) {
                            // Get the next and previous objects based on the current index
                            const previousIndex = (currentIndex - 1 + myArray.length) % myArray.length;

                            const previousItem = myArray[previousIndex];
                            setCurrentIdDetails(previousItem?.id);

                            await getDataItem(previousItem?.id);
                          }
                        }}>
                        <i className="flaticon2-left-arrow" style={{ fontSize: "4rem" }}></i>
                      </a>

                    </Col>
                  </OverlayTrigger>
                  <Col lg="10">
                {loaderDetail ? (
                  <div style={{ height: "auto" }}>
                    <div className="d-flex justify-content-center mt-md-10">
                      <h3 className="text-muted">Loading </h3>
                      <Spinner
                        as="span"
                        animation="border"
                        className="mb-1 mx-2 mt-2 text-muted"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                ) : (
                  <Fragment>
                    <div className="col-lg-12 mb-7">{bodyTemplate}</div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex justify-content-start align-items-center align-items-start mb-3">
                          <button
                            className="btn btn-primary btn-sm mr-4"
                            onClick={() => {
                              setShow(true);
                            }}
                          >
                            <i className="fa fa-reply icon-sm text-white mr-2 icon-sm"></i>

                            Resend step
                          </button>
                        {replyForm ? (
                          <button
                            className="btn btn-danger btn-sm mr-4"
                            onClick={(e) => {
                              e.preventDefault();
                              setReplyForm(false);
                            }}
                          >
                            <i className="fas fa-times icon-sm pr-2"></i>
                            Close
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary btn-sm mr-4"
                            onClick={(e) => {
                              e.preventDefault();
                              setReplyForm(true);
                              setTimeout(() => {
                                try {
                                  document
                                    .getElementById("bottomContent")
                                    .scrollIntoView({ behavior: "smooth" });
                                } catch (err) {}
                              }, 300);
                            }}
                          >
                            <i className="fas fa-reply icon-sm pr-2"></i>
                            Reply
                          </button>
                        )}
                        {showForwardEmail === true ? (
                          <button
                            className="btn btn-danger btn-sm mr-4"
                            onClick={(e) => {
                              e.preventDefault();
                              setforwardObjectData({
                                reportId: null,
                                providerId: null,
                                toEmails: [],
                                date: moment(),
                              });
                              setshowForwardEmail(false);
                            }}
                          >
                            <i className="fas fa-times icon-sm pr-2"></i>
                            Close
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary btn-sm mr-4"
                            onClick={(e) => {
                              setshowForwardEmail(true);
                              setReplyForm(false);
                            }}
                          >
                            <i className="fas fa-forward icon-sm pr-2"></i>
                            Forward
                          </button>
                        )}
                        <button
                          className="btn btn-primary btn-sm mr-4"
                          disabled={loaderRefresh}
                          onClick={async (e) => {
                            e.preventDefault();
                            setLoaderRefresh(true);
                            await getDataItem(currentRow?.id);
                            setLoaderRefresh(false);
                          }}
                        >
                          {!loaderRefresh && (
                            <i className="flaticon2-refresh-button icon-sm pr-2"></i>
                          )}
                          Refresh
                          {loaderRefresh && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              className="icon-sm"
                              aria-hidden="true"
                            />
                          )}
                        </button>
                        {pushing ? (
                          <Button
                            className="mr-2"
                            variant="secondary"
                            disabled
                            size="sm"
                          >
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            <span className="visually-hidden ml-2">
                              Pushing...
                            </span>
                          </Button>
                        ) : (
                          <Dropdown>
                            <Dropdown.Toggle
                              disabled={pushing}
                              className="mr-2"
                              variant="secondary"
                              id="dropdown-basic"
                              size="sm"
                            >
                              <i className="fab fa-rockrms mr-2" />
                              Push to CRM
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {list?.map(
                                (item, index) =>
                                  item?.title === "HubSpot" &&
                                  item?.active && (
                                    <Dropdown.Item
                                      key={index}
                                      onClick={() => {
                                        handlePushToHubspot();
                                      }}
                                    >
                                      <i className="fab fa-hubspot mr-2 mr-2 text-warning" />
                                      Push to HubSpot
                                    </Dropdown.Item>
                                  )
                              )}
                              {list?.map(
                                (item, index) =>
                                  item?.title === "PipeDrive" &&
                                  item?.active && (
                                    <Dropdown.Item
                                      key={index}
                                      onClick={() => {
                                        handlePushToPipeDrive();
                                      }}
                                    >
                                      <i className="fab fa-product-hunt mr-2 text-primary" />
                                      Push to PipeDrive
                                    </Dropdown.Item>
                                  )
                              )}
                              {list?.map(
                                (item, index) =>
                                  item?.title === "SalesForce" &&
                                  item.active && (
                                    <Dropdown.Item
                                      key={index}
                                      onClick={() => {
                                        handlePushToSalesForce();
                                      }}
                                    >
                                      <i className="fab fa-salesforce mr-2 text-primary" />
                                      Push to Salesforce
                                    </Dropdown.Item>
                                  )
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                      </div>
                      <div className="bd-highlight">
                        <Form.Group
                          controlId={"groupFilterLabel"}
                          className="mb-3 pl-10"
                        >
                          <div className="d-flex justify-content-end p-2 bd-highlight">
                            {!!props?.dataTable?.replyTypes &&
                              props?.dataTable.replyTypes.length > 0 &&
                              props?.dataTable.replyTypes
                                .slice(0, 4)
                                .map((x, i) => (
                                  <Fragment key={x.id}>
                                    <button
                                      className={`btn ${getClassNameActive(
                                        activeStatusReply === x?.name ||
                                          !!activeStatusReply === false
                                      )} mr-5 label-lg mr-5 btn-sm`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setActiveStatusReply(x?.name);
                                        // setLoaderBtn(true);
                                        apiCall({
                                          method: "POST",
                                          link: `/api/Campaigns/ClassifyType`,
                                          data: {
                                            id: currentRow?.id,
                                            type: x?.name,
                                          },
                                        }).then((data) => {
                                          // setLoaderBtn(false);
                                          if (data[0] === 200) {
                                            // setToggleModal(false)
                                            setLoaderRefreshMain(true);
                                            props?.handleGetUbniBox();
                                            setLoaderRefreshMain(false);

                                            Swal.fire({
                                              icon: "success",
                                              title: "Success",
                                            });
                                          } else {
                                            ModelStateMessage(data);
                                            // setLoaderBtn(false);
                                          }
                                        });
                                      }}
                                    >
                                      {x?.name}
                                    </button>
                                  </Fragment>
                                ))}
                          </div>
                          <div className="d-flex justify-content-end p-2 bd-highlight">
                            {!!props?.dataTable?.replyTypes &&
                              props?.dataTable.replyTypes.length > 0 &&
                              props?.dataTable.replyTypes
                                .slice(4)
                                .map((x, i) => (
                                  <Fragment key={x.id}>
                                    <button
                                      className={`btn ${getClassNameActive(
                                        activeStatusReply === x?.name ||
                                          !!activeStatusReply === false
                                      )} mr-5 label-lg mr-5 btn-sm`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setActiveStatusReply(x?.name);
                                        // setLoaderBtn(true);
                                        apiCall({
                                          method: "POST",
                                          link: `/api/Campaigns/ClassifyType`,
                                          data: {
                                            id: currentRow?.id,
                                            type: x?.name,
                                          },
                                        }).then((data) => {
                                          // setLoaderBtn(false);
                                          if (data[0] === 200) {
                                            // setToggleModal(false)
                                            setLoaderRefreshMain(true);
                                            props?.handleGetUbniBox();
                                            setLoaderRefreshMain(false);

                                            Swal.fire({
                                              icon: "success",
                                              title: "Success",
                                            });
                                          } else {
                                            ModelStateMessage(data);
                                            // setLoaderBtn(false);
                                          }
                                        });
                                      }}
                                    >
                                      {x?.name}
                                    </button>
                                  </Fragment>
                                ))}
                          </div>
                        </Form.Group>
                      </div>
                    </div>
                    {showForwardEmail === true ? (
                      <>
                        <div className="col-md-12 lign-items-start mb-3 pl-10">
                          <div
                            className="form-group mb-3"
                            style={{ width: "100%" }}
                          >
                            <label className="font-size-sm mt-5">Emails</label>
                            <Creatable
                              isMulti
                              classNamePrefix="react-select"
                              size="sm"
                              name="country"
                              value={forwardObjectData.toEmails}
                              onChange={(e) => {
                                var obj = { ...forwardObjectData };
                                obj.toEmails = e;
                                setforwardObjectData(obj);
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="col-md-12 lign-items-start mb-3 pl-10"
                          style={{ float: "right" }}
                        >
                          <button
                            className="btn btn-primary btn-sm mr-4"
                            style={{ float: "right" }}
                            disabled={LoadingForwardEmail}
                            onClick={(e) => {
                              setLoadingForwardEmail(true);
                              var row = {}; // {...forwardObjectData}
                              row.reportId = currentRow?.id;
                              row.providerId = props?.providerId;
                              row.toEmails = forwardObjectData?.toEmails?.map(
                                (a) => a.label
                              );
                              row.date = moment();
                              apiCall({
                                method: "POST",
                                link: `/api/Sending/Forward`,
                                data: row,
                              }).then((data) => {
                                if (data[0] === 200) {
                                  Swal.fire({
                                    icon: "success",
                                    title: "Success",
                                    text:
                                      "Message has been forwarded successfully",
                                  });
                                  setforwardObjectData({
                                    reportId: null,
                                    providerId: null,
                                    toEmails: [],
                                    date: moment(),
                                  });
                                  setshowForwardEmail(false);
                                } else if (data[1].message) {
                                  Swal.fire({
                                    icon: "error",
                                    title: "Oops...",
                                    text: data[1].message,
                                  });
                                } else if (data[0] === 400) {
                                  let tt = JSON.parse(data[1]);
                                  for (const [key, value] of Object.entries(
                                    tt.errors
                                  )) {
                                    Swal.fire({
                                      icon: "error",
                                      title: "Oops...",
                                      text: value,
                                    });
                                  }
                                } else if (data[0] === 500) {
                                  Swal.fire({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Something went wrong",
                                  });
                                }
                                setLoadingForwardEmail(false);
                              });
                            }}
                          >
                            <i className="fas fa-forward icon-sm pr-2"></i>
                            Forward
                            {LoadingForwardEmail && (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                className="ml-2"
                                aria-hidden="true"
                              />
                            )}
                          </button>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {replyForm && (
                      <Row>
                        <Col className="px-14">
                          <ReplyForm
                            GetData={() => props?.handleGetUbniBox()}
                            key={currentRow?.id}
                            item={currentRow}
                            viewModelCompanies={viewModelCompanies}
                            templates={props?.templates}
                            to={`${currentRow?.firstName} ${currentRow?.lastName}`}
                            subject={replaceValues(
                              currentRow?.subject,
                              variables
                            )}
                            getDataItem={() => getDataItem(currentRow?.id)}
                            body={
                              <div
                                dangerouslySetInnerHTML={createMarkup(
                                  replaceValues(currentRow?.body, variables)
                                )}
                              />
                            }
                            setReplyForm={setReplyForm}
                            variables={variables}
                            contactList={contacts}
                            viewModel={viewModel}
                          />
                        </Col>
                      </Row>
                    )}
                    {!!currentRow?.reply && currentRow?.reply?.length > 0 && (
                      <Fragment>
                        <Row>
                          <Col className="px-12 mb-3">
                            <div className="d-flex justify-content-start align-items-center">
                              <h4 className="text-primary">Replies</h4>
                              <div
                                className="timeline-steps aos-init aos-animate"
                                data-aos="fade-up"
                              >
                                <div className={` timeline-step active mb-0`}>
                                  <div
                                    className={`timeline-content`}
                                    data-toggle="popover"
                                    data-trigger="hover"
                                    data-placement="top"
                                    title=""
                                    data-content=""
                                    data-original-title={2020}
                                  >
                                    <div className={` inner-circle active`}>
                                      <i className="fa fa-reply icon-sm text-white"></i>

                                      <div className="chiffer-top">
                                        {" "}
                                        {currentRow?.reply?.length}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <div className="col-lg-12 bg-primary-o-30 px-12 py-5">
                          <div
                            className={
                              currentRow?.reply?.length <= 3
                                ? ""
                                : "scrollDivReplies"
                            }
                          >
                            <div
                              dangerouslySetInnerHTML={createMarkup(
                                bodyTemplateReplied
                              )}
                            />
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </Fragment>
                    )}
                  </Col>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top" className="mb-3">
                        <span className="font-weight-bold">Next</span>
                      </Tooltip>
                    }
                  >
                    <Col lg="1" className="mt-md-30 arrowItem">
                      <a href={() => false} className=""
                        onClick={async (e) => {
                          e.preventDefault();
                          var myArray = props?.dataTable?.inbox;
                          const currentIndex = myArray.findIndex(item => item.id === currentRow?.id);

                          if (currentIndex !== -1) {
                            // Get the next and previous objects based on the current index
                            const nextIndex = (currentIndex + 1) % myArray.length;

                            const nextItem = myArray[nextIndex];
                            setCurrentIdDetails(nextItem?.id);

                            await getDataItem(nextItem?.id);
                          }
                        }}>
                        <i className="flaticon2-right-arrow" style={{ fontSize: "4rem" }}></i>
                      </a>

                    </Col>
                  </OverlayTrigger>
                </Row>
              </CardBody>
            </CardBody>
          </Card>
        }
      />
      <Modal
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        {/*begin::Loading*/}

        {/*end::Loading*/}
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Resend step
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <span className="text-muted">
            {`We will send you a email after ${SpinnerValue} days`}
          </span>
          <div className="justify-content-center mt-3">
            <InputSpinner
              type={"real"}
              precision={2}
              max={30}
              min={0}
              step={1}
              value={SpinnerValue}
              onChange={(num) => setSpinnerValue(num)}
              variant={"primary"}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            onClick={handlDeletReplay}
            className="btn btn-primary"
          >
            {"Save"}
          </button>
          <button
            type="button"
            onClick={() => {
              setShow(false);
              setSpinnerValue(7);
            }}
            className="btn btn-light btn-elevate"
          >
            {"Cancel"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InboxDetails;
