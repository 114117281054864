/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import objectPath from "object-path";
import React, { useEffect, useMemo, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Brand } from "../brand/Brand";
import { QuickUserToggler } from "../extras/QuickUserToggler";
import { KTUtil } from "./../../../_assets/js/components/util";
import { apiCall } from "../../../../app/helpers/globals";
import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import Swal from "sweetalert2";
import IntroJs from "../extras/IntroJs"
export function Aside() {

  const uiService = useHtmlClassService();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { token } = JSON.parse(localStorage.getItem("user"));
  const [menu, setMenu] = useState([]);

  const layoutProps = useMemo(() => {
    return {
      asideClassesFromConfig: uiService.getClasses("aside", true),
      asideSecondaryDisplay: objectPath.get(
        uiService.config,
        "aside.secondary.display"
      ),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        "aside.self.minimize.toggle"
      ),
      extrasSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      extrasNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      extrasQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      extrasQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      extrasLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      extrasUserDisplay: objectPath.get(
        uiService.config,
        "extras.user.display"
      ),
    };
  }, [uiService]);

  const tabs = {
    tabId1: "kt_aside_tab_1",
    tabId2: "kt_aside_tab_2",
    tabId3: "kt_aside_tab_3",
    tabId4: "kt_aside_tab_4",
    tabId5: "kt_aside_tab_5",
    tabId6: "kt_aside_tab_6",
    tabId7: "kt_aside_tab_7",
    tabId8: "kt_aside_tab_8",
    tabId9: "kt_aside_tab_9",
    tabId10: "kt_aside_tab_10",
    tabId11: "kt_aside_tab_11",
    tabId12: "kt_aside_tab_12",
    tabId13: "kt_aside_tab_13",
    tabId14: "kt_aside_tab_14",
    tabId15: "kt_aside_tab_15",
  };
  const [activeTab, setActiveTab] = useState(false);
  const handleTabChange = (id) => {
    setActiveTab(id);
    const asideWorkspace = KTUtil.find(
      document.getElementById("kt_aside"),
      ".aside-secondary .aside-workspace"
    );
    if (asideWorkspace) {
      KTUtil.scrollUpdate(asideWorkspace);
    }
  };

  const handleMenu = () => {
    fetch(BASE_URL + "/api/Generic/Menu", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      Allow: "GET",
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          setMenu(data[1]?.permissions);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    handleMenu();
  }, []);

  function checkLinkIncluded(value, element) {
    var currentLink = window.location.pathname;
    if (currentLink.includes(value)) {
      handleTabChange(element);
    }
  }

  function checkBuilder() {
    apiCall({
      method: "GET",
      link: `/api/Generic/AppVersion`,
    }).then((data) => {
      if (data[0] === 200) {
        if (String(window.location.hostname) !== "localhost") {
          if (String(process.env.REACT_APP_VERSION) !== String(data[1])) {
            Swal.fire({
              position: "center",
              icon: "info",
              title: "New Build Version Released. Clearing Cache now ...",
              showConfirmButton: false,
              timer: 5000,
            });
            setTimeout(function () {
              if (caches) {
                caches.keys().then((names) => {
                  for (const name of names) {
                    caches.delete(name);
                  }
                });
              }
              window.location.reload(true);
            }, 2000);
          };
        }
      }
      return data;
    });
  }

  useEffect(() => {

    var currentLink = window.location.pathname;
    switch (currentLink) {
      case "/Sequences/management": {
        setActiveTab(tabs.tabId1);
        break;
      }
      case "/templatesList": {
        setActiveTab(tabs.tabId2);
        break;
      }
      case "/search": {
        setActiveTab(tabs.tabId3);
        break;
      }
      case "/Leadlist": {
        setActiveTab(tabs.tabId4);
        break;
      }
      case "/Contacts": {
        setActiveTab(tabs.tabId6);
        break;
      }
      case "/Opportunities": {
        setActiveTab(tabs.tabId7);
        break;
      }
      case "/tasks": {
        setActiveTab(tabs.tabId1);
        break;
      }
      case "/GlobalImport": {
        setActiveTab(tabs.tabId12);
        break;
      }
      case "/Staff": {
        setActiveTab(tabs.tabId10);
        break;
      }
      case "/Companies": {
        setActiveTab(tabs.tabId11);
        break;
      }
      case "/settings/custom-fields": {
        setActiveTab(tabs.tabId13);
        break;
      }
      case "/settings/mailbox": {
        setActiveTab(tabs.tabId13);
        break;
      }
      case "/settings/blacklist": {
        setActiveTab(tabs.tabId13);
        break;
      }
      case "/settings/schedule": {
        setActiveTab(tabs.tabId13);
        break;
      }
      case "/Agencies": {
        setActiveTab(tabs.tabId14);
        break;
      }
      case "/Businesses": {
        setActiveTab(tabs.tabId15);
        break;
      }
      default: {
        setActiveTab(tabs.tabId1);
      }
    }
    menu.length > 0 &&
      menu.forEach((x, i) => {
        if (x.position > 0) {
          checkLinkIncluded(x?.link, tabs.tabId + i);
        }
      });
  }, [tabs, menu]);
//   const steps = [
//     {
//         element: '.asideintro',
//         title: 'Aside bar',
//         intro: 'Aside bar',
//     },
   
//     {
//       element: '.asidemune',
//       title: 'Aside menu',
//       intro: 'Menu',
//   },
//   {
//     element: '.Dashboard',
//     title: 'Aside menu',
//     intro: 'Menu',
// },
// {
//   element: '.Search',
//   title: 'Aside search',
//   intro: 'Search',
// },
// {
//   element: '.Sequences',
//   title: 'Aside sequences',
//   intro: 'Sequences',
// },
// {
//   element: '.Newsletters',
//   title: 'Aside newsletters',
//   intro: 'Newsletters',
// },
// {
//   element: '.ProspectLists',
//   title: 'Aside Prospect Lists',
//   intro: 'Prospect lists',
// },
// {
//   element: '.Contacts',
//   title: 'Aside contacts',
//   intro: 'Contacts',
// },
// {
//   element: '.Companies',
//   title: 'Aside companies',
//   intro: 'Contacts',
// },
// {
//   element: '.ABCompanies',
//   title: 'Aside AB Companies',
//   intro: 'ABCompanies',
// },
// {
//   element: '.Opportunities',
//   title: 'Aside opportunities',
//   intro: 'Opportunities',
// },{
//   element: '.Tasks',
//   title: 'Aside tasks',
//   intro: 'Tasks',
// },

// {
//   element: '.Settings',
//   title: 'Aside settings',
//   intro: 'Settings',
// },
// {
//   element: '.UserProfile',
//   title: 'Aside user profile',
//   intro: 'User profile',
// },
// ];
  return (
    <>
      {/* begin::Aside */}
      <button hidden={true} onClick={handleMenu} id="Menu-refrech" className="btn btn-primary h-10 w-10">
buttonMenu-refrech
    </button>
      <div
        id="kt_aside"
        className={`aside aside-left d-flex asideintro ${layoutProps.asideClassesFromConfig}`}
      >
         {/* <IntroJs
    steps={steps}
    />   */}
    
        {/* begin::Primary */}
        <div className="aside-primary d-flex flex-column align-items-center flex-row-auto bg-primary">
          <Brand />
          {/* begin::Nav Wrapper */}
          <div className="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull asidemune">
            {/* begin::Nav */}
            <ul className="list-unstyled flex-column" role="tablist">
              {menu.map((item, index) => {
                if (item.position > 0) {

                  return (
                    <li
                      key={index}
                      className={`nav-item mb-3 ${item?.name==="Prospect Lists"?"ProspectLists":item?.name} ${item?.name==="AB Companies"?"ABCompanies":""}`}
                      data-toggle="tooltip"
                      data-placement="rigth"
                      data-container="body"
                      data-boundary="window"
                    >
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id="metronic-features">{item?.name}</Tooltip>
                        }
                      >
                        <Link
                          to={item?.link}
                          className={`nav-link btn btn-icon btn-clean btn-lg ${window.location.pathname === item.link
                            ? "active"
                            : ""
                            }`}
                          data-toggle="tab"
                          data-target={`#${tabs.tabId + index}`}
                          onClick={() => { checkBuilder(); handleTabChange(tabs.tabId + index) }}
                          role="tab"
                        >
                          <span className="svg-icon svg-icon-lg">
                            <SVG src={toAbsoluteUrl(item?.icon)} />
                          </span>
                        </Link>
                      </OverlayTrigger>
                    </li>
                  );
                } else {
                  return "";
                }
              })}
            </ul>
            {/* end::Nav */}
          </div>
          {/* end::Nav Wrapper */}

          {/* begin::Footer */}
          {/* begin::Languages*/}
          {layoutProps.extrasLanguagesDisplay && <LanguageSelectorDropdown />}
          {/* end::Languages */}
          <div className="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 pb-lg-10 ">
            {/* begin::User*/}
            {layoutProps.extrasUserDisplay && (
              <QuickUserToggler
                tabs={{ tabId9: tabs.tabId9, tabId13: tabs.tabId13 }}
                activeTab={activeTab}
                handleTabChange={handleTabChange}
                menu={menu}
              />
            )}
            {/* end::User */}
          </div>
          {/* end::Footer */}
        </div>
        {/* end::Primary */}

        {layoutProps.asideSecondaryDisplay && (
          <>
            {/* begin::Secondary */}
            <div className="aside-secondary d-flex flex-row-fluid">
              {/* begin::Workspace */}
              <div className="aside-workspace scroll scroll-push my-2">
                <div className="tab-content">
                  {/* <asideSearch isActive={activeTab === tabs.tabId1} />
                  <asideMenu isActive={activeTab === tabs.tabId2} /> */}
                </div>
              </div>
              {/* end::Workspace */}
            </div>
            {/* end::Secondary */}
          </>
        )}
      </div>
      {/* end::Aside */}
    </>
  );
}
