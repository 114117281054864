/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import objectPath from "object-path";
import React, { useEffect, useLayoutEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import {
  getBreadcrumbsAndTitle,
  useSubheader
} from "../../_core/MetronicSubheader";
import { BreadCrumbs } from "./components/BreadCrumbs";
import { SearchDropdown } from "../extras/dropdowns/search/SearchDropdown";
import { UserNotificationsDropdown } from "../extras/dropdowns/UserNotificationsDropdown";
import { toAbsoluteUrl } from "../../../_helpers";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export function SubHeader() {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderFixed: objectPath.get(uiService.config, "subheader.fixed"),
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      ),
    };
  }, [uiService]);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    const breadcrumbs =
      aside && aside.breadcrumbs.length > 0
        ? aside.breadcrumbs
        : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle(
      aside && aside.title && aside.title.length > 0
        ? aside.title
        : header.title
    );
    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => { }, [subheader]);

  return (
    <div
      id="kt_subheader"
      className={`subheader py-3 py-lg-4 mb-0 ${layoutProps.subheaderCssClasses}`}
    >
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
      >
        {/* Info */}
        <div className="d-flex align-items-center flex-wrap mr-1">
          {/* begin::Mobile Toggle */}
          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}
          {/* end::Mobile Toggle */}

          {/* begin::Heading */}
          <div className="d-flex align-items-baseline mr-5">
            {/* begin::Title */}
            <h4 className="subheader-title text-dark font-weight-bold my-2 mr-3">
              {subheader.title}
            </h4>
            {/* end::Title */}

            <BreadCrumbs items={subheader.breadcrumbs} />
          </div>
          {/* end::Heading */}
        </div>
        {/* Info */}

        {/* Toolbar */}
        <div className="d-flex align-items-center flex-wrap">
          <div className="topbar-item mx-10">
             <div
                className="btn btn-icon btn-clean btn-lg"
                onClick={(e) => {
                  e.preventDefault();
                  const link = "https://sales-prophet.canny.io/roadmap"; // Replace this with the URL you want to open
                  window.open(link, "_blank");
                }}
              >
                
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Home/Lamp3.svg"
                    )}
                  />
                </span>
              </div>
              <span 
                className="text-dark-50 font-weight-bold cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  const link = "https://sales-prophet.canny.io/roadmap"; // Replace this with the URL you want to open
                  window.open(link, "_blank");
                }}
                >Product Roadmap</span>
          </div>
          <div className="topbar-item mr-15">
            <div
                className="btn btn-icon btn-clean btn-lg"
                onClick={(e) => {
                  e.preventDefault();
                  const link = "https://salesprophet.customerly.help/en"; // Replace this with the URL you want to open
                  window.open(link, "_blank");
                }}
              >
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Code/Question-circle.svg"
                    )}
                  />
                </span>
              </div>
              <span 
                className="text-dark-50 font-weight-bold cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  const link = "https://salesprophet.customerly.help/en"; // Replace this with the URL you want to open
                  window.open(link, "_blank");
                }}
                >Help Desk</span>
          </div>
          <UserNotificationsDropdown />
          <SearchDropdown />
          <LanguageSelectorDropdown />
        </div>
      </div>
    </div>
  );
}
