import { useFormik } from "formik";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import * as auth from "../_redux/authRedux";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useTranslation } from "react-i18next";
import preval from "preval.macro";
import { LanguageSelectorDropdown } from "../../../../_metronic/layout/components/extras/dropdowns/LanguageSelectorDropdown";
import { Slide } from "../../Slide/Slide";
import { Link } from "react-router-dom";
import ReactSelect from "react-select";
const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};
const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialValues = {
  email: "",
  companyName: "",
  appSumoCode1: "",
  appSumoCode2: "",
  appSumoCode3: "",
  firstName: "",
  lastName: "",
  password: "",
  confirmPassword: "",
  phoneNumber: "",
  site: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zipCode: "",
  country: "",
  acceptTerms: false,
};

function Registration(props) {
  const today = new Date().getFullYear();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [isShowPassword, setShowPassword] = useState(false);
  const [valueAppSumo, setValueAppSumo] = useState({value : "1", label : "License Tier 1 - $99"});
  const [t, i18n] = useTranslation();

  const url = new URL(window.location.href);
  function extractCodeFromURL(url) {
    try {
      const urlParams = new URLSearchParams(url.search);
      const code = urlParams.get('c');
      return code;
    } catch (error) {
      console.error(`Error extracting code from URL: ${error}`);
      return null;
    }
  }
  const inviteLink = extractCodeFromURL(url);

  const RegistrationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),
    companyName: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Company is required"),
    firstName: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("First name is required"),
    lastName: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Last name is required"),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      )
      .required("Password is required"),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
    acceptTerms: Yup.bool().oneOf(
      [true],
      "You must accept the terms and conditions"
    ),
  });

  function handleRegister(data) {
    setLoading(true);
    fetch(`${BASE_URL}/api/Account/AppSumo`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      Allow: "POST",
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return [statusCode, data];
      })
      .then((data) => {
        if (data[0] === 200) {
          setLoading(false);
          props.history.push("/activateaccount");
        } else if (data[0] === 400 && data[1].message) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: data[1].message,
          });
        } else if (data[0] === 400) {
          setLoading(false);
          for (const [key, value] of Object.entries(data[1].errors)) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: value,
            });
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error,
        });
      });
  }

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values) => {
      if (inviteLink) {
        values.inviteLink = inviteLink;
      }
      let dataUpdated = {...values};
      dataUpdated.tier = parseInt(valueAppSumo.value);

      //validation 
      if(dataUpdated.tier === 1 && !!dataUpdated.appSumoCode1 === false){
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "AppSumo Code 1 is required",
        });
        return false;
      }
      else if(dataUpdated.tier === 2 && (!!dataUpdated.appSumoCode1 === false || !!dataUpdated.appSumoCode2 === false)){
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "AppSumo Code 1 and AppSumo Code 2 are required",
        });
        return false;
      } else if(dataUpdated.tier === 3 && (!!dataUpdated.appSumoCode1 === false || !!dataUpdated.appSumoCode2 === false || !!dataUpdated.appSumoCode3 === false)){
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "AppSumo Code 1, AppSumo Code 2, AppSumo Code 3 are required",
        });
        return false;
      } else if(dataUpdated.tier === 4 && (!!dataUpdated.appSumoCode1 === false || !!dataUpdated.appSumoCode2 === false || !!dataUpdated.appSumoCode3 === false || !!dataUpdated.appSumoCode4 === false)){
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "AppSumo Code 1, AppSumo Code 2, AppSumo Code 3, AppSumo Code 4 are required",
        });
        return false;
      } else if(dataUpdated.tier === 5 && (!!dataUpdated.appSumoCode1 === false || !!dataUpdated.appSumoCode2 === false || !!dataUpdated.appSumoCode3 === false || !!dataUpdated.appSumoCode4 === false || !!dataUpdated.appSumoCode5 === false)){
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "AppSumo Code 1, AppSumo Code 2, AppSumo Code 3, AppSumo Code 4, AppSumo Code 5 are required",
        });
        return false;
      }
      handleRegister(dataUpdated);
    },
  });
  const listAppSumo = [
    {value : "1", label : "License Tier 1"},
    {value : "2", label : "License Tier 2"},
    {value : "3", label : "License Tier 3"},
    {value : "4", label : "License Tier 4"},
    {value : "5", label : "License Tier 5"},
  ];
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Content*/}
          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
            {/* <div className="col-2">
              <LanguageSelectorDropdown />
            </div> */}
            {/*begin::Content header*/}
            {/* <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
              <span className="font-weight-bold text-dark-50">
                Don't have an account yet?
              </span>
              <Link
                to="/auth/registration"
                className="font-weight-bold ml-2"
                id="kt_login_signup"
              >
                Sign Up!
              </Link>
            </div> */}
            {/*end::Content header*/}

            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <div
                className="login-form login-signin"
                style={{ display: "block" }}
              >
                <div className="text-left mb-10 mb-lg-10">
                  <h3 className="font-size-h1">
                    {t("Sign up for a SalesProphet")}
                  </h3>
                  <p className="text-muted font-weight-bold">
                    {t("Enter your details to create your account")}
                  </p>
                </div>

                <form
                  id="kt_login_signin_form"
                  className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="form-group fv-plugins-icon-container row">
                    <div className="col-xl-6 coll-lg-6 col-md-12 mb-xl-0 mb-lg-0 mb-md-5 mb-5">
                      <input
                        placeholder={t("First name")}
                        type="text"
                        className={`form-control h-auto py-3 px-6`}
                        name="firstName"
                        {...formik.getFieldProps("firstName")}
                      />
                      {formik.touched.firstName && formik.errors.firstName ? (
                        <div className="fv-plugins-message-container">
                          <div className="text-danger">
                            {formik.errors.firstName}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-xl-6 coll-lg-6 col-md-12">
                      <input
                        placeholder={t("Last name")}
                        type="text"
                        className={`form-control h-auto py-3 px-6`}
                        name="lastName"
                        {...formik.getFieldProps("lastName")}
                      />
                      {formik.touched.lastName && formik.errors.lastName ? (
                        <div className="fv-plugins-message-container">
                          <div className="text-danger">
                            {formik.errors.lastName}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group fv-plugins-icon-container">
                    <input
                      placeholder={t("Company")}
                      type="text"
                      className={`form-control h-auto py-3 px-6`}
                      name="email"
                      {...formik.getFieldProps("companyName")}
                    />
                    {formik.touched.companyName && formik.errors.companyName ? (
                      <div className="fv-plugins-message-container">
                        <div className="text-danger">
                          {formik.errors.companyName}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group fv-plugins-icon-container">
                    <input
                      placeholder={t("Email")}
                      type="email"
                      className={`form-control h-auto py-3 px-6`}
                      name="email"
                      {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="fv-plugins-message-container">
                        <div className="text-danger">{formik.errors.email}</div>
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group fv-plugins-icon-container ">
                    <div className="input-icon input-icon-right">
                      <input
                        placeholder={t("Password")}
                        type={isShowPassword ? "text" : "password"}
                        className={`form-control h-auto py-3 px-6`}
                        name="password"
                        {...formik.getFieldProps("password")}
                      />
                      <span
                        className="cursor-pointer"
                        onClick={(e) => setShowPassword(!isShowPassword)}
                      >
                        {!isShowPassword ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/input/eye-solid.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/input/eye-slash-solid.svg"
                            )}
                          />
                        )}
                      </span>
                    </div>
                    {formik.touched.password && formik.errors.password ? (
                      <div className="fv-plugins-message-container">
                        <div className="text-danger">
                          {formik.errors.password}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group fv-plugins-icon-container">
                    <input
                      placeholder={t("Confirm Password")}
                      type={isShowPassword ? "text" : "password"}
                      className={`form-control h-auto py-3 px-6`}
                      name="confirmPassword"
                      {...formik.getFieldProps("confirmPassword")}
                    />
                    {formik.touched.confirmPassword &&
                      formik.errors.confirmPassword ? (
                      <div className="fv-plugins-message-container">
                        <div className="text-danger">
                          {formik.errors.confirmPassword}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group fv-plugins-icon-container">
                    <ReactSelect
                      options={listAppSumo}
                      defaultValue={listAppSumo[0]}
                      onChange={(e) => {
                        formik.setFieldValue("appSumoCode1", "");
                        formik.setFieldValue("appSumoCode2", "");
                        formik.setFieldValue("appSumoCode3", "");
                        formik.setFieldValue("appSumoCode4", "");
                        formik.setFieldValue("appSumoCode5", "");
                        setValueAppSumo(e);
                      }}
                    />
                  </div>
                  {valueAppSumo?.value === "1" && <div className="form-group fv-plugins-icon-container">
                    <input
                      placeholder={t("AppSumo Code")}
                      type="text"
                      className={`form-control h-auto py-3 px-6`}
                      name="appSumoCode1"
                      {...formik.getFieldProps("appSumoCode1")}
                    />
                    {formik.touched.email && formik.errors.appSumoCode1 ? (
                      <div className="fv-plugins-message-container">
                        <div className="text-danger">{formik.errors.appSumoCode1}</div>
                      </div>
                    ) : null}
                  </div>}
                  {valueAppSumo?.value === "2" && (
                    <div>
                      <div className="form-group fv-plugins-icon-container">
                        <input
                          placeholder={t("AppSumo Code 1")}
                          type="text"
                          className={`form-control h-auto py-3 px-6`}
                          name="appSumoCode1"
                          {...formik.getFieldProps("appSumoCode1")}
                        />
                        {formik.touched.email && formik.errors.appSumoCode1 ? (
                          <div className="fv-plugins-message-container">
                            <div className="text-danger">{formik.errors.appSumoCode1}</div>
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group fv-plugins-icon-container">
                        <input
                          placeholder={t("AppSumo Code 2")}
                          type="text"
                          className={`form-control h-auto py-3 px-6`}
                          name="appSumoCode2"
                          {...formik.getFieldProps("appSumoCode2")}
                        />
                        {formik.touched.email && formik.errors.appSumoCode2 ? (
                          <div className="fv-plugins-message-container">
                            <div className="text-danger">{formik.errors.appSumoCode2}</div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )}
                  
                  {/* Tier 3 */}
                  {valueAppSumo?.value === "3" && (
                    <div>
                      <div className="form-group fv-plugins-icon-container">
                        <input
                          placeholder={t("AppSumo Code 1")}
                          type="text"
                          className={`form-control h-auto py-3 px-6`}
                          name="appSumoCode1"
                          {...formik.getFieldProps("appSumoCode1")}
                        />
                        {formik.touched.email && formik.errors.appSumoCode1 ? (
                          <div className="fv-plugins-message-container">
                            <div className="text-danger">{formik.errors.appSumoCode1}</div>
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group fv-plugins-icon-container">
                        <input
                          placeholder={t("AppSumo Code 2")}
                          type="text"
                          className={`form-control h-auto py-3 px-6`}
                          name="appSumoCode2"
                          {...formik.getFieldProps("appSumoCode2")}
                        />
                        {formik.touched.email && formik.errors.appSumoCode2 ? (
                          <div className="fv-plugins-message-container">
                            <div className="text-danger">{formik.errors.appSumoCode2}</div>
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group fv-plugins-icon-container">
                        <input
                          placeholder={t("AppSumo Code 3")}
                          type="text"
                          className={`form-control h-auto py-3 px-6`}
                          name="appSumoCode3"
                          {...formik.getFieldProps("appSumoCode3")}
                        />
                        {formik.touched.email && formik.errors.appSumoCode3 ? (
                          <div className="fv-plugins-message-container">
                            <div className="text-danger">{formik.errors.appSumoCode3}</div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )}
                   {/* Tier 4 */}
                   {valueAppSumo?.value === "4" && (
                    <div>
                      <div className="form-group fv-plugins-icon-container">
                        <input
                          placeholder={t("AppSumo Code 1")}
                          type="text"
                          className={`form-control h-auto py-3 px-6`}
                          name="appSumoCode1"
                          {...formik.getFieldProps("appSumoCode1")}
                        />
                        {formik.touched.email && formik.errors.appSumoCode1 ? (
                          <div className="fv-plugins-message-container">
                            <div className="text-danger">{formik.errors.appSumoCode1}</div>
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group fv-plugins-icon-container">
                        <input
                          placeholder={t("AppSumo Code 2")}
                          type="text"
                          className={`form-control h-auto py-3 px-6`}
                          name="appSumoCode2"
                          {...formik.getFieldProps("appSumoCode2")}
                        />
                        {formik.touched.email && formik.errors.appSumoCode2 ? (
                          <div className="fv-plugins-message-container">
                            <div className="text-danger">{formik.errors.appSumoCode2}</div>
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group fv-plugins-icon-container">
                        <input
                          placeholder={t("AppSumo Code 3")}
                          type="text"
                          className={`form-control h-auto py-3 px-6`}
                          name="appSumoCode3"
                          {...formik.getFieldProps("appSumoCode3")}
                        />
                        {formik.touched.email && formik.errors.appSumoCode3 ? (
                          <div className="fv-plugins-message-container">
                            <div className="text-danger">{formik.errors.appSumoCode3}</div>
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group fv-plugins-icon-container">
                        <input
                          placeholder={t("AppSumo Code 4")}
                          type="text"
                          className={`form-control h-auto py-3 px-6`}
                          name="appSumoCode4"
                          {...formik.getFieldProps("appSumoCode4")}
                        />
                        {formik.touched.email && formik.errors.appSumoCode4 ? (
                          <div className="fv-plugins-message-container">
                            <div className="text-danger">{formik.errors.appSumoCode4}</div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )}
                    {/* Tier 5 */}
                    {valueAppSumo?.value === "5" && (
                    <div>
                      <div className="form-group fv-plugins-icon-container">
                        <input
                          placeholder={t("AppSumo Code 1")}
                          type="text"
                          className={`form-control h-auto py-3 px-6`}
                          name="appSumoCode1"
                          {...formik.getFieldProps("appSumoCode1")}
                        />
                        {formik.touched.email && formik.errors.appSumoCode1 ? (
                          <div className="fv-plugins-message-container">
                            <div className="text-danger">{formik.errors.appSumoCode1}</div>
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group fv-plugins-icon-container">
                        <input
                          placeholder={t("AppSumo Code 2")}
                          type="text"
                          className={`form-control h-auto py-3 px-6`}
                          name="appSumoCode2"
                          {...formik.getFieldProps("appSumoCode2")}
                        />
                        {formik.touched.email && formik.errors.appSumoCode2 ? (
                          <div className="fv-plugins-message-container">
                            <div className="text-danger">{formik.errors.appSumoCode2}</div>
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group fv-plugins-icon-container">
                        <input
                          placeholder={t("AppSumo Code 3")}
                          type="text"
                          className={`form-control h-auto py-3 px-6`}
                          name="appSumoCode3"
                          {...formik.getFieldProps("appSumoCode3")}
                        />
                        {formik.touched.email && formik.errors.appSumoCode3 ? (
                          <div className="fv-plugins-message-container">
                            <div className="text-danger">{formik.errors.appSumoCode3}</div>
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group fv-plugins-icon-container">
                        <input
                          placeholder={t("AppSumo Code 4")}
                          type="text"
                          className={`form-control h-auto py-3 px-6`}
                          name="appSumoCode4"
                          {...formik.getFieldProps("appSumoCode4")}
                        />
                        {formik.touched.email && formik.errors.appSumoCode4 ? (
                          <div className="fv-plugins-message-container">
                            <div className="text-danger">{formik.errors.appSumoCode4}</div>
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group fv-plugins-icon-container">
                        <input
                          placeholder={t("AppSumo Code 5")}
                          type="text"
                          className={`form-control h-auto py-3 px-6`}
                          name="appSumoCode5"
                          {...formik.getFieldProps("appSumoCode5")}
                        />
                        {formik.touched.email && formik.errors.appSumoCode5 ? (
                          <div className="fv-plugins-message-container">
                            <div className="text-danger">{formik.errors.appSumoCode5}</div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )}
                  <div className="form-group">
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        name="acceptTerms"
                        className="m-1"
                        checked={formik.getFieldProps("acceptTerms").value}
                        {...formik.getFieldProps("acceptTerms")}
                      />
                      <span />
                      <label
                        onClick={handleShow}
                        className="ml-1 text-primary cursor-pointer "
                        rel="noopener noreferrer"
                      >
                        {t("I agree to the Terms & Conditions")}
                      </label>
                    </label>
                    {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                      <div className="">
                        <div className="text-danger">
                          {formik.errors.acceptTerms}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group d-flex flex-wrap justify-content-between">
                    <button
                      type="submit"
                      className="btn btn-success font-weight-bold px-9 py-3 my-3 w-100"
                    >
                      <span>{t("Sign Up")}</span>
                      {loading && (
                        <span className="ml-3 spinner spinner-white"></span>
                      )}
                    </button>
                    <button
                      type="button"
                      className="btn btn-light-primary font-weight-bold px-9 py-3 my-3 w-100"
                      onClick={() => props.history.push("/auth/login")}
                    >
                      {t("Cancel")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy; {today} SalesProphet
                <span className="text-muted ml-3">
                  {t("Build Version")}{" "}
                  <a
                    href={() => false}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-weight-bolder text-muted"
                  >
                    {process.env.REACT_APP_VERSION}
                  </a>{" "}
                  / {t("Build Date:")}{" "}
                  <strong>{preval`module.exports = new Date().toLocaleDateString("en-US");`}</strong>
                </span>
              </div>
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
          {/*begin::Aside*/}
          <div
            className={`login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10 ${String(window.location.href).includes("Unsubscribe") ||
                String(window.location.href).includes("History")
                ? "d-none"
                : ""
              }`}
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`,
            }}
          >
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-between">
              {/* start:: Aside header */}
              <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                <img
                  alt="Logo"
                  className="max-h-150px"
                  src={toAbsoluteUrl("/media/logos/logo-letter-1.png")}
                />
              </Link>
              {/* end:: Aside header */}

              {/* start:: Aside content */}
              <div className="flex-column-fluid d-flex flex-column justify-content-center">
                <Slide />
              </div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 font-weight-bold	text-white">
                  &copy; {today} SalesProphet
                  <span className="text-muted ml-3">
                    {t("Build Version")}{" "}
                    <a
                      href={() => false}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-weight-bolder text-muted"
                    >
                      {process.env.REACT_APP_VERSION}
                    </a>{" "}
                    / {t("Build Date:")}{" "}
                    <strong>{preval`module.exports = new Date().toLocaleDateString("en-US");`}</strong>
                  </span>
                </div>
              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}
        </div>
        {/*end::Login*/}
      </div>
      <Modal show={show} onHide={handleClose} size="xl ">
        <Modal.Header closeButton className="py-4 px-3">
          <Modal.Title className="ml-10 h3">Terms and conditions</Modal.Title>
        </Modal.Header>

        <Modal.Body className="p-0">
          <div className="login-form login-signin" style={{ display: "block" }}>
            <div
              className="card card-custom card-stretch"
              id="kt_page_stretched_card"
            >
              <div className="card-body">
                <PerfectScrollbar
                  options={perfectScrollbarOptions}
                  className="scroll px-5"
                  style={{ maxHeight: "50vh", position: "relative" }}
                >
                  <>
                    <div className="rich-text-block-2 w-richtext">
                      <p>
                        <strong>Welcome to SalesProphet.</strong>
                      </p>
                      <p>
                        These terms and conditions outline the rules and regulations for the use of
                        SalesProphet's website.
                      </p>
                      <p>
                        By accessing this website, we assume that you accept these terms and
                        conditions in full. If you do not accept all of the terms and conditions
                        stated on this page, please do not continue to use SalesProphet's website.
                      </p>
                      <p>
                        The following terminology applies to these Terms and Conditions, Privacy
                        Statement, and Disclaimer Notice, and any or all agreements: "Client,"
                        "you," and "your" refer to you, the person accessing this website and
                        accepting the company's terms and conditions. "The Company," "ourselves,"
                        "we," "our," and "us" refer to our company. "Party," "Parties," or "us"
                        refer to both the client and ourselves, or either the client or ourselves.
                        All terms refer to the offer, acceptance, and consideration of payment
                        necessary to undertake the process of our assistance to the client in the
                        most appropriate manner, whether by formal meetings of a fixed duration or
                        any other means, for the express purpose of meeting the client's needs in
                        respect of the provision of the company's stated services/products, in
                        accordance with and subject to the prevailing law of the United States. Any
                        use of the above terminology or other words in the singular, plural,
                        capitalization, and/or he/she or they are taken as interchangeable and
                        therefore as referring to the same.
                      </p>
                      <p>
                        <strong>Cookies:</strong>
                      </p>
                      <p>
                        We employ the use of cookies. By using SalesProphet's website, you consent
                        to the use of cookies in accordance with SalesProphet's privacy policy.
                      </p>
                      <p>
                        Most of the modern-day interactive websites use cookies to enable us to
                        retrieve user details for each visit. Cookies are used in some areas of our
                        site to enable the functionality of this area and ease of use for those
                        people visiting. Some of our affiliate/advertising partners may also use
                        cookies.
                      </p>
                      <p>
                        <strong>License:</strong>
                      </p>
                      <p>
                        Unless otherwise stated, SalesProphet and/or its licensors own the
                        intellectual property rights for all materials on SalesProphet. All
                        intellectual property rights are reserved. You may view and/or print pages
                        from{" "}
                        <a href="https://salesprophet.io/" target="_new">
                          <strong>https://SalesProphet.io</strong>
                        </a>{" "}
                        for your own personal use subject to restrictions set in these terms and
                        conditions.
                      </p>
                      <p>You must not:</p>
                      <ul role="list">
                        <li>
                          Republish material from{" "}
                          <a href="https://salesprophet.io/">
                            <strong>https://SalesProphet.io</strong>
                          </a>
                        </li>
                        <li>
                          Sell, rent, or sub-license material from{" "}
                          <a href="https://salesprophet.io/">
                            <strong>https://SalesProphet.io</strong>
                          </a>
                        </li>
                        <li>
                          Reproduce, duplicate, or copy material from{" "}
                          <a href="https://salesprophet.io/">
                            <strong>https://SalesProphet.io</strong>
                          </a>
                        </li>
                        <li>
                          Redistribute content from SalesProphet (unless content is specifically
                          made for redistribution).
                        </li>
                      </ul>
                      <h5>‍</h5>
                      <h5>Hyperlinking to our Content</h5>
                      <p>
                        The following organizations may link to our website without prior written
                        approval: government agencies, search engines, news organizations, and
                        online directory distributors. When listing us in a directory, they may link
                        to our website in the same way they hyperlink to other listed businesses.
                        Systemwide accredited businesses are also allowed to link, except for
                        soliciting non-profit organizations, charity shopping malls, and charity
                        fundraising groups, which are not allowed to hyperlink to our website. These
                        organizations may link to our home page, publications, or other website
                        information, as long as the link:
                      </p>
                      <p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(a) is not in any way misleading.</p>
                      <p>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(b) does not falsely imply sponsorship,
                        endorsement, or approval of the linking party and its products or services.
                      </p>
                      <p>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(c) fits within the context of the
                        linking party's site.
                      </p>
                      <p>
                        We may consider and approve, at our sole discretion, other link requests
                        from the following types of organizations: commonly-known consumer and/or
                        business information sources such as Chambers of Commerce, American
                        Automobile Association, AARP, and Consumers Union; dot-com community sites;
                        associations or other groups representing charities, including charity
                        giving sites; online directory distributors; internet portals; accounting,
                        law, and consulting firms whose primary clients are businesses; and
                        educational institutions and trade associations.
                      </p>
                      <p>
                        We will approve link requests from these organizations if we determine that:
                      </p>
                      <p>
                        (a) the link would not reflect unfavorably on us or our accredited
                        businesses (for example, trade associations or other organizations
                        representing inherently suspect types of business, such as work-at-home
                        opportunities, shall not be allowed to link).
                      </p>
                      <p>(b) the organization does not have an unsatisfactory record with us.</p>
                      <p>
                        (c) the benefit to us from the visibility associated with the hyperlink
                        outweighs the absence of SalesProphet.
                      </p>
                      <p>
                        (d) where the link is in the context of general resource information or is
                        otherwise consistent with editorial content in a newsletter or similar
                        product furthering the mission of the organization.
                      </p>
                      <p>
                        These organizations may link to our home page, publications, or other
                        website information, as long as the link:
                      </p>
                      <p>(a) is not in any way misleading</p>
                      <p>
                        (b) does not falsely imply sponsorship, endorsement, or approval of the
                        linking party and its products or services
                      </p>
                      <p>(c) fits within the context of the linking party's site.</p>
                      <p>
                        If you are among the organizations listed in paragraph 2 above and are
                        interested in linking to our website, you must notify us by sending an email
                        to{" "}
                        <a href="mailto:info@salesprophet.io" target="_new">
                          <strong>info@salesprophet.io</strong>
                        </a>
                        . Please include your name, your organization name, contact information
                        (such as a phone number and/or email address) as well as the URL of your
                        site, a list of any URLs from which you intend to link to our website, and a
                        list of the URL(s) on our site to which you would like to link. Allow 2-3
                        weeks for a response. Approved organizations may hyperlink to our website as
                        follows:
                      </p>
                      <p>Approved organizations may hyperlink to our Web site as follows:</p>
                      <p>-By use of our corporate name</p>
                      <p>-By use of the uniform resource locator (Web address) being linked to</p>
                      <p>
                        -By use of any other description of our website or material being linked to
                        that makes sense within the context and format of content on the linking
                        party's site.
                      </p>
                      <p>
                        No use of SalesProphet's logo or other artwork will be allowed for linking
                        absent a trademark license agreement.
                      </p>
                      <h5>Iframes</h5>
                      <p>
                        Without prior approval and express written permission, you may not create
                        frames around our web pages or use other techniques that alter in any way
                        the visual presentation or appearance of our website.
                      </p>
                      <h5>Reservation of Rights</h5>
                      <p>
                        We reserve the right at any time and in our sole discretion to request that
                        you remove all links or any particular link to our website. You agree to
                        immediately remove all links to our website upon such request. We also
                        reserve the right to amend these terms and conditions and its linking policy
                        at any time. By continuing to link to our website, you agree to be bound by
                        and abide by these linking terms and conditions.
                      </p>
                      <h5>Removal of links from our website</h5>
                      <p>
                        If you find any link on our website or any linked website objectionable for
                        any reason, you may contact us about this. We will consider requests to
                        remove links but will have no obligation to do so or to respond directly to
                        you.
                      </p>
                      <p>
                        Whilst we endeavor to ensure that the information on this website is
                        correct, we do not warrant its completeness or accuracy; nor do we commit to
                        ensuring that the website remains available or that the material on the
                        website is kept up to date.
                      </p>
                      <h5>Content Liability</h5>
                      <p>
                        We shall have no responsibility or liability for any content appearing on
                        your website. You agree to indemnify and defend us against all claims
                        arising out of or based upon your website. No link(s) may appear on any page
                        on your website or within any context containing content or materials that
                        may be interpreted as libelous, obscene, or criminal, or which infringes,
                        otherwise violates, or advocates the infringement or other violation of any
                        third-party rights.
                      </p>
                      <h5>Disclaimer</h5>
                      <p>
                        To the maximum extent permitted by applicable law, we exclude all
                        representations, warranties, and conditions relating to our website and the
                        use of this website (including, without limitation, any warranties implied
                        by law in respect of satisfactory quality, fitness for purpose and/or the
                        use of reasonable care and skill). Nothing in this disclaimer will:
                      </p>
                      <ul role="list">
                        <li>
                          limit or exclude our or your liability for death or personal injury
                          resulting from negligence.
                        </li>
                        <li>
                          limit or exclude our or your liability for fraud or fraudulent
                          misrepresentation.
                        </li>
                        <li>
                          limit any of our or your liabilities in any way that is not permitted
                          under applicable law.
                        </li>
                        <li>
                          exclude any of our or your liabilities that may not be excluded under
                          applicable law.
                        </li>
                      </ul>
                      <p>
                        The limitations and exclusions of liability set out in this section and
                        elsewhere in this disclaimer: (a) are subject to the preceding paragraph;
                        and (b) govern all liabilities arising under the disclaimer or in relation
                        to the subject matter of this disclaimer, including liabilities arising in
                        contract, in tort (including negligence), and for breach of statutory duty.
                      </p>
                      <p>
                        To the extent that the website and the information and services on the
                        website are provided free of charge, we will not be liable for any loss or
                        damage of any nature.
                      </p>
                      
                      
                      
                      <p>Updated: February 28, 2020.</p>
                      <p>
                        Welcome to the SalesProphet service ("Service" or "Services"). These
                        SalesProphet Service Terms (these "Terms") explain the relationship between
                        SalesProphet ("SalesProphet", "we" or "us") and you, when you access and use
                        SalesProphet and its related domains (together, the "Website") and/or (ii)
                        download, install, use and in some cases purchase SalesProphet's proprietary
                        email software applications (including all related documentation, updates
                        and upgrades) and any other services offered by SalesProphet Service.
                      </p>
                      
                      <p>
                        You may use the Services only in accordance with these Terms and Usage
                        Policy. We may make changes to these Terms from time to time. When we do, we
                        will revise the "last updated" date given above. It is your responsibility
                        to review these Terms frequently and to remain informed of any changes to
                        them. The then-current version of these Terms will supersede all earlier
                        versions. You agree that your continued use of the Website after such
                        changes have been published to our Services will constitute your acceptance
                        of such revised Terms. We may terminate, suspend, or modify the Services, in
                        general, or with respect to you, from time to time without cause or prior
                        notice. We may also delete any content or data from the Services at our
                        discretion.
                        <br />‍
                      </p>
                      <p>
                        These terms, together with our privacy policy and usage policy, form an
                        agreement between you and SalesProphet. By using the SalesProphet Services,
                        you represent to us that you are legally competent to enter into this
                        agreement.
                      </p>
                      
                      <p>
                        You may use the Services only if you have the legal power and capacity to
                        form a contract with SalesProphet. If you are using the SalesProphet
                        Services on behalf of your employer, your acceptance of these terms has
                        deemed an agreement between your employer and SalesProphet and you represent
                        and warrant that you have the authority to bind your employer to these
                        terms. SalesProphet reserves the right to change these terms and conditions
                        at any time. The date of this agreement’s last update shall be displayed at
                        the top of this agreement. Continued use of the SalesProphet services is
                        deemed your conclusive acceptance of any changes to these terms. If you do
                        not agree to these terms, you are not permitted to use the SalesProphet
                        Services.
                      </p>
                      
                      <p>
                        You may not access the SalesProphet Services if you are our direct
                        competitor, except with our prior written consent. In addition, you may not
                        access the SalesProphet Services for purposes of monitoring their
                        availability, performance or functionality, or for any other benchmarking or
                        competitive purposes.
                      </p>
                      
                      
                      <h5>Your Data</h5>
                      <p>
                        “Your Data” means any data and content stored or transmitted via the
                        Services by or on behalf of you or your end-users (which may include data
                        you elect to import from third-party services you use). This includes
                        messages you send, files you upload, comments on files, and anything else
                        you enter or upload into SalesProphet.
                      </p>
                      
                      <p>
                        In order to use our SalesProphet Services, you must link a 3rd party email
                        account to your SalesProphet Services account, and we require that you grant
                        us certain rights with respect to Your Data. For example, with your
                        permission (which you are granting by using the Services), the SalesProphet
                        Service’s software will obtain access to your email account in order for you
                        to be able to send emails via SalesProphet Service and for the Service to
                        check for replies on emails you sent. You retain full ownership of Your
                        Data. We do not claim any ownership to any of it.
                      </p>
                      
                      <p>
                        As well, we need to be able to transmit, store, and copy Your Data in order
                        to display it to you and your teammates, to index it so you are able to
                        search it, to make backups to prevent data loss, and so on. Your acceptance
                        of this TOS gives us permission to do so and grants us any such rights
                        necessary to provide the service to you, only for the purpose of providing
                        the service (and for no other purpose). This permission includes allowing us
                        to use third-party service providers (such as Microsoft Azure Services) in
                        the operation and administration of the service, and the rights granted to
                        us are extended to these third parties to the degree necessary to provide
                        the service.
                      </p>
                      
                      <p>
                        You are solely responsible for your conduct, the content of Your Data, and
                        your communications with others while using the Services.{" "}
                      </p>
                      <p>
                        For example, it’s your responsibility to ensure that you have all rights and
                        permissions needed to comply with these Terms and to avoid infringement or
                        violation of any rights of others. You will be responsible for your users’
                        compliance with these Terms and will use commercially reasonable efforts to
                        prevent unauthorized access to or use of Services and notify us promptly of
                        any such unauthorized access or use.
                      </p>
                      
                      <p>
                        You acknowledge that SalesProphet has no obligation to monitor any
                        information on the Services, and that we are not responsible for the
                        accuracy, completeness, appropriateness, safety, or legality of Your Data or
                        any other information or content you may be able to access using the
                        Services.
                      </p>
                      
                      <h5>Your SalesProphet Account</h5>
                      <p>
                        You are responsible for maintaining the security of your account, and you
                        are fully responsible for all activities that occur under the account and
                        any other actions taken in connection with your account. You must
                        immediately notify SalesProphet of any unauthorized uses of your account or
                        any other breaches of security. You are not allowed to send spam messages
                        via SalesProphet Services. SalesProphet will not be liable for any acts or
                        omissions by you, including any damages of any kind incurred as a result of
                        such acts or omissions. SalesProphet is passing all liability for any spam
                        or other lawsuits that could occur as a result of your activity within
                        SalesProphet Services to you.
                      </p>
                      
                      <p>
                        You will use the Services only for your internal business purposes and in
                        accordance with applicable laws, rules, and regulations. You are responsible
                        for all of your activities that occur within, through, or as a result of
                        your use of the Services. You agree that you will not use the Services for
                        unlawful purposes or to engage in any illegal, offensive, indecent, or
                        objectionable conduct, including violation of any third-party privacy or
                        other rights.
                      </p>
                      
                      <p>You hereby warrant, represent, and agree that:</p>
                      <ol role="list">
                        <li>
                          The Service may not be used for the sending of unsolicited commercial
                          email (as such term is defined in the CAN-SPAM Act of 2003 and any rules
                          adopted under such act – sometimes called “spam”).
                        </li>
                        <li>
                          You acknowledge that you are the sole or designated “sender” (as such term
                          is defined in the CAN-SPAM Act of 2003 (the “Act”) and any rules adopted
                          under the Act) of any email message sent by you using the Service.
                        </li>
                        <li>The Service may only be used for lawful purposes.</li>
                        <li>
                          You agree you will not access or otherwise use third-party mailing lists
                          or otherwise prepare or distribute mass unsolicited commercial email (as
                          such term is defined in the CAN-SPAM Act of 2003 and any rules adopted
                          under such act), in connection with the Service. You will import, access,
                          or otherwise use only lists for which all listed parties have consented to
                          receive correspondence from you in connection with your use of the
                          Service.
                        </li>
                        <li>
                          You acknowledge and agree that not all email messages sent through the use
                          of the Service will be received by their intended recipients.
                        </li>
                        <li>
                          The “from” line of any email message sent by you using the Service will
                          accurately and in a non-deceptive manner identify your organization, your
                          product, or your service.
                        </li>
                        <li>
                          The “subject” line of any email message sent by you using the Service will
                          not contain any deceptive or misleading content regarding the overall
                          subject matter of the email message.
                        </li>
                        <li>
                          You will include in any email message sent by you using the Products your
                          valid physical address, if required by law, which may be a valid post
                          office box meeting the registration requirements established by the United
                          States Postal Service.
                        </li>
                        <li>
                          The Service may not be used for hosting content, including images and
                          documents, that knowingly infringes on the intellectual property rights of
                          others, including copyright, trademark, and patent infringement, or that
                          includes any obscene or libelous material or other material that violates
                          any applicable law or regulation.
                        </li>
                      </ol>
                      
                      
                      <p>
                        You are solely responsible for all activities that occur in your account(s)
                        and for the security of your passwords. Notwithstanding anything to the
                        contrary herein, SalesProphet has no liability of any kind (whether by
                        contract, tort, or otherwise) for any unauthorized access to your or your
                        users’ accounts as a result of your actions or inactions
                      </p>
                      
                      <h5>Sharing Your Data and Your Privacy</h5>
                      <p>
                        Subject to the limited exceptions specified in our Privacy Policy, until you
                        choose otherwise, all of your data remains visible only to you. However, the
                        Services do allow you to share your data with others. If you choose to share
                        your data, we cannot be responsible for what those other users do with your
                        data, so please carefully consider what you share and with whom you share
                        it.
                      </p>
                      
                      <p>
                        We will maintain administrative, physical, and technical safeguards for the
                        &nbsp;protection of the security, confidentiality, and integrity of your
                        data as described in our Privacy Policy. We do not guarantee that your data
                        will be free from loss, theft, misuse, or unauthorized access, disclosure,
                        alteration or destruction. You acknowledge that it is your responsibility to
                        use a secure encrypted connection if you wish to protect your data when you
                        are transmitting it to us and to keep your own backup copies of your data.
                        You are solely responsible for protecting your passwords, limiting access to
                        your computers and devices, and signing out of the SalesProphet Services
                        when you are not using them.
                      </p>
                      
                      <h5>Disclaimer of Warranties</h5>
                      <p>
                        Your use of the services and the service content is at your sole risk. The
                        services and the service content each are provided on an "as is" and "as
                        available" basis. We and our suppliers and licensors expressly disclaim all
                        warranties of any kind, whether express or implied, including, but not
                        limited to the implied warranties of merchantability, fitness for a
                        particular purpose, title, and non-infringement. We do not guarantee the
                        accuracy, completeness, or usefulness of the services or any service
                        content, and you rely on the services and service content at your own risk.
                        Any material that you access or obtain through our services is done at your
                        own discretion and risk, and you will be solely responsible for any damage
                        to your computer or loss of data that results from the download of any
                        material through our services. No advice or information, whether oral or
                        written, obtained by you from us or through or from our services will create
                        any warranty not expressly stated in this agreement. Some states may
                        prohibit a disclaimer of warranties, and you may have other rights that vary
                        from state to state.
                      </p>
                      
                      <h5>Your Responsibilities</h5>
                      <p>
                        Information and content accessible through the Services may be protected by
                        intellectual property rights of others. Please do not copy, upload,
                        download, or share any information or content unless you have the right to
                        do so. You, not SalesProphet, will be fully responsible and liable for what
                        you copy, share, upload, download, or otherwise use while using the
                        Services. You must not upload spyware or any other malicious software to the
                        Service or use the Service to spam others.
                      </p>
                      
                      <p>
                        You, and not SalesProphet, are responsible for maintaining and protecting
                        all of your data. SalesProphet will not be liable for any loss or corruption
                        of your data, or for any costs, fees, or expenses associated with backing up
                        or restoring your data.
                      </p>
                      
                      <p>
                        If any information related to your account changes, you must notify us
                        promptly and keep your information current. The Services are not intended
                        for use by you if you are under 18 years of age. By agreeing to these Terms,
                        you are representing to us that you are over 18.
                      </p>
                      
                      <p>
                        You will not engage in activity that would cause SalesProphet to violate any
                        of these laws and regulations, and you agree that you will indemnify
                        SalesProphet for any fines, penalties, or other liabilities incurred by
                        SalesProphet for your failure to comply with these provisions.
                      </p>
                      
                      <p>
                        You shall pay all fees for the SalesProphet Services as specified in the
                        billing section of the SalesProphet Services or Order Form, if applicable.
                        Our fees do not include any taxes, levies, duties, or similar governmental
                        assessments of any nature, including, for example, value-added, sales, use,
                        or withholding taxes, assessable by any jurisdiction whatsoever. You are
                        responsible for paying all taxes associated with your purchases. You agree
                        that your purchases are not contingent on the delivery of any future
                        functionality or features, nor dependent on any oral or written public
                        comments made by us regarding future functionality or features.
                      </p>
                      
                      <h5>Copyright Infringement and DMCA Policy</h5>
                      <p>
                        If you believe that material located on or linked to by SalesProphet
                        violates your copyright, you are encouraged to notify SalesProphet in
                        accordance with the DMCA Policy.
                      </p>
                      
                      <h5>Cancelling Your Account</h5>
                      <p>
                        The SalesProphet Service is billed in advance in accordance with our pricing
                        schedule, and all monthly payments are nonrefundable. All subscriptions,
                        whether monthly or annual, will auto-renew on the scheduled renewal date
                        unless a cancellation is requested by the customer before the renewal date.
                        There will be no refunds or credits for partial months of service,
                        upgrade/downgrade refunds, or refunds for months unused with an open
                        account. If you cancel, you will not receive a refund for any service
                        already paid for.
                      </p>
                      
                      <p>
                        You can stop using our Services at any time, and we reserve the right to
                        suspend or end the Services to you in whole or in part at any time, with or
                        without cause, and with or without notice, without incurring the liability
                        of any kind. Without limitation, we may suspend or terminate your use if you
                        are not complying with these Terms or if you use the Services in any way
                        that may cause us legal liability or disrupt others' use of the SalesProphet
                        Services or damage to our business or reputation, or for any other reason.
                        If we suspend or terminate your use, we will try to let you know in advance
                        and help you retrieve data, though there may be some cases (for example,
                        repeatedly or flagrantly violating these Terms, a court order, or danger to
                        other users) where we may suspend or terminate your account and access to
                        the SalesProphet Services immediately. You acknowledge that if your access
                        to the Service is suspended or terminated, you may no longer have access to
                        Your Data that is stored with the Service. To cancel an account, you should
                        send a request to our support team via support chat in your customer portal
                        or via the SalesProphet.io email.
                      </p>
                      
                      <p>
                        The SalesProphet Service is billed in advance in accordance with our pricing
                        schedule, and all monthly payments are nonrefundable. There will be no
                        refunds or credits for partial months of service, upgrade/downgrade refunds,
                        or refunds for months unused with an open account. If you cancel, you will
                        not receive a refund for any service already paid for.
                      </p>
                      
                      <h5>Indemnification</h5>
                      <p>
                        You shall indemnify, defend, and hold harmless the SalesProphet parties from
                        any and all third-party claims and related losses, liability, damages,
                        and/or costs (including attorneys’ fees and costs) arising from (i) any
                        Content, (ii) your use of the Services, or (iii) breach by you or your
                        Content of any Law or this Agreement. We shall indemnify, defend, and hold
                        you harmless from any and all third-party claims and related losses,
                        liability, damages, and/or costs (including attorneys’ fees and costs)
                        arising from an allegation that the SalesProphet Services infringe or
                        misappropriate any third-party intellectual property right.
                      </p>
                      
                      <h5>Limitation of Liability</h5>
                      <p>
                        To the fullest extent permitted by law, in no event will SalesProphet, Alpha
                        Flexi Sarl, or either of their subsidiaries, affiliates, officers,
                        employees, agents, suppliers, or licensors be liable for (a) any indirect,
                        special, incidental, punitive, exemplary, or consequential (including loss
                        of use, data, business, or profits) damages, regardless of legal theory,
                        whether or not SalesProphet has been warned of the possibility of such
                        damages, and even if a remedy fails of its essential purpose; (b) aggregate
                        liability for all claims relating to the services more than the greater of
                        $20 or the amounts paid by you to SalesProphet for the past three months of
                        the services in question.
                      </p>
                      
                      <h5>Miscellaneous</h5>
                      <p>
                        This Agreement constitutes the entire agreement between SalesProphet and you
                        concerning the subject matter hereof, and it may only be modified by a
                        written amendment signed by an authorized executive of SalesProphet or by
                        the posting by SalesProphet of a revised version.
                      </p>
                      
                      <p>
                        Except to the extent applicable law, if any, provides otherwise, this
                        Agreement, any access to or use of the Website will be governed by the laws
                        of the state of British Columbia, Canada, excluding its conflict of law
                        provisions, and the proper venue for any disputes arising out of or relating
                        to any of the same will be the state and provincial courts located in
                        Vancouver, Canada. Except for claims for injunctive or equitable relief or
                        claims regarding intellectual property rights (which may be brought in any
                        competent court without the posting of a bond), any dispute arising under
                        this Agreement shall be finally settled in accordance with the Comprehensive
                        Arbitration Rules of the Judicial Arbitration and Mediation Service, Inc.
                        (“JAMS”) by three arbitrators appointed in accordance with such Rules. The
                        arbitration shall take place in Vancouver, Canada, in the English language,
                        and the arbitral decision may be enforced in any court. The prevailing party
                        in any action or proceeding to enforce this Agreement shall be entitled to
                        costs and attorneys’ fees.
                      </p>
                      
                      <p>
                        If any part of this Agreement is held invalid or unenforceable, that part
                        will be construed to reflect the parties’ original intent, and the remaining
                        portions will remain in full force and effect. A waiver by either party of
                        any term or condition of this Agreement or any breach thereof, in any one
                        instance, will not waive such term or condition or any subsequent breach
                        thereof.
                      </p>
                      
                      <p>
                        You may not assign your rights under this Agreement to any other party
                        without SalesProphet's express written consent; SalesProphet may assign its
                        rights under this Agreement without condition. This Agreement will be
                        binding upon and will inure to the benefit of the parties, their successors
                        and permitted assigns.
                      </p>
                      
                      <h5>SalesProphet Anti-Spam Requirements</h5>
                      <p>
                        As an email service provider, we take spam law seriously, not only for our
                        sake but for yours as well. Spam negatively impacts deliverability rates,
                        and we want to make sure your emails reach their intended recipients as
                        often as possible. We have some very strict rules that must be adhered to in
                        all countries, but you may find that your country has additional
                        requirements.
                      </p>
                      
                      <p>
                        Below you will find a list (conveniently ordered alphabetically) of
                        countries with specific anti-spam legislation as well as our overall rules
                        to help maintain platform integrity.
                      </p>
                      
                      <h5>Requirements for All SalesProphet Campaigns</h5>
                      <ol role="list">
                        <li>You must agree to our Terms of Service.</li>
                        <li>
                          We recommend including an opt-out link into your email templates and
                          outreach campaigns for audiences you haven't contacted previously.
                        </li>
                        <li>
                          You must include your contact information inside every email promotion*
                          that you send, including a physical mailing address or PO Box where you
                          can receive mail. (Not a website or email address.)
                        </li>
                      </ol>
                      <ul role="list">
                        <li>
                          An email promotion is usually a broadcast with a low ratio of text to
                          image area offering discounts or other incentives to purchase a product or
                          service, or to subscribe, register, download, or perform some other action
                          intended to drive revenue for the vendor.
                        </li>
                      </ul>
                      <ol start={4} role="list">
                        <li>Your contact information and subject line must be 100% accurate.</li>
                        <li>
                          If you regularly use an integrated service or CRM platform, you need to
                          abide by their terms of use, in addition to our own.
                        </li>
                        <li>
                          It is strongly recommended that your emails comply with the US CAN-SPAM
                          Act. If you break the rules, you could be liable for hundreds of dollars
                          for each recipient that you sent non-compliant messages to.
                        </li>
                        <li>
                          In addition to CAN-SPAM rules, you must comply with the anti-spam laws of
                          the countries your recipients live in. So if you're sending to EU
                          residents and US residents, check the EU spam laws to make sure you're
                          also EU compliant.
                        </li>
                      </ol>
                      
                      
                      <h5>International Requirements By Country</h5>
                      <p>
                        Here are links to the Anti-Spam legislation in countries outside of the US:
                      </p>
                      
                      <h5>Australia</h5>
                      <p>Spam Act 2003, Act No. 129 of 2003 as amended.</p>
                      
                      <h5>Austria</h5>
                      <p>Telecommunications Act 2003.</p>
                      
                      <h5>Belgium</h5>
                      <p>
                        Commission de la protection de la vie privée, Le spam en Belgique Etat des
                        lieux en juillet 2003, July 4, 2003.
                      </p>
                      
                      <h5>Canada</h5>
                      <p>Canada’s Anti-Spam Legislation (CASL).</p>
                      
                      <h5>China</h5>
                      <p>Measures for Administration of E-Mail Service on Internet (2006)</p>
                      <p>(Unofficial English Translation).</p>
                      
                      <h5>Cyprus</h5>
                      <p>
                        Section 06 of the Regulation of Electronic Communications and Postal
                        Services Law of 2004 (Law 12 (I) / 2004 deals with unsolicited
                        communications (spam).
                      </p>
                      
                      <h5>Czech Republic</h5>
                      <p>Act No. 480/2004 Coll., on Certain Information Society Services.</p>
                      
                      <h5>Estonia</h5>
                      <p>Information Society Service Act.</p>
                      
                      <h5>EU</h5>
                      <p>
                        Article 13 of DIRECTIVE 2002/58/EC OF THE EUROPEAN PARLIAMENT AND OF THE
                        COUNCIL of 12 July 2002
                      </p>
                      <p>
                        The EU body that addresses spam is The Contact Network of Spam Enforcement
                        Authorities (CNSA).
                      </p>
                      <p>
                        The Directive is implemented by each member state independently so you will
                        want to check with your particular country law for specific details.
                      </p>
                      
                      <h5>France</h5>
                      <p>CNIL Guidelines on email marketing.</p>
                      
                      <h5>Germany</h5>
                      <p>
                        Art. 7 German Unfair Competition Law (Gesetz gegen Unlauteren Wettbewerb)
                        (UWG)
                      </p>
                      <p>
                        Art. 202a, 263, 303a, 303b of the German Criminal Code Art. 6 of the German
                        Law regarding Information Society Services Art. 28 Par. 4 of the German Data
                        Protection Act.
                      </p>
                      <h5>India</h5>
                      <p>Information Technology Act of 2000.</p>
                      <h5>Italy</h5>
                      <p>
                        Italy’s anti-spam laws are some of the strictest, even to the point of
                        potential imprisonment for sending spam. If you’re sending to Italian
                        recipients, follow these guidelines to the letter.
                      </p>
                      <p>Personal Data Protection Code (legislative decree no. 196/2003)</p>
                      <p>DL 196/2003 Personal Data Protection Code.</p>
                      <h5>Netherlands</h5>
                      <p>
                        Dutch law requires very explicit permission and heavily protects data and
                        privacy.
                      </p>
                      <h5>New Zealand</h5>
                      <p>
                        The Unsolicited Electronic Messages Act 2007. The Department of Internal
                        Affairs provides detailed guidelines on the anti-spam laws.
                      </p>
                      <h5>South Africa</h5>
                      <p>Regulation of Spam in South Africa – South African Law.</p>
                      <h5>Sweden</h5>
                      <p>Swedish Marketing Act (Swedish Code of Statutes, SFS 1995:450).</p>
                      <p>
                        Personal Data Act (Swedish Code of Statutes, SFS 1998:204), in so far as
                        spam activities involve processing of personal data.
                      </p>
                      <h5>UK</h5>
                      <p>The Privacy and Electronic Communications (EC Directive) Regulations.</p>
                      <p>
                        If you are sending to a country not on this list, please inform our support
                        and they will look into updating this page.
                      </p>
                      <p>
                        If any of the preceding requirements is not met we reserve the right to
                        suspend any campaign or account without warning.
                      </p>
                    </div>

                  </>
                </PerfectScrollbar>
              </div>

              <div className="card-footer">
                <div className="form-group">
                  <div className="checkbox-list">
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        name="acceptTerms"
                        checked={formik.getFieldProps("acceptTerms").value}
                        {...formik.getFieldProps("acceptTerms")}
                      />
                      <span />I agree to the Terms &amp; Conditions
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="py-2">
          <Button variant="link" onClick={handleClose} size="sm">
            {t("Close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
