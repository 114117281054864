import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import Swal from "sweetalert2";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useTranslation } from "react-i18next";
import preval from "preval.macro";
import { LanguageSelectorDropdown } from "../../../../_metronic/layout/components/extras/dropdowns/LanguageSelectorDropdown";
import { Slide } from "../../Slide/Slide";

function ResetPassword(props) {
  const today = new Date().getFullYear();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [isRequested, setIsRequested] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isShowPassword, setShowPassword] = useState(false);
  var url2 = window.location.search;
  let param = url2?.replace("?code=", "");
  let token = param?.split(",,,")[0];
  var email = param?.split(",,,")[1];
  const [t, i18n] = useTranslation();


  const ForgotPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "Minimum 8 symbols")
      .max(50, "Maximum 50 symbols")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      )
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const initialValues = {
    email: email,
    password: "",
    token: token,
    confirmPassword: "",
  };


  const handleResetPassword = (values) => {
    setLoading(true);
    fetch(`${BASE_URL}/api/Account/ResetPassword`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      Allow: "POST",
      body: JSON.stringify(values),
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Password reset successfully",
          });
          setLoading(false);
          setIsRequested(true);
        } else if (data[0] === 400 && data[1].errors) {
          setLoading(false);
          for (var i = 0; i < data[1].errors.length; i++) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: data[1].errors[i].description,
            });
          }
          setIsRequested(false);
        } else if (data[0] === 500) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!. Please try again later",
          });
          setIsRequested(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error,
        });
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values) => {
      handleResetPassword(values);
    },
  });

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="d-flex flex-column flex-root">
          {/*begin::Login*/}
          <div
            className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
            id="kt_login"
          >
            {/*begin::Content*/}
            <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
              <div className="col-2">
                <LanguageSelectorDropdown />
              </div>
              {/*begin::Content header*/}
              {/* <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
              <span className="font-weight-bold text-dark-50">
                Don't have an account yet?
              </span>
              <Link
                to="/auth/registration"
                className="font-weight-bold ml-2"
                id="kt_login_signup"
              >
                Sign Up!
              </Link>
            </div> */}
              {/*end::Content header*/}

              {/* begin::Content body */}
              <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
                <div className="login-form login-forgot" style={{ display: "block" }}>
                  <div className="text-center mb-10 mb-lg-10">
                    <h3 className="font-size-h1">{t("Reset Password !")}</h3>
                  </div>
                  <form
                    onSubmit={formik.handleSubmit}
                    className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                  >
                    <div className="form-group fv-plugins-icon-container">
                      <div className="input-icon input-icon-right">
                        <input
                          type={isShowPassword ? "text" : "password"}
                          placeholder={t("New Password")}
                          className={`form-control h-auto py-3 px-6`}
                          name="password"
                          {...formik.getFieldProps("password")}
                        />
                        <span onClick={(e) => setShowPassword(!isShowPassword)}>
                          {!isShowPassword ? (
                            <SVG src={toAbsoluteUrl("/media/svg/input/eye-solid.svg")} />
                          ) : (
                            <SVG src={toAbsoluteUrl("/media/svg/input/eye-slash-solid.svg")} />
                          )}
                        </span>
                      </div>
                      {formik.touched.password && formik.errors.password ? (
                        <div className="fv-plugins-message-container">
                          <div className="text-danger">* {formik.errors.password}</div>
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group fv-plugins-icon-container">
                      <input
                        type={isShowPassword ? "text" : "password"}
                        placeholder={t("Confirm Password")}
                        className={`form-control h-auto py-3 px-6`}
                        name="confirmPassword"
                        {...formik.getFieldProps("confirmPassword")}
                      />
                      {formik.touched.confirmPassword &&
                        formik.errors.confirmPassword ? (
                        <div className="fv-plugins-message-container">
                          <div className="text-danger">
                            * {formik.errors.confirmPassword}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group d-flex flex-wrap flex-center">
                      <button
                        id="kt_login_forgot_submit"
                        type="submit"
                        className="btn btn-info font-weight-bold px-9 py-3 my-3 mx-4"
                        disabled={loading}
                      >
                        {t("Submit")}
                      </button>
                      <Link to="/auth">
                        <button
                          type="button"
                          id="kt_login_forgot_cancel"
                          className="btn btn-light-info font-weight-bold px-9 py-3 my-3 mx-4"
                        >
                          {t("Cancel")}
                        </button>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
              {/*end::Content body*/}

              {/* begin::Mobile footer */}
              <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
                <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                  &copy; {today} SalesProphet
                  <span className="text-muted ml-3">
                    {t("Build Version")}{" "}
                    <a
                      href={() => false}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-weight-bolder text-muted"
                    >
                      {process.env.REACT_APP_VERSION}
                    </a>{" "}
                    / {t("Build Date:")}{" "}
                    <strong>{preval`module.exports = new Date().toLocaleDateString("en-US");`}</strong>
                  </span>
                </div>
              </div>
              {/* end::Mobile footer */}
            </div>
            {/*end::Content*/}
            {/*begin::Aside*/}
            <div
              className={`login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10 ${String(window.location.href).includes("Unsubscribe") || String(window.location.href).includes("History") ? "d-none" : ""}`}
              style={{
                backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`,

              }}
            >
              {/*begin: Aside Container*/}
              <div className="d-flex flex-row-fluid flex-column justify-content-between">
                {/* start:: Aside header */}
                <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                  <img
                    alt="Logo"
                    className="max-h-150px"
                    src={toAbsoluteUrl("/media/logos/logo-letter-1.png")}
                  />
                </Link>
                {/* end:: Aside header */}

                {/* start:: Aside content */}
                <div className="flex-column-fluid d-flex flex-column justify-content-center">
                  <Slide />
                </div>
                {/* end:: Aside content */}

                {/* start:: Aside footer for desktop */}
                <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                  <div className="opacity-70 font-weight-bold	text-white">
                    &copy; {today} SalesProphet
                    <span className="text-muted ml-3">
                      {t("Build Version")}{" "}
                      <a
                        href={() => false}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="font-weight-bolder text-muted"
                      >
                        {process.env.REACT_APP_VERSION}
                      </a>{" "}
                      / {t("Build Date:")}{" "}
                      <strong>{preval`module.exports = new Date().toLocaleDateString("en-US");`}</strong>
                    </span>
                  </div>
                </div>
                {/* end:: Aside footer for desktop */}
              </div>
              {/*end: Aside Container*/}
            </div>
            {/*begin::Aside*/}
          </div>
          {/*end::Login*/}
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ResetPassword));
