/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import preval from "preval.macro";
import { LanguageSelectorDropdown } from "../../../../_metronic/layout/components/extras/dropdowns/LanguageSelectorDropdown";
import { Slide } from "../../Slide/Slide";
export default function Activationemail() {
  const today = new Date().getFullYear();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  var url = new URL(window.location.href);
  var code = url?.searchParams?.get("code");
  const [accountverified, setaccountverified] = useState(false);
  const [t, i18n] = useTranslation();
  useEffect(() => {
    sendCodeFunction(code);
  }, [code]);

  function sendCodeFunction(params) {
    if (params !== null && params !== "" && params !== undefined) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        Code: params,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${BASE_URL}/api/Account/ConfirmEmail`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          setaccountverified(true);
        })
        .catch((error) => console.log("error", error));
    }
  }

  return (
    <div className="d-flex flex-column flex-root">
      {/*begin::Login*/}
      <div
        className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
        id="kt_login"
      >
        {/*begin::Content*/}
        <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
          <div className="col-2">
            <LanguageSelectorDropdown />
          </div>
          {/*begin::Content header*/}
          {/* <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
          <span className="font-weight-bold text-dark-50">
            Don't have an account yet?
          </span>
          <Link
            to="/auth/registration"
            className="font-weight-bold ml-2"
            id="kt_login_signup"
          >
            Sign Up!
          </Link>
        </div> */}
          {/*end::Content header*/}

          {/* begin::Content body */}
          <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
            <div className="login login-3 login-signin-on d-flex flex-row-fluid" id="">
              <div
                className="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid"
                style={{ backgroundImage: "url(/media/bg/bg-3.jpg)" }}
              >
                <div className="col-xl-6 col-lg-6 col-md-10 col-12  p-7 position-relative overflow-hidden">
                  {/*begin::Login Header*/}
                  <div className="d-flex flex-center mb-15">
                    <span>
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/logos/Full-Color_-RGB_Vertical.svg"
                        )}
                      />
                    </span>
                  </div>
                  {/*end::Login Header*/}
                  {/*begin::Login Sign in form*/}
                  <div className="card card-custom shadow">
                    <div className="card-header card-header-tabs-line">
                      <div className="d-flex flex-center flex-column mt-7 mx-auto">
                        {!accountverified ? (
                          <>
                            <h2 className="font-size-h1 mb-5 font-weight-bolder">
                              {t(" Verify your email")}
                            </h2>
                          </>
                        ) : (
                          <>
                            <h2 className="font-size-h1 mb-5 font-weight-bolder">
                              {t("Welcome To SalesProphet")}
                            </h2>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="login-signin">
                        <div className="text-center">
                          {!accountverified ? (
                            <>
                              <div className="font-size-h3 text-muted font-weight-bold">
                                {t("We have sent an email from")}{" "}
                                <span className="text-primary text-decoration-none font-weight-bolder line-height-lg my-3">
                                  {t(" support@mail.salesprophet.com")}
                                </span>
                                <br />
                                {t("to verify email")}
                                <br />
                                {t("If you didn't receive any email. Please")}{" "}
                                <a href="mailto:support@salesprophet.io?subject=signup-issue">Contact us</a>
                              </div>
                              <div className="text-center my-10">
                                <Link
                                  to="/auth/login"
                                  className="btn btn-lg btn-primary font-weight-bolder"
                                >
                                  {t("Log In")}
                                </Link>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="font-size-h3 text-muted font-weight-bold">
                                <span className="text-primary text-decoration-none font-weight-bolder line-height-lg my-3">
                                  {t("Your account is ready")}
                                </span>
                                <br />
                                {t("Please click login to access your account")}
                              </div>
                              <div className="text-center my-10">
                                <Link
                                  to="/auth/login"
                                  className="btn btn-lg btn-primary font-weight-bolder"
                                >
                                  {t("Log In")}
                                </Link>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*end::Login*/}
            </div>
          </div>
          {/*end::Content body*/}

          {/* begin::Mobile footer */}
          <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
            <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
              &copy; {today} SalesProphet
              <span className="text-muted ml-3">
                {t("Build Version")}{" "}
                <a
                  href={() => false}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-weight-bolder text-muted"
                >
                  {process.env.REACT_APP_VERSION}
                </a>{" "}
                / {t("Build Date:")}{" "}
                <strong>{preval`module.exports = new Date().toLocaleDateString("en-US");`}</strong>
              </span>
            </div>
          </div>
          {/* end::Mobile footer */}
        </div>
        {/*end::Content*/}
        {/*begin::Aside*/}
        <div
          className={`login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10 ${String(window.location.href).includes("Unsubscribe") || String(window.location.href).includes("History") ? "d-none" : ""}`}
          style={{
            backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`,

          }}
        >
          {/*begin: Aside Container*/}
          <div className="d-flex flex-row-fluid flex-column justify-content-between">
            {/* start:: Aside header */}
            <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
              <img
                alt="Logo"
                className="max-h-150px"
                src={toAbsoluteUrl("/media/logos/logo-letter-1.png")}
              />
            </Link>
            {/* end:: Aside header */}

            {/* start:: Aside content */}
            <div className="flex-column-fluid d-flex flex-column justify-content-center">
              <Slide />
            </div>
            {/* end:: Aside content */}

            {/* start:: Aside footer for desktop */}
            <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
              <div className="opacity-70 font-weight-bold	text-white">
                &copy; {today} SalesProphet
                <span className="text-muted ml-3">
                  {t("Build Version")}{" "}
                  <a
                    href={() => false}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-weight-bolder text-muted"
                  >
                    {process.env.REACT_APP_VERSION}
                  </a>{" "}
                  / {t("Build Date:")}{" "}
                  <strong>{preval`module.exports = new Date().toLocaleDateString("en-US");`}</strong>
                </span>
              </div>
            </div>
            {/* end:: Aside footer for desktop */}
          </div>
          {/*end: Aside Container*/}
        </div>
        {/*begin::Aside*/}
      </div>
      {/*end::Login*/}
    </div>
  );
}
