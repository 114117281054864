/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import { useSelector } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import objectPath from "object-path";
import { Modal, ProgressBar } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { UserProfileDropdown } from "./dropdowns/UserProfileDropdown";
import { useHistory } from "react-router-dom";

const BASE_URL = process.env.REACT_APP_BASE_URL;
export function QuickUserToggler(props) {
  const token = JSON.parse(localStorage.getItem("user")).token;
  const [show, setShow] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
    };
  }, [uiService]);
  const Username = localStorage.getItem("UserEmail");
  const handelQuickUser = async () => {
    handleShow();
  };
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const LoginBussnisAdmin = async (values) => {
    await fetch(`${BASE_URL}/api/Account/Token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `bearer ${token}`,
      },
      body: JSON.stringify({
        username: Username,
        password: values.password,
      }),
      Allow: "POST",
    })
      .then(async (response) => {
        const statusCode = response.status;
        const contentType = response.headers.get("content-type");

        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          var dataUser = data[1];
           handleClose();
           history.push("/dashboard");
          window.location.reload();
          localStorage.removeItem("user");
          localStorage.removeItem("ProfilePicture");
          localStorage.removeItem("dateFormat");
          localStorage.removeItem("IconShow");
          localStorage.removeItem("sendingTimeZoneValue");
          localStorage.removeItem("sendingTimeZone");

          localStorage.setItem("fullName", `${data[1].firstName} ${data[1].lastName}`);
          localStorage.setItem("user", JSON.stringify(data[1]));
          localStorage.setItem("sendingTimeZone", dataUser?.sendingTimeZone);
          localStorage.setItem("sendingTimeZoneValue", dataUser?.sendingTimeZoneValue);
          localStorage.setItem("ProfilePicture", data[1].profilePicture?.toString());
          if (document.getElementById("backgroundProfileGlobal")) {
            document.getElementById(
              "backgroundProfileGlobal"
            ).style.backgroundImage = `url("${data[1].profilePicture?.toString()}")`;
          }
          localStorage.setItem(
            "dateFormat",
            data[1]?.dateFormat !== null &&
              data[1]?.dateFormat !== undefined &&
              data[1]?.dateFormat !== ""
              ? data[1]?.dateFormat
              : "DD/MM/YYYY"
          );
          localStorage.setItem("IconShow", false);

          const user = {
            accessToken: "access-token-8f3ae836da744329a6f93bf20594b5cc",
            refreshToken: "access-token-f8c137a2c98743f48b643e71161d90aa",
          };
          if (user) {
            return [200, { ...user, password: undefined }];
          }
        } else if (data[0] === 400 && data[1].message) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: data[1].message,
          });

          return [400];
        } else if (data[0] === 400 && data[1].errors) {
          for (const [key, value] of Object.entries(data[1].errors)) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: value,
            });
          }

          return [400];
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error,
        });

        return [400];
      });
  };
  useEffect(() => { }, []);

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="mb-5 " >
          <div className="nav-item Settings">
            {props.menu.map((item, index) => {
              if (item.position < 0) {
                return (
                  <OverlayTrigger
                    key={index}
                    placement="right"
                    overlay={
                      <Tooltip id="metronic-features">{item.name}</Tooltip>
                    }
                  >
                    <Link
                      to={item.link}
                      data-toggle="tab"
                      role="tab"
                      className={`nav-link btn btn-icon btn-clean btn-lg ${window.location.pathname.includes(
                        item?.link
                      ) && "active"}`}
                    >
                      <span className="svg-icon svg-icon-lg">
                        <SVG src={toAbsoluteUrl(item.icon)} />
                      </span>
                    </Link>
                  </OverlayTrigger>
                );
              } else {
                return ""
              }
            })}
          </div>

          <div className="nav-item my-5 UserProfile">
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="quick-user-tooltip">User Profile</Tooltip>}
            >
              <div
                className={`nav-link btn btn-icon btn-clean btn-lg ${props.activeTab ===
                  props.tabs?.tabId9 && "active"}`}
                id="kt_quick_user_toggle"
                data-placement="right"
                data-container="body"
                data-boundary="window"
                onClick={() => props.handleTabChange(props.tabs?.tabId9)}
              >
                <span className="symbol symbol-30 symbol-lg-40">
                  <span className="svg-icon svg-icon-lg">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                    />
                  </span>
                </span>
              </div>
            </OverlayTrigger>
          </div>
          <>
            {localStorage.getItem("IconShow") === "true" ? (
              <div className="nav-item my-5">
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="quick-user-tooltip">Cancel Login</Tooltip>
                  }
                >
                  <div
                    className={"nav-link btn btn-icon btn-clean btn-lg"}
                    id="kt_quick_user_toggle"
                    data-placement="right"
                    data-container="body"
                    data-boundary="window"
                    onClick={() => {
                      handelQuickUser();
                    }}
                  >
                    <span className="symbol symbol-30 symbol-lg-40">
                      <span className="svg-icon svg-icon-lg">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Navigation/Sign-out.svg"
                          )}
                        />
                      </span>
                    </span>
                  </div>
                </OverlayTrigger>
              </div>
            ) : (
              ""
            )}
          </>
        </div>
      )}
      <Modal
        size="md"
        show={show}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        {isLoading && (
          <ProgressBar
            variant={"success"}
            animated
            now={100}
            style={{ height: "3px", width: "100%" }}
          />
        )}
        <Formik
          enableReinitialize={true}
          initialValues={{
            username: "",
            password: "",
          }}
          onSubmit={(values) => {
            LoginBussnisAdmin(values);
          }}
        >
          {({ handleSubmit, setFieldValue, values }) => (
            <>
              <>
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg">
                    Login : {Username}
                  </Modal.Title>
                </Modal.Header>
              </>
              <Modal.Body className="overlay overlay-block cursor-default">
                {isLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}
                <Form className="form form-label-right">

                  <label>
                    {"Password"} <span className="text-primary">*</span>
                  </label>
                  <div className="d-flex align-items-center">
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      className="form-control"
                      placeholder={"New password"}
                      value={values.password}
                      autoFocus={true}
                    />
                    <span
                      onClick={() => setShowPassword(!showPassword)}
                      className="svg-icon svg-icon-md svg-icon-primary position-absolute right-0 mr-5 pr-3"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          showPassword
                            ? "/media/svg/icons/General/Visible.svg"
                            : "/media/svg/icons/General/Hidden.svg"
                        )}
                      ></SVG>
                    </span>
                  </div>
                  <div className="text-primary">
                    <ErrorMessage name="newPassword" />
                  </div>


                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button

                  type="button"
                  onClick={handleClose}
                  className="btn btn-light btn-elevate"
                >
                  {"Cancel"}
                </button>
                <> </>
                <button
                  disabled={!values.password ? true : false}
                  type="submit"
                  onClick={() => handleSubmit()}

                  className="btn btn-primary btn-elevate outline-primary"

                >
                  {"Login"}
                </button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal>
      {!layoutProps.offcanvas && <UserProfileDropdown />}
    </>
  );
}
