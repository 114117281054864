import {
  LOGIN_URL,
  ME_URL,
  REGISTER_URL,
  REQUEST_PASSWORD_URL
} from "../_redux/authCrud";
import userTableMock from "./userTableMock";
import Swal from "sweetalert2";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export default function mockAuth(mock) {


  mock.onPost(LOGIN_URL).reply(({ data }) => {
    
    const { email, password } = JSON.parse(data);

    return fetch(`${BASE_URL}/api/Account/Token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      Allow: "POST",
      body: JSON.stringify({
        username: email,
        password: password,
      }),
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
          if (data[0] === 200) {
            var dataUser = data[1];
            localStorage.removeItem("tnsApp");
            localStorage.removeItem("tC");
            localStorage.removeItem("tPL");
            localStorage.removeItem("tMQ");
            localStorage.removeItem("tTf");
            localStorage.removeItem("t3D");
            localStorage.removeItem("tTDu");
            localStorage.removeItem("tTDe");
            localStorage.removeItem("tADu");
            localStorage.removeItem("tADe");
            localStorage.removeItem("tTE");
            localStorage.removeItem("tAE");
          localStorage.setItem("user", JSON.stringify(data[1]));
          localStorage.setItem("sendingTimeZone", dataUser?.sendingTimeZone);
          localStorage.setItem("sendingTimeZoneValue", dataUser?.sendingTimeZoneValue);

          localStorage.setItem("ProfilePicture", data[1].profilePicture?.toString());
          localStorage.setItem(
            "dateFormat",
            data[1]?.dateFormat !== null &&
              data[1]?.dateFormat !== undefined &&
              data[1]?.dateFormat !== ""
              ? data[1]?.dateFormat
              : "DD/MM/YYYY"
          );
          if(data[1].roles[0]=="Super Admin"){localStorage.setItem("IconShow",false)}else{localStorage.setItem("IconShow","")};
          localStorage.setItem("UserEmail",email)
          
          const user = {
            accessToken: "access-token-8f3ae836da744329a6f93bf20594b5cc",
            refreshToken: "access-token-f8c137a2c98743f48b643e71161d90aa",
          };
          if (user) {
            return [200, { ...user, password: undefined }];
          }
        } else if (data[0] === 400 && data[1].message) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: data[1].message,
          });
          return [400];
        } else if (data[0] === 400 && data[1].errors) {
          for (const [key, value] of Object.entries(data[1].errors)) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: value,
            });
          }
          return [400];
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error,
        });
        console.log(error);
        return [400];
      });
  });

  mock.onPost(REGISTER_URL).reply(({ data }) => {
    const { email, fullname, username, password } = JSON.parse(data);

    if (email && fullname && username && password) {
      const user = {
        id: generateUserId(),
        email,
        fullname,
        username,
        password,
        roles: [2], // Manager
        accessToken: "access-token-" + Math.random(),
        refreshToken: "access-token-" + Math.random(),
        pic: process.env.PUBLIC_URL + "/media/users/default.jpg"
      };

      userTableMock.push(user);

      return [200, { ...user, password: undefined }];
    }

    return [400];
  });

  mock.onPost(REQUEST_PASSWORD_URL).reply(({ data }) => {
    const { email } = JSON.parse(data);

    if (email) {
      const user = userTableMock.find(
        x => x.email.toLowerCase() === email.toLowerCase()
      );

      if (user) {
        user.password = undefined;

        return [200, { ...user, password: undefined }];
      }
    }

    return [400];
  });

  mock.onGet(ME_URL).reply(({ headers: { Authorization } }) => {
    const accessToken =
      Authorization &&
      Authorization.startsWith("Bearer ") &&
      Authorization.slice("Bearer ".length);

    if (accessToken) {
      const user = userTableMock.find(x => x.accessToken === accessToken);

      if (user) {
        return [200, { ...user, password: undefined }];
      }
    }

    return [401];
  });

  function generateUserId() {
    const ids = userTableMock.map(el => el.id);
    const maxId = Math.max(...ids);
    return maxId + 1;
  }
  
}
