/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Form, Modal, Row } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
/* import { Filter } from "./Filter"; */
import { useFormik } from "formik";
import PerfectScrollbar from "react-perfect-scrollbar";
import Select from "react-select";
import Swal from "sweetalert2";
import {
  apiCall,
  ConvertToDateCurrentTimeZoneSending,
  ConvertToDateCurrentTimeZoneV2,
  ConvertToTimeZoneSendingPost,
  ModelStateMessage,
  RequiredHtml,
  setValueField,
  ToggleTrackingOnOff,
} from "../../helpers/globals";
/* import ContactCustomField, { GetApiChange } from "../ContactCustomField"; */
import CKEditor from "ckeditor4-react";
import moment from "moment";
import * as Yup from "yup";
import ReactSelect from "react-select";
import { Preview } from "../Templates/preview";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

const Schema = Yup.object().shape({
  firstName: Yup.string().required("* First name is required"),
  lastName: Yup.string().required("* Last name is required"),
});

const SchemaEmail = Yup.object().shape({});

export function ABMPage({
  id = "",
  idsTable,
  rows,
  setIdsTable,
  setLoaderTable,
  ContactSelectedSendEmail,
  isAbmAuthorized,
  ...props
}) {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const token = JSON.parse(localStorage.getItem("user")).token;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  /* const [rows, setRows] = useState(rowsRef.current); */
  const [technologies, setTechnologies] = useState([]);
  const [dateSent, setDateSent] = useState(new Date());
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showEmails, setshowEmails] = useState(false);
  const [ShowPreview, setShowPreview] = useState(false);
  const [contactList, setcontactList] = useState([]);
  const [CCvalue, setCCvalue] = useState([]);
  const [BCCvalue, setBCCvalue] = useState([]);

  useEffect(() => {
    setVariables([]);
    var objList = [];
    var indexes = 1;
    contactList.map((a, i) => {
      if (idsTable.includes(a.value)) {
        objList.push({
          name: `{{Influencer${indexes}}}`,
          value: String(a.label.split("-")[0]).trim() || a.label,
        });
        indexes++;
      }
      return objList;
    });
    setTimeout(() => {
      setVariables([...initvariables, ...objList]);
    }, 50);
  }, [idsTable]);

  const [emails, setEmails] = useState([
    {
      isMain: true,
      value: "",
      type: "",
      state: "",
    },
  ]);

  const [phoneNumbers, setPhoneNumbers] = useState([
    {
      isMain: true,
      value: "",
      type: "",
      state: "",
    },
  ]);
  const [socials, setSocials] = useState([
    {
      isMain: true,
      value: "",
      type: "",
      state: "",
    },
  ]);

  useEffect(() => {
    var a = [];

    if (!!rows === true && rows.length > 0) {
      rows.forEach((x) => {
        a.push({
          value: x?.id,
          label: x?.firstName + " " + x?.lastName + " - " + x?.jobTitle,
        });
      });
    }
    setcontactList(a);
  }, [rows]);

  function GetContactTo() {
    return !!props?.selectedContactEmail
      ? props?.selectedContactEmail
      : contactList
          .filter((x) => x.value === ContactSelectedSendEmail)
          .map((x) => x.label)
          .join(" , ");
  }

  useEffect(() => {
    setCCvalue([]);
    setBCCvalue([]);
    setSubject("");
    setBody("");
    setBasicsData();
    formikEmail.resetForm();

    if (!!ContactSelectedSendEmail) {
      apiCall({
        method: "GET",
        link: `/api/Templates/GetVariables?contactId=${ContactSelectedSendEmail}`,
      }).then((data) => {
        if (data?.length > 0 && data[0] === 200) {
          setVariables(data[1]["variables"]);
          setinitVariables(data[1]["variables"]);
        }
      });
    }
  }, [ContactSelectedSendEmail]);

  const [companyId, setCompanyId] = useState([]);
  const [datahtml, setdatahtml] = useState({ subject: "", body: "" });
  const [Subject, setSubject] = useState("");
  const [provider, setprovider] = useState({});
  const [trackValues, setTrackValues] = useState({
    trackOpen: true,
    trackClick: true,
  });
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    jobTitle: "",
    companyId: null,
    source: "",
    emailDomain: "",
    industry: "",
    jobLevel: "",
    sicCode: "",
    naicsCode: "",
    seoDescription: "",
    annualRevenue: "",
    isAb: true,
    Fields: [],
  });

  const [filter, setFilter] = useState({
    pageNumber: 1,
    pageSize: 10,
    sortField: "Id",
    sortOrder: "desc",
    search: "",
  });

  const [viewModel, setViewModel] = useState([]);
  const [variables, setVariables] = useState([]);
  const [initvariables, setinitVariables] = useState([]);
  const [body, setBody] = useState("");
  const [SinatureList, setSinatureList] = useState([]);
  const [BasicsData, setBasicsData] = useState(null);

  const handleGettContact = async (filterParams) => {
    /*  return await apiCall({
      method: "GET",
      link: `/api/Contacts?CompanyId=${id}&pageNumber=${filterParams.pageNumber}&pageSize=${filterParams.pageSize}&search=${filterParams.search}&sortField=${filterParams.sortField}&sortOrder=${filterParams.sortOrder}`,
    }).then((data) => {
      if (data[0] === 200) {
        setRows(data[1]?.contacts);
        setTotalRows(data[1]?.pagination?.totalItemCount);
      }

      return data;
    }); */
  };

  useEffect(() => {
    getSignatureTemplate();
  }, []);
  function getSignatureTemplate() {
    apiCall({
      method: "GET",
      link: `/api/Templates/Signature`,
    }).then((data) => {
      if (data?.length > 0 && data[0] === 200) {
        setSinatureList(data[1]?.signature || []);
      }
      return data;
    });
  }

  const handlePutContact = (values) => {
    setIsLoading(true);
    apiCall({
      method: "PUT",
      link: `/api/Contacts/${companyId}`,
      data: values,
    }).then((data) => {
      if (data[0] === 200) {
        setIsLoading(false);
        handleClose();
        setCompanyId(null);
        Swal.fire({
          title: "Success",
          text: "Company updated Successfully",
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            handleGettContact(filter);
          }
        });
      } else if (data[0] === 400 && data[1].message) {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: data[1].message,
        });
      } else if (data[0] === 400) {
        setIsLoading(false);
        let tt = JSON.parse(data[1]);
        for (const [key, value] of Object.entries(tt.errors)) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: value,
          });
        }
      } else if (data[0] === 500) {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong",
        });
      }
    });
  };

  const handlePostContact = (values) => {
    setIsLoading(true);
    apiCall({
      method: "POST",
      link: `/api/Contacts`,
      data: values,
    }).then((data) => {
      if (data[0] === 200) {
        setIsLoading(false);
        handleClose();
        handleGettContact(filter);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Contact Created Successfully",
        });
      } else if (data[0] === 400 && data[1].message) {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: data[1].message,
        });
      } else if (data[0] === 400) {
        setIsLoading(false);
        let tt = JSON.parse(data[1]);
        for (const [key, value] of Object.entries(tt.errors)) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: value,
          });
        }
      } else if (data[0] === 500) {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong",
        });
      }
    });
  };

  useEffect(() => {
    apiCall({
      method: "GET",
      link: `/api/Companies/GetViewModel`,
    }).then((data) => {
      if (data?.length > 0 && data[0] === 200) {
        setViewModel(data[1]);
      }
    });

    apiCall({
      method: "GET",
      link: `/api/Templates/GetVariables?contactId=`,
    }).then((data) => {
      if (data?.length > 0 && data[0] === 200) {
        setVariables(data[1]["variables"]);
        setinitVariables(data[1]["variables"]);
      }
    });
  }, []);

  useEffect(() => {
    if (!show) {
      setCompanyId(null);
      setInitialValues({
        firstName: "",
        lastName: "",
        jobTitle: "",
        companyId: null,
        source: "",
        emailDomain: "",
        industry: "",
        jobLevel: "",
        sicCode: "",
        naicsCode: "",
        seoDescription: "",
        annualRevenue: "",
        isAb: true,
      });
      setEmails([
        {
          isMain: true,
          value: "",
          type: "",
          state: "",
        },
      ]);
      setPhoneNumbers([
        {
          isMain: true,
          value: "",
          type: "",
          state: "",
        },
      ]);
      setSocials([
        {
          isMain: true,
          value: "",
          type: "",
          state: "",
        },
      ]);
      formik.resetForm();
    }
  }, [show]);

  useEffect(() => {
    setCCvalue([]);
    setBCCvalue([]);
    setSubject("");
    setBody("");
    setBasicsData();
    formikEmail.resetForm();
  }, [showEmails]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Schema,
    onSubmit: (values) => {
      values.emails = emails;
      values.phoneNumbers = phoneNumbers;
      values.socials = socials;
      values.technologies = technologies;

      // values.emails = values.emails.filter((item) => item.value !== "");
      values.phoneNumbers = values.phoneNumbers.filter(
        (item) => item.value !== ""
      );
      values.socials = values.socials.filter((item) => item.value !== "");

      // loop through email and if item.value is empty setErrors for that item
      values.emails.forEach((item, index) => {
        if (item.value === "" || item.type === "") {
          formik.setFieldError(
            `emails[${index}].value`,
            "* Please fill these fields"
          );
          setError(true);
        } else {
          setError(false);
        }
      });

      if (companyId) {
        handlePutContact(values);
      } else {
        handlePostContact(values);
      }
    },
  });

  const emailObj = {
    templateId: [],
    provider: 0,
    toId: [],
    ccId: [],
    bccId: [],
    subject: "",
    body: "",
    EmailSignature: null,
    trackOpen: true,
    trackClick: true,
  };

  const formikEmail = useFormik({
    initialValues: emailObj,
    enableReinitialize: true,
    validationSchema: SchemaEmail,
    onSubmit: (values) => {
      if (ContactSelectedSendEmail === null) {
        Swal.fire("Oops?", "Please select to", "error");
        return false;
      }
      if (values.body === "") {
        Swal.fire("Oops?", "Body is required", "error");
        return false;
      }
      if (values.subject === "") {
        Swal.fire("Oops?", "Subject is required", "error");
        return false;
      }
      if (values.provider === 0) {
        Swal.fire("Oops?", "Please select from", "error");
        return false;
      }
      values.date = ConvertToTimeZoneSendingPost(dateSent);
      values.ccId = idsTable;
      values.subject = Subject;
      values.toId = [ContactSelectedSendEmail];
      values.signature = BasicsData?.signature;
      //track values
      values.trackClick = trackValues?.trackClick;
      values.trackOpen = trackValues?.trackOpen;

      setIsLoading(true);
      apiCall({
        method: "POST",
        link: `/api/Companies/Email`,
        data: values,
      }).then((data) => {
        setIsLoading(false);
        if (data[0] === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Email Sent Successfully",
          });
          if (!!props?.onHide) {
            props.onHide();
          } else {
            document.getElementById("ClicktoremoveSelection").click();
            setLoaderTable(true);
            setIdsTable([]);

            setTimeout(() => {
              setLoaderTable(false);
            }, 500);
          }
          setshowEmails(false);
        } else ModelStateMessage(data);
      });
    },
  });
  return (
    <>
      <div
        id="kt_quick_panel"
        className={`offcanvas-custom offcanvas offcanvas-right pt-5 pb-10 ${
          ContactSelectedSendEmail !== null ? "offcanvas-on" : ""
        }`}
      >
        {/*begin::Header*/}
        <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
          <div className="pl-4">
            <h5>
              {!!props?.selectedContactEmail
                ? "Send Email"
                : "Account Based Email"}
            </h5>
          </div>
          <div className="offcanvas-close mt-n1 pr-5">
            <span
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              id="kt_quick_panel_close"
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (!!props.onHide) {
                  props.onHide();
                } else {
                  document.getElementById("ClicktoremoveSelection").click();
                  setLoaderTable(true);
                  setIdsTable([]);

                  setTimeout(() => {
                    setLoaderTable(false);
                  }, 500);
                }
              }}
            >
              <i className="ki ki-close icon-xs text-muted"></i>
            </span>
          </div>
        </div>
        <div className="offcanvas-content px-1">
          <PerfectScrollbar
            options={perfectScrollbarOptions}
            className="scroll"
            style={{ maxHeight: "83vh", overflow: "hidden" }}
          >
            <Accordion defaultActiveKey="0">
              <Col className="py-4">
                <Form>
                  <Form.Row>
                    <Col xl="6" lg="6" md="6">
                      <Form.Group controlId="formGroupAccount" className="mb-3">
                        <Form.Label className="mb-1 font-size-sm">
                          From <RequiredHtml />
                        </Form.Label>
                        <Select
                          options={viewModel?.providers?.map((a) => {
                            const label =
                              a?.businessId === null
                                ? `${a?.name} (Costs $0.005 per send)`
                                : a?.name;

                            const displayLabel = a?.isExpired
                              ? `${label} - Expired`
                              : label;

                            return {
                              value: a?.id,
                              label: displayLabel,
                              isDisabled: a?.isExpired,
                            };
                          })}
                          classNamePrefix="react-select"
                          size="sm"
                          name="provider"
                          value={
                            formikEmail?.values?.provider &&
                            (viewModel?.providers ?? []).find(
                              (obj) =>
                                obj.value === formikEmail?.values?.provider
                            )
                          }
                          onChange={(e) => {
                            formikEmail.setFieldValue("provider", e.value);
                            apiCall({
                              method: "GET",
                              link: `/api/Providers/${e.value}`,
                            }).then((data) => {
                              if (data[0] === 200) {
                                setprovider(data[1].provider);
                              }
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>

                    <Col xl="6" lg="6" md="6">
                      <Form.Group controlId="formGroupAccount" className="mb-3">
                        <Form.Label className="mb-1 font-size-sm">
                          To
                        </Form.Label>
                        <Form.Control
                          disabled={true}
                          size="sm"
                          name="to"
                          value={GetContactTo()}
                        />
                      </Form.Group>
                    </Col>
                    <Col xl="12" lg="12" md="12">
                      <Form.Group controlId="formGroupAccount" className="mb-3">
                        <Form.Label className="mb-1 font-size-sm">
                          CC
                        </Form.Label>
                        <Select
                          isMulti
                          isDisabled={props?.isSendEmail ? false : true}
                          options={contactList}
                          value={
                            props?.isSendEmail
                              ? contactList.filter((a) =>
                                formikEmail.values.ccId.includes(a.value)
                                )
                              : contactList.filter((a) =>
                                  idsTable.includes(a.value)
                                )
                          }
                          classNamePrefix="react-select"
                          size="sm"
                          name="ccId"
                          onChange={(e) => {
                            setCCvalue(e ?? []);
                            let result = (e ?? []).map((a) => a.value);
                            formikEmail.setFieldValue("ccId", result);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col xl="12" lg="12" md="12">
                      <Form.Group controlId="formGroupAccount" className="mb-3">
                        <Form.Label className="mb-1 font-size-sm">
                          BCC
                        </Form.Label>
                        <Select
                          isMulti
                          options={contactList}
                          value={BCCvalue}
                          classNamePrefix="react-select"
                          size="sm"
                          name="bccId"
                          onChange={(e) => {
                            setBCCvalue(e ?? []);
                            let result = (e ?? []).map((a) => a.value);
                            formikEmail.setFieldValue("bccId", result);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col xl="6" lg="6" md="6">
                      <Form.Group controlId="formGroupAccount" className="mb-3">
                        <Form.Label className="mb-1 font-size-sm">
                          Subject
                        </Form.Label>
                        <Form.Control
                          placeholder="Subject"
                          size="sm"
                          name="to"
                          value={Subject}
                          onChange={(e) => {
                            setSubject(e.target.value);
                            formikEmail.setFieldValue(
                              "subject",
                              e.target.value
                            );
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col xl="6" lg="6" md="6">
                      <Form.Group controlId="formGroupAccount" className="mb-3">
                        <Form.Label className="mb-1 font-size-sm">
                          Template
                        </Form.Label>
                        <Select
                          options={(viewModel?.templates ?? []).map((x) => {
                            return {
                              value: x?.id,
                              label: x?.name,
                            };
                          })}
                          classNamePrefix="react-select"
                          size="sm"
                          name="templateId"
                          value={(viewModel?.templates ?? []).find(
                            (obj) =>
                              obj.value === formikEmail?.values?.templateId[0]
                          )}
                          onChange={(e) => {
                            formikEmail.setFieldValue("templateId", [e.value]);
                            apiCall({
                              method: "GET",
                              link: `/api/Templates/${e.value}`,
                            }).then((data) => {
                              if (data[0] === 200) {
                                var obj = { ...datahtml };
                                obj.subject = data[1].subject;
                                setSubject(
                                  !!data[1].subject ? data[1].subject : Subject
                                );
                                setBody(data[1].body);
                                setdatahtml(obj);

                                formikEmail.setFieldValue(
                                  "subject",
                                  data[1].subject
                                );
                                formikEmail.setFieldValue("body", data[1].body);
                              }
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Row>
                    <div>
                      <Form.Group controlId="formGroupAccount" className="mb-3">
                        <Form.Label className="mb-1 font-size-sm row px-4">
                          Sent Date
                        </Form.Label>
                        <DatePicker
                          selected={dateSent}
                          onChange={(date) => {
                            console.log(
                              "🚀 ~ file: ABMPage.js:726 ~ date:",
                              date
                            );
                            setDateSent(date);
                          }}
                          showTimeSelect
                          className="form-control"
                          timeFormat="h:mm aa"
                          dateFormat="MM/dd/yyyy h:mm aa"
                        />
                      </Form.Group>
                    </div>
                  </Row>
                  <Row>
                    <Col>
                      <label className="font-size-sm">
                        Body
                        <RequiredHtml />
                      </label>
                      {variables?.length > 0 && (
                        <CKEditor
                          data={body}
                          onChange={(event) => {
                            setBody(event?.editor?.getData());
                            formikEmail.setFieldValue(
                              "body",
                              event?.editor?.getData()
                            );
                          }}
                          config={{
                            versionCheck: false,
                            extraPlugins: "uploadimage,autolink",
                            filebrowserUploadUrl: `${BASE_URL}/api/Generic/uploadCK?isckEditor=true`,
                            fileTools_requestHeaders: {
                              "X-Requested-With": "XMLHttpRequest",
                              Authorization: `Bearer ${token}`,
                            },
                          }}
                          onPluginsLoaded={(args) => {
                            const editor = args.editor;
                            editor.ui.addRichCombo("my-combo", {
                              label: "Add variables",
                              title: "Add variables",
                              toolbar: "basicstyles,0",
                              className: "CkeATClass",
                              panel: {
                                // css: [ CKEDITOR.skin.getPath( 'editor' ) ].concat( config.contentsCss ),
                                css: [
                                  "https://cdn.ckeditor.com/4.16.2/standard-all/skins/moono-lisa/editor.css?t=L7C8",
                                  "https://cdn.ckeditor.com/4.4.0/standard/contents.css?t=E3OD",
                                  "./styles.css",
                                ],
                                multiSelect: false,
                                attributes: { "aria-label": "Merge Codes" },
                              },
                              init: function() {
                                variables.forEach((x) => {
                                  this.add(x?.name, x?.name);
                                });
                              },
                              onClick: function(value) {
                                editor.focus();
                                editor.fire("saveSnapshot");
                                editor.insertHtml(value);
                                editor.fire("saveSnapshot");
                              },
                            });
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="form-group mb-3">
                        <label className="font-size-sm mt-5">
                          Email signature
                        </label>
                        <div className="d-flex justify-content-between mb-5">
                          <div className="flex-grow-1 mr-4">
                            <ReactSelect
                              options={SinatureList.map((a) => {
                                return { label: a.name, value: a.id };
                              })}
                              classNamePrefix="react-select"
                              size="sm"
                              placeholder="Select signature template"
                              name="country"
                              value={formikEmail?.values?.EmailSignature}
                              onChange={(e) => {
                                formikEmail.setFieldValue("EmailSignature", e);
                                var obj = { ...BasicsData };
                                obj.signature = SinatureList?.find(
                                  (a) => a.id === e?.value
                                )?.body;
                                setBasicsData(obj);
                              }}
                            />
                          </div>
                        </div>
                        <CKEditor
                          data={BasicsData?.signature}
                          onChange={(event) => {
                            var obj = { ...BasicsData };
                            obj.signature = event?.editor?.getData();
                            setBasicsData(obj);
                          }}
                          config={{
                            versionCheck: false,
                            height: 120,
                            extraPlugins: "uploadimage,autolink",
                            filebrowserUploadUrl: `${BASE_URL}/api/Generic/uploadCK?isckEditor=true`,
                            fileTools_requestHeaders: {
                              "X-Requested-With": "XMLHttpRequest",
                              Authorization: `Bearer ${token}`,
                            },
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Row>
                        <Col>
                          <ToggleTrackingOnOff
                            value={trackValues?.trackOpen}
                            setValue={(e) =>
                              setValueField(
                                trackValues,
                                setTrackValues,
                                e,
                                "trackOpen"
                              )
                            }
                            label="Track open"
                            name="trackOpen"
                          />
                        </Col>
                        <Col>
                          <ToggleTrackingOnOff
                            value={trackValues?.trackClick}
                            setValue={(e) =>
                              setValueField(
                                trackValues,
                                setTrackValues,
                                e,
                                "trackClick"
                              )
                            }
                            label="Track click"
                            name="trackClick"
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Accordion>
          </PerfectScrollbar>
          <div className="d-flex justify-content-end py-5 px-3">
            {/* Date */}
            <Button
              variant="outline-primary"
              className="mr-2"
              size="sm"
              onClick={() => {
                setShowPreview((x) => !x);
              }}
            >
              <i className={`fas fa-eye${ShowPreview ? "-slash" : ""}`}></i>{" "}
              Preview
            </Button>
            <Button
              variant="primary"
              size="sm"
              type="submit"
              onClick={() => {
                if (!isAbmAuthorized) {
                  Swal.fire({
                    title: "Error",
                    text: `You need a Growth plan to use this feature`,
                    icon: "error",
                  });
                } else {
                  formikEmail.setFieldValue("separately", false);
                  formikEmail.handleSubmit();
                }
              }}
              disabled={isLoading}
            >
              Send
            </Button>
          </div>
        </div>
        {/*end::Content*/}
      </div>
      {ShowPreview && (
        <Preview
          show={ShowPreview}
          setShow={setShowPreview}
          variables={variables}
          currentRow={{
            body: body,
            subject: Subject,
            signature: BasicsData?.signature,
          }}
        />
      )}
      <Modal show={showEmails} onHide={() => setshowEmails(false)} size="lg">
        <Modal.Header closeButton className="py-4 px-3">
          <Modal.Title className="h5">Bulk Email</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">{/* <AccountBasedEmail /> */}</Modal.Body>
        <Modal.Footer className="py-2">
          <Button variant="link" onClick={() => setshowEmails(false)} size="sm">
            Close
          </Button>

          <Button
            variant="outline-primary"
            className=""
            size="sm"
            onClick={() => {
              setShowPreview((x) => !x);
            }}
          >
            <i className={`fas fa-eye${ShowPreview ? "-slash" : ""}`}></i>{" "}
            Preview
          </Button>
          {idsTable.length > 1 && (
            <Button
              variant="primary"
              size="sm"
              type="submit"
              onClick={() => {
                formikEmail.setFieldValue("separately", true);
                formikEmail.handleSubmit();
              }}
              disabled={isLoading}
            >
              Send Separately
            </Button>
          )}
          <Button
            variant="primary"
            size="sm"
            type="submit"
            onClick={() => {
              formikEmail.setFieldValue("separately", false);
              formikEmail.handleSubmit();
            }}
            disabled={isLoading}
          >
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function formaterFunction(parameters, variables) {
  var tt = parameters;
  if (variables.length > 0) {
    variables.forEach((element) => {
      tt = tt?.split(element.name).join(element.value);
    });
  }
  return tt;
}
