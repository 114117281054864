import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import { InputPassword } from "../../../helpers/globals";
import { useTranslation } from "react-i18next";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import preval from "preval.macro";
import { LanguageSelectorDropdown } from "../../../../_metronic/layout/components/extras/dropdowns/LanguageSelectorDropdown";
import { Slide } from "../../Slide/Slide";
/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const today = new Date().getFullYear();
  const [t,i18n] = useTranslation();
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login(values.email, values.password)
          .then(({ data: { accessToken } }) => {
            disableLoading();
            props.login(accessToken);
          })
          .catch(() => {
            disableLoading();
            setSubmitting(false);
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          });
      }, 1000);
    },
  });

  return (
    <div className="d-flex flex-column flex-root">
    {/*begin::Login*/}
    <div
      className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      id="kt_login"
    >
      {/*begin::Content*/}
      <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
        <div className="col-2">
          <LanguageSelectorDropdown />
        </div>
        {/*begin::Content header*/}
        {/* <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
          <span className="font-weight-bold text-dark-50">
            Don't have an account yet?
          </span>
          <Link
            to="/auth/registration"
            className="font-weight-bold ml-2"
            id="kt_login_signup"
          >
            Sign Up!
          </Link>
        </div> */}
        {/*end::Content header*/}

        {/* begin::Content body */}
        <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-left mb-10 mb-lg-10">
        <h3 className="font-size-h1">{t("Sign in to your account")}</h3>
        <p className="text-muted font-weight-bold">
          <span className="font-weight-bold text-dark-50">
            {t("Don't have an account yet?")}
          </span>
          <Link
            to="/auth/registration"
            className="font-weight-bold ml-2"
            id="kt_login_signup"
          >
            {t("Sign Up!")}
          </Link>
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          //
          ""
        )}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={t("Email")}
            type="email"
            className={`form-control form-control-sm`}
            name="email"
            {...formik.getFieldProps("email")}
          />
        </div>
        <Fragment>
          <InputPassword
            isFormGroup={true}
            formikParams={{ ...formik.getFieldProps("password") }}
            placeholder={t("Password")}
            name="password"
          />
        </Fragment>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <label className="checkbox">
            {/* <input type="checkbox" name="Remember" className="m-1" />
            <span />
            <div
              className="ml-1 text-dark"
            >
              Remember me
            </div> */}
          </label>
          <Link
            to="/auth/forgot-password"
            className="text-primary text-hover-primary my-1 mr-2"
            id="kt_login_forgot"
          >
           {t("Forgot Password")}
          </Link>
        </div>
        <button
          id="kt_login_signin_submit"
          type="submit"
          disabled={formik.isSubmitting}
          className={`btn btn-success font-weight-bold px-9 py-3 my-3 w-100`}
        >
          <span>{t("Login")}</span>
          {loading && <span className="ml-3 spinner spinner-white"></span>}
        </button>{" "}
        <button
          type="button"
          onClick={() => {
            props.history.push("/auth/registration");
          }}
          className={`btn btn-primary font-weight-bold px-9 py-3 my-3 w-100`}
        >
          <span>{t("Sign Up")}</span>
        </button>
      </form>
      {/*end::Form*/}
    </div>
    </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy; {today} SalesProphet
                <span className="text-muted ml-3">
                  {t("Build Version")}{" "}
                  <a
                    href={() => false}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-weight-bolder text-muted"
                  >
                    {process.env.REACT_APP_VERSION}
                  </a>{" "}
                  / {t("Build Date:")}{" "}
                  <strong>{preval`module.exports = new Date().toLocaleDateString("en-US");`}</strong>
                </span>
              </div>
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
          {/*begin::Aside*/}
          <div
            className={`login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10 ${String(window.location.href).includes("Unsubscribe") || String(window.location.href).includes("History") ? "d-none" : ""}`}
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`,

            }}
          >
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-between">
              {/* start:: Aside header */}
              <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                <img
                  alt="Logo"
                  className="max-h-150px"
                  src={toAbsoluteUrl("/media/logos/logo-letter-1.png")}
                />
              </Link>
              {/* end:: Aside header */}

              {/* start:: Aside content */}
              <div className="flex-column-fluid d-flex flex-column justify-content-center">
                <Slide />
              </div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 font-weight-bold	text-white">
                  &copy; {today} SalesProphet
                  <span className="text-muted ml-3">
                    {t("Build Version")}{" "}
                    <a
                      href={() => false}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-weight-bolder text-muted"
                    >
                      {process.env.REACT_APP_VERSION}
                    </a>{" "}
                    / {t("Build Date:")}{" "}
                    <strong>{preval`module.exports = new Date().toLocaleDateString("en-US");`}</strong>
                  </span>
                </div>
              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}
        </div>
        {/*end::Login*/}
      </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
