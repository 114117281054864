import React from "react";
import { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { BASE_URL, ModelStateMessage } from "../../../app/helpers/globals";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
export default function UnsbscribePage() {
  const [t, i18n] = useTranslation();
  var url_string = window.location.href; //window.location.href
  url_string = String(url_string).replace("+", "%2B");
  var url = new URL(url_string);
  var code = url?.searchParams?.get("code");
  const { id } = code;
  const [isloadingPost, setisloadingPost] = useState(false);
  const [isUnsubscribed, setisUnsubscribed] = useState(false);

  function SendCodeToApi() {
    if (id === "" || id === null || id === undefined) {
    }
    setisloadingPost(true);
    fetch(`${BASE_URL}/api/Unsubscribe/Report`, {
      method: "Post",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        Code: code,
      }),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (!!data && data.length > 0 && data[0] === 200) {
          Swal.fire({
            title: "Success",
            text: "you have unsubscribed Successfully",
            icon: "success",
          });
          setisloadingPost(false);
          setisUnsubscribed(true);
        } else{
          ModelStateMessage(data);
          setisloadingPost(false);
        }
      })
      .catch((error) => {
        setisloadingPost(false);

        console.log(error);
      });
  }
  return (
    <>

      <div className="error error-6 d-flex flex-row-fluid bgi-size-cover bgi-position-center card pb-md-8">
        {/*begin::Content*/}
        <div className="d-flex flex-column flex-row-fluid text-center justify-content-center">
          <h1 className="display-4 font-weight-boldest text-primary p-5">
            Unsubscribe
          </h1>
          <div className="separator separator-solid mb-12" />
          <img
            src={toAbsoluteUrl("/media/logos/unsubscribe.svg")}
            alt=""
            className="h-350px"
          />
          <p className="display-4 font-weight-bold text-primary mb-5">
            We're sad to see you go!
          </p>
          <div clasName="col-xl-8 col-lg-8 col-md-12 mx-auto mt-5">
            {isUnsubscribed === false ? (
              <h5 className="text-muted text-center font-weight-normal line-height-xl mb-3">
                Please confirm that you want to proceed.
              </h5>
            ) : (
              <h5 className="text-muted text-center font-weight-normal line-height-xl mb-3">
                You have been unsubscribed successfully and will no longer
                receive emails of this sequence. Thank you!
              </h5>
            )}

            {isUnsubscribed === true ? (
              ""
            ) : (
              <Button
                variant="primary"
                size="sm"
                disabled={isloadingPost}
                onClick={(e) => {
                  SendCodeToApi();
                }}
              >
                Confirm
                {isloadingPost && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </Button>
            )}
          </div>
        </div>
        {/*end::Content*/}
      </div>

    </>
  );
}
