import React, { useMemo } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import preval from 'preval.macro'
import moment from "moment";
import {getFromatdate } from '../../../../app/helpers/globals';

export function Footer() {
  const today = new Date().getFullYear();
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      footerLayout: objectPath.get(uiService.config, "footer.layout"),
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true),
    };
  }, [uiService]);

  return (
    <>
      {/* begin::Footer */}
      {/* doc: add "bg-white" class to have footer with solod background color */}
      <div
        className={`footer py-4 d-flex flex-lg-column ${layoutProps.footerClasses}`}
        id="kt_footer"
      >
        {/* begin::Container */}
        <div
          className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
        >
          {/* begin::Copyright */}
          <div className="text-dark order-2 order-md-1">
            <span className="text-muted font-weight-bold mr-">
              {today} &copy;
            </span>
            {` `}
            <span
              className="text-dark-75 text-hover-primary mr-3"
            >
              SalesProphet
            </span>

            <span className="text-muted mr-2">
              Build Version{" "}
              <span
                target="_blank"
                rel="noopener noreferrer"
                className="font-weight-bolder text-muted"
              >
                {process.env.REACT_APP_VERSION}
              </span>{" "}
              / Build Date:{" "}
              <strong>{moment(preval`module.exports = new Date().toLocaleDateString("en-US");`).format(getFromatdate())}</strong>

            </span>
          </div>
        </div>
      </div>
    </>
  );
}
