import CKEditor from "ckeditor4-react";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  DynamicModal,
  ModelStateMessage,
  RequiredHtml,
  ToggleTrackingOnOff,
  apiCall,
  replaceValues,
  setValueField,
} from "../../helpers/globals";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { Col, Form, Row, Spinner, Button, Modal } from "react-bootstrap";
import Select from "react-select";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import moment from "moment";

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const token =
  localStorage?.getItem("user") !== null
    ? JSON.parse(localStorage.getItem("user") ?? "").token
    : "";

const emailObj = {
  templateId: [0],
  fromId: 0,
  toId: [0],
  ccId: [0],
  bccId: [0],
  subject: "",
  body: "",
};

const ReplyModal = (props) => {
  const SchemaEmail = Yup.object().shape({});
  const [loaderBtn, setLoaderBtn] = useState(false);
  const [provider, setprovider] = useState({});
  const [datahtml, setdatahtml] = useState({ subject: "", body: "" });
  const [isCC, setisCC] = useState(false);
  const [BCCvalue, setBCCvalue] = useState([]);
  const [variables, setVariables] = useState([]);
  const [CCvalue, setCCvalue] = useState([]);
  const [body, setBody] = useState("");
  const [dateSent, setDateSent] = useState(new Date());
  const [ShowPreview, setShowPreview] = useState(false);
  const [trackValues, setTrackValues] = useState({
    trackOpen: true,
    trackClick: true,
  });
  useEffect(() => {
    if(!props?.modalReply) setBody("")
  }, [props]);
  const formikEmail = useFormik({
    initialValues: emailObj,
    enableReinitialize: true,
    validationSchema: SchemaEmail,
    onSubmit: (values) => {},
  });

  const bodyTemplate = useMemo(() => {
    return replaceValues(body, variables);
  }, [body, variables]);

  useEffect(() => {
    if (props?.modalReply && !!props?.item?.contactId) {
      apiCall({
        method: "GET",
        link: `/api/Templates/GetVariables?contactId=${props?.item?.contactId}`,
      }).then((dataResult) => {
        try {
          if (dataResult[0] === 200) {
            setVariables(dataResult[1]?.variables || []);
          }
        } catch (err) {}
      });
    }
  }, [props?.modalReply, props.item.contactId]);

  return (
    <div>
      {props?.modalReply && (
        <DynamicModal
          size="lg"
          show={props?.modalReply}
          setShow={props?.setModalReply}
          child={
            <Fragment>
              <Card>
                <CardHeader
                  title={"Reply thread"}
                  className="font-weight-boldest"
                >
                  {" "}
                  <CardHeaderToolbar className="d-flex justify-centent-between">
                    <div>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => props?.setModalReply(false)}
                        className="float-right"
                      >
                        <i className="flaticon2-delete"></i>
                      </span>
                    </div>
                  </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col className="py-4">
                      <div>
                        <Form.Row>
                          <Col xl="12" lg="12" md="12">
                            <Form.Group
                              controlId="formGroupAccount"
                              className="mb-3"
                            >
                              <Form.Label className="mb-1 font-size-sm">
                                Template
                              </Form.Label>
                              <Select
                                options={(
                                  props?.viewModelCompanies?.templates ?? []
                                ).map((x) => {
                                  return {
                                    value: x?.id,
                                    label: x?.name,
                                  };
                                })}
                                classNamePrefix="react-select"
                                size="sm"
                                name="templateId"
                                value={(
                                  props?.viewModelCompanies?.templates ?? []
                                ).find(
                                  (obj) =>
                                    obj.value ===
                                    formikEmail?.values?.templateId[0]
                                )}
                                onChange={(e) => {
                                  formikEmail.setFieldValue("templateId", [
                                    e.value,
                                  ]);
                                  apiCall({
                                    method: "GET",
                                    link: `/api/Templates/${e.value}`,
                                  }).then((data) => {
                                    if (data[0] === 200) {
                                      var obj = { ...datahtml };
                                      obj.subject = data[1].subject;
                                      // setSubject(data[1].subject);
                                      setBody(data[1].body);
                                      setdatahtml(obj);

                                      formikEmail.setFieldValue(
                                        "subject",
                                        data[1].subject
                                      );
                                      formikEmail.setFieldValue(
                                        "body",
                                        data[1].body
                                      );
                                    }
                                  });
                                }}
                              />
                            </Form.Group>
                          </Col>

                          <Col xl="12" lg="12" md="12">
                            <Form.Group
                              controlId="formGroupAccount"
                              className="mb-3"
                            >
                              <Form.Label className="mb-1 font-size-sm">
                                From
                              </Form.Label>
                              <Select
                                options={(
                                  props?.viewModelCompanies?.providers ?? []
                                )?.map((a) => {
                                  const label =
                                    a.businessId === null
                                      ? `${a.name}`
                                      : a.name;

                                  const displayLabel = a.isExpired
                                    ? `${label} - Expired`
                                    : label;

                                  return {
                                    value: a.id,
                                    label: displayLabel,
                                    isDisabled: a.isExpired,
                                  };
                                })}
                                classNamePrefix="react-select"
                                size="sm"
                                name="fromId"
                                value={
                                  !!props?.item?.provider
                                    ? {
                                        value: (
                                          props?.viewModelCompanies
                                            ?.providers ?? []
                                        ).find(
                                          (obj) =>
                                            obj.name === props?.item?.provider
                                        )?.id,
                                        label: props?.item?.provider,
                                      }
                                    : (
                                        props?.viewModelCompanies?.providers ??
                                        []
                                      ).find(
                                        (obj) =>
                                          obj.value ===
                                          formikEmail?.values?.fromId
                                      )
                                }
                                onChange={(e) => {
                                  formikEmail.setFieldValue("fromId", e.value);
                                  apiCall({
                                    method: "GET",
                                    link: `/api/Providers/${e.value}`,
                                  }).then((data) => {
                                    if (data[0] === 200) {
                                      setprovider(data[1].provider);
                                    }
                                  });
                                }}
                              />
                            </Form.Group>
                          </Col>

                          <Col xl="12" lg="12" md="12">
                            <Form.Group
                              controlId="formGroupAccount"
                              className="mb-3"
                            >
                              <Form.Label className="mb-1 font-size-sm">
                                To
                              </Form.Label>
                              <Form.Control
                                disabled={true}
                                size="sm"
                                name="to"
                                value={`${props?.item?.firstName} ${props?.item?.lastName}`}
                              />
                            </Form.Group>
                          </Col>
                          <Row className="px-2">
                            <div>
                              <Form.Group
                                controlId="formGroupAccount"
                                className="mb-3"
                              >
                                <Form.Label className="mb-1 font-size-sm row px-4">
                                  Sent Date
                                </Form.Label>
                                <DatePicker
                                  selected={dateSent}
                                  onChange={(date) => setDateSent(date)}
                                  showTimeSelect
                                  className="form-control"
                                  timeFormat="h:mm aa"
                                  dateFormat="MM/dd/yyyy h:mm aa"
                                />
                              </Form.Group>
                            </div>
                          </Row>
                          {/* <Col>
                            <div className="d-flex justify-content-end">
                              <Button
                                variant="outline-primary"
                                className=""
                                size="sm"
                                onClick={() => {
                                  setisCC((x) => !x);
                                }}
                              >
                                <i
                                  className={`fas fa-eye${
                                    isCC ? "-slash" : ""
                                  }`}
                                ></i>{" "}
                                CC && BCC
                              </Button>
                            </div>
                          </Col> */}

                          {isCC && (
                            <>
                              <Col xl="12" lg="12" md="12" className="mt-2">
                                <Form.Group
                                  controlId="formGroupAccount"
                                  className="mb-3"
                                >
                                  <Form.Label className="mb-1 font-size-sm">
                                    CC
                                  </Form.Label>
                                  <Select
                                    isMulti
                                    options={(props?.isCompany
                                      ? props?.contactList?.contacts ?? []
                                      : props?.contactList ?? []
                                    )?.map((x) => {
                                      return {
                                        value: x?.contactId,
                                        label: `${x?.firstName} ${x?.lastName}`,
                                      };
                                    })}
                                    value={CCvalue}
                                    classNamePrefix="react-select"
                                    size="sm"
                                    name="ccId"
                                    onChange={(e) => {
                                      setCCvalue(e);
                                      let result = e.map((a) => a.value);
                                      formikEmail.setFieldValue("ccId", result);
                                    }}
                                  />
                                </Form.Group>
                              </Col>
                              <Col xl="12" lg="12" md="12">
                                <Form.Group
                                  controlId="formGroupAccount"
                                  className="mb-3"
                                >
                                  <Form.Label className="mb-1 font-size-sm">
                                    BCC
                                  </Form.Label>
                                  <Select
                                    isMulti
                                    options={(props?.isCompany
                                      ? props?.contactList?.contacts ?? []
                                      : props?.contactList ?? []
                                    )?.map((x) => {
                                      return {
                                        value: x?.contactId,
                                        label: `${x?.firstName} ${x?.lastName}`,
                                      };
                                    })}
                                    value={BCCvalue}
                                    classNamePrefix="react-select"
                                    size="sm"
                                    name="bccId"
                                    onChange={(e) => {
                                      setBCCvalue(e);
                                      let result = e.map((a) => a.value);
                                      formikEmail.setFieldValue(
                                        "bccId",
                                        result
                                      );
                                    }}
                                  />
                                </Form.Group>
                              </Col>
                            </>
                          )}

                          <Col xl="12" lg="12" md="12" className="mt-5">
                            <h5 className="mb-1">
                              <b>Re: </b> {props?.item?.subject}
                            </h5>
                          </Col>
                        </Form.Row>

                        {/* Second row */}
                        <Row>
                          <Col>
                            <label className="font-size-sm mt-5">
                              Body
                              <RequiredHtml />
                            </label>
                            {variables?.length > 0 && (
                              <CKEditor
                                data={body}
                                onChange={(event) => {
                                  setBody(event?.editor?.getData());
                                  formikEmail.setFieldValue(
                                    "body",
                                    event?.editor?.getData()
                                  );
                                }}
                                config={{
                                  versionCheck: false,
                                  extraPlugins: "uploadimage,autolink",
                                  filebrowserUploadUrl: `${BASE_URL}/api/Generic/uploadCK?isckEditor=true`,
                                  fileTools_requestHeaders: {
                                    "X-Requested-With": "XMLHttpRequest",
                                    Authorization: `Bearer ${token}`,
                                  },
                                }}
                                onPluginsLoaded={(args) => {
                                  const editor = args.editor;
                                  editor.ui.addRichCombo("my-combo", {
                                    label: "Add variables",
                                    title: "Add variables",
                                    toolbar: "basicstyles,0",
                                    className: "CkeATClass",
                                    panel: {
                                      // css: [ CKEDITOR.skin.getPath( 'editor' ) ].concat( config.contentsCss ),
                                      css: [
                                        "https://cdn.ckeditor.com/4.16.2/standard-all/skins/moono-lisa/editor.css?t=L7C8",
                                        "https://cdn.ckeditor.com/4.4.0/standard/contents.css?t=E3OD",
                                        "./styles.css",
                                      ],
                                      multiSelect: false,
                                      attributes: {
                                        "aria-label": "Merge Codes",
                                      },
                                    },
                                    init: function() {
                                      (variables ?? []).forEach((x) => {
                                        this.add(x?.name, x?.name);
                                      });
                                    },
                                    onClick: function(value) {
                                      editor.focus();
                                      editor.fire("saveSnapshot");
                                      editor.insertHtml(value);
                                      editor.fire("saveSnapshot");
                                    },
                                  });
                                }}
                              />
                            )}
                          </Col>

                          <Col xl="12" lg="12" md="12" className="mt-2">
                            {ShowPreview && (
                              <Row>
                                <Col>
                                  <div className="divReply">
                                    <h5 className="text-dark font-weight-bolder mt-2 mb-2 font-size-sm">
                                      Subject:
                                    </h5>
                                    <span className="text-dark-700 font-weight-normal font-size-sm">
                                      {replaceValues(
                                        props?.item?.subject,
                                        variables
                                      )}
                                    </span>

                                    <h5 className="text-dark font-weight-bolder mt-2 mb-2 font-size-sm mt-5">
                                      Body
                                    </h5>
                                    <div
                                      className="text-dark-700 font-weight-normal font-size-sm"
                                      dangerouslySetInnerHTML={{
                                        __html: replaceValues(
                                          bodyTemplate,
                                          variables
                                        ),
                                      }}
                                    />

                                    {!!provider?.signature && (
                                      <>
                                        <div
                                          className="text-dark-700 font-weight-normal font-size-sm mt-5"
                                          dangerouslySetInnerHTML={{
                                            __html: replaceValues(
                                              provider?.signature,
                                              variables
                                            ),
                                          }}
                                        />
                                      </>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Row>
                              <Col>
                                <ToggleTrackingOnOff
                                  value={trackValues?.trackOpen}
                                  setValue={(e) =>
                                    setValueField(
                                      trackValues,
                                      setTrackValues,
                                      e,
                                      "trackOpen"
                                    )
                                  }
                                  label="Track open"
                                  name="trackOpen"
                                />
                              </Col>
                              <Col>
                                <ToggleTrackingOnOff
                                  value={trackValues?.trackClick}
                                  setValue={(e) =>
                                    setValueField(
                                      trackValues,
                                      setTrackValues,
                                      e,
                                      "trackClick"
                                    )
                                  }
                                  label="Track click"
                                  name="trackClick"
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={(e) => {
                    e.preventDefault();
                    props?.setModalReply(false);
                  }}
                >
                  Close
                </Button>
                <Button
                  variant="outline-primary"
                  className=""
                  size="sm"
                  onClick={() => {
                    setShowPreview((x) => !x);
                  }}
                >
                  <i className={`fas fa-eye${ShowPreview ? "-slash" : ""}`}></i>{" "}
                  Preview
                </Button>
                <button
                  className="btn btn-primary btn-sm"
                  disabled={loaderBtn}
                  onClick={(e) => {
                    e.preventDefault();
                    if (!!body === false) {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Body is required",
                      });
                      return false;
                    }
                    setLoaderBtn(true);
                    apiCall({
                      method: "POST",
                      link: `/api/Sending/Reply`,
                      data: {
                        reportId: props?.item?.id,
                        body: body,
                        trackOpen: trackValues.trackOpen ?? true,
                        trackClick: trackValues.trackClick ?? true,
                        date: moment(dateSent, "YYYY-MM-DD[T]HH:mm"),
                      },
                    }).then((data) => {
                      setLoaderBtn(false);
                      if (data[0] === 200) {
                        props?.setModalReply(false);
                        props?.handleGetUbniBox();
                        Swal.fire({
                          icon: "success",
                          title: "Success",
                          text: "Email has been sent successfully",
                        });
                        // setshowEmails(false);
                      } else {
                        ModelStateMessage(data);
                        setLoaderBtn(false);
                      }
                    });
                  }}
                >
                  Reply now
                  {loaderBtn && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </button>
              </Modal.Footer>
            </Fragment>
          }
        />
      )}
    </div>
  );
};

export default ReplyModal;
