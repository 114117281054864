/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import SVG from "react-inlinesvg";
import { shallowEqual, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_helpers";
import { useCustomerly } from "react-live-chat-customerly";

export function QuickUser() {
  const history = useHistory();
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const [ReloadData, setReloadData] = useState(0);

  const logoutClick = () => {
    localStorage.removeItem("fullName");
    localStorage.removeItem("ProfilePicture");
    localStorage.removeItem("IconShow");
    localStorage.removeItem("UserEmail");
    localStorage.removeItem("dateFormat");
    localStorage.removeItem("customerly_messenger/i18next/en-translation");
    localStorage.removeItem("i18nextLng");
    localStorage.removeItem("user");
    localStorage.removeItem("customerly_messenger/stats");
    localStorage.removeItem("customerly_messenger/welcome-lead/sent");

    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    history.push("/logout");
  };

  const currentUser = useMemo(() => {
    return JSON.parse(localStorage.getItem("user"));
  }, []);
  const fullName = useMemo(() => {
    return localStorage.getItem("fullName");
  }, []);
  const currentProfilePicture = useMemo(() => {
    return localStorage.getItem("ProfilePicture");
  }, []);

  const { load } = useCustomerly();
  useEffect(() => {
    LoadChats(load);
  }, []);

  return (
    <div id="kt_quick_user" className="offcanvas offcanvas-left offcanvas p-10">
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">
          User Profile
          {/* <small className="text-muted font-size-sm ml-2">12 messages</small> */}
        </h3>
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>

      <input
        type="hidden"
        id="ReloadInfoData"
        onClick={() => {
          setReloadData((x) => x + 1);
        }}
      />

      <div className="offcanvas-content pr-5 mr-n5">
        <div className="d-flex align-items-center mt-5">
          <div className="symbol symbol-100 mr-5">
            <div
              className="symbol-label"
              id="backgroundProfileGlobal"
              style={{
                backgroundImage: `url("${currentProfilePicture?.toString()}")`,
              }}
            />
          </div>
          <div className="d-flex flex-column">
            <a
              href="#"
              className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {!!fullName
                ? fullName
                : `${currentUser.firstName} ${currentUser.lastName}`}
            </a>
            {/* <div className="text-muted mt-1">
              {currentUser?.roles?.length > 0 ? currentUser?.roles[0] : ""}
            </div> */}
            <div className="navi mt-2">
              <a href="#" className="navi-item">
                <span
                  className="navi-link p-0 pb-2"
                  style={{ wordBreak: "break-all" }}
                >
                  <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Mail-notification.svg"
                        )}
                      ></SVG>
                    </span>
                  </span>
                  <span
                    className="navi-text text-muted text-hover-primary font-size-sm"
                  >
                    {currentUser?.email}
                  </span>
                </span>
              </a>
            </div>
            {/* <Link to="/logout" className="btn btn-light-primary btn-bold">
                Sign Out
              </Link> */}
            <button
              className="btn btn-light-primary btn-bold"
              onClick={logoutClick}
            >
              Sign out
            </button>
          </div>
        </div>

        <div className="separator separator-dashed mt-8 mb-5" />

        <div className="navi navi-spacer-x-0 p-0">
          <Link to="/user-profile/personal-information" className="navi-item" onClick={() => {
            const Close = document.getElementById("kt_quick_user_close");
            if (Close) {
              Close.click();
            }
          }}>
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-success">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/Notification2.svg"
                      )}
                    ></SVG>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Profile</div>
                <div className="text-muted">Account settings and more </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

function LoadChats(load) {
  load();
  var count = 0;
  try {
    var interval = setInterval(doStuff, 1000);
    function doStuff() {
      count++;
      var ele = document.querySelector("#customerly-container .gQkKzL");
      if (!!ele || count > 15) {
        ele.setAttribute("style", "display: none !important;");
        clearInterval(interval);

        var chatId = document.querySelector("#ChatId");
        if (!!chatId) chatId.removeAttribute("style");
      }

      var ele2 = document.querySelector("#customerly-container");
      if (!!ele2) ele2.style.opacity = 1;
    }
  } catch (error) { }
}
